import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";
import { TrashCan, Add, Save, Download, Renew, Edit } from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

// import { useFormik } from "formik";

import Padding from "~/components/Padding";

import { formatCPFCNPJ } from "~/services/utils";
import { permissions } from "~/services/permissions";

import { getCompanysAPI } from "~/services/api/companys";
import useCompany from "~/hooks/useCompany";
import NewCompanyModal from "./NewCompanyModal";

import { useNavigate } from "react-router-dom";
import DeleteCompanyModal from "./DeleteCompanyModal";
import Badge from "~/components/Badge";
import { ModalEditCompany } from "./ModalEditCompany";

// import DeleteProfileModal from "./DeleteProfileModal";

const tableHeadersProfile: string[] = [
  "Razão Social",
  "Nome Fantasia",
  "CNPJ",
  "Tipo",
  "Status",
  "",
  ""
];

const canReadFunds = permissions.includes("can_read_funds");
const canCreateFunds = permissions.includes("can_create_funds");
// const canEditProfiles = permissions.includes("can_edit_profiles")
const canDeleteFunds = permissions.includes("can_delete_funds");

const Companys = () => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const {
    setCompanys,
    companys,
    setOpenDeleteCompanyModal,
    openDeleteCompanyModal,
    setOpenEditCompanyModal,
    openEditCompanyModal,
    setCompanyFunds,
    setCompanyDetails,
    setOpenNewCompanyModal,
    openNewCompanyModal,
  } = useCompany();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const paginatedCompanys = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return companys
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, "pt-BR", { sensitivity: "base" })
      )
      .slice(start, end);
  }, [currentPage, itemsPerPage, companys]);


  const fetchCompanys = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setLoading, setCompanys]);

  useEffect(() => {
    fetchCompanys();
    setCompanyDetails(null);
    setCompanyFunds([]);
  }, [fetchCompanys, setCompanyDetails, setCompanyFunds]);


  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Empresas </h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableContainer>
          <Margin mb={20} />
          <TableToolbar>
            {canReadFunds && (
              <Button
                tabIndex={0}
                onClick={() => {
                  fetchCompanys();
                }}
                kind="secondary"
                renderIcon={Renew}
              >
                Atualizar
              </Button>
            )}
            {!canReadFunds && (
              <div
                style={{
                  height: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <h4 style={{ position: "relative", left: "45%" }}>
                  Você não possui permissão para visualizar usuários
                </h4>
              </div>
            )}
            <TableBatchActions onCancel={console.log} totalSelected={0}>
              <TableBatchAction
                tabIndex={0}
                renderIcon={TrashCan}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Add"
                tabIndex={0}
                renderIcon={Add}
                onClick={console.log}
              >
                Delete
              </TableBatchAction>
              <TableBatchAction
                hasIconOnly
                iconDescription="Save"
                tabIndex={0}
                renderIcon={Save}
                onClick={console.log}
              >
                Save
              </TableBatchAction>
              <TableBatchAction
                tabIndex={0}
                renderIcon={Download}
                onClick={console.log}
              >
                Download
              </TableBatchAction>
            </TableBatchActions>
            <TableToolbarContent>
              {/* <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                  /> */}
              {/* {user.type === "tool_manager" && (
                    <Tooltip defaultOpen label="Exportar relatório" align="top">
                      <Button
                        kind="ghost"
                        hasIconOnly
                        renderIcon={Report}
                        id="filter"
                      />
                    </Tooltip>
                  )}
                  <Tooltip defaultOpen label="Filtrar" align="top">
                    <Button
                      kind="ghost"
                      hasIconOnly
                      renderIcon={Filter}
                      id="filter"
                      onClick={() => setOpenModal(true)}
                    />
                  </Tooltip> */}

              {canCreateFunds && (
                <Button
                  tabIndex={0}
                  onClick={() => navigate("/app/company-types")}
                  kind="secondary"
                >
                  Ver tipos de empresa
                </Button>
              )}

              {canCreateFunds && (
                <Button
                  tabIndex={0}
                  onClick={() => {
                    setOpenNewCompanyModal(true);
                  }}
                  kind="primary"
                >
                  Nova Empresa
                </Button>
              )}
            </TableToolbarContent>
          </TableToolbar>
          {canReadFunds && (
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                  {tableHeadersProfile.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedCompanys.length === 0 ? (
                  <TableCell colSpan={tableHeadersProfile.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Nenhuma empresa até o momento</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedCompanys.length > 0 ? (
                  paginatedCompanys.map((company, index) => (
                    <TableRow
                      key={String(index)}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/app/companys/${company.id}`)}
                    >
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                          ariaLabel={""}
                        /> */}
                      <TableCell>{company?.name}</TableCell>
                      <TableCell>{company?.raw_data?.brand_name}</TableCell>
                      <TableCell>
                        <p
                          style={{
                            whiteSpace: "nowrap"
                          }}
                        >{company?.raw_data?.document_number &&
                          formatCPFCNPJ(company?.raw_data?.document_number)}</p>
                      </TableCell>
                      <TableCell>
                        {company?.raw_data?.company_type?.name}
                      </TableCell>
                      <TableCell>
                        {company?.raw_data?.active ? (
                          <Badge
                            color="#CCEFDF"
                            textColor="#00B360"
                            label="Ativa"
                          />
                        ) : (
                          <Badge
                            label="Inativa"
                            color="#e3e3e3"
                            textColor="#696868"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setOpenDeleteCompanyModal(true);
                              setCompanyDetails(company);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setOpenEditCompanyModal((prev) => !prev)
                              setCompanyDetails(company);
                            }}
                          />
                        )}
                      </TableCell>
                      {/* <TableCell>{profile.area}</TableCell> */}
                    </TableRow>
                  ))
                ) : (
                  paginatedCompanys?.map((company, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                          ariaLabel={""}
                        /> */}
                      <TableCell>{company?.name}</TableCell>
                      <TableCell>{company?.raw_data?.brand_name}</TableCell>
                      <TableCell>
                        {company?.raw_data?.document_number &&
                          formatCPFCNPJ(company?.raw_data?.document_number)}
                      </TableCell>
                      <TableCell>
                        {company?.raw_data?.company_type?.name}
                      </TableCell>
                      <TableCell>
                        {company?.raw_data?.active ? "Sim" : "Não"}
                      </TableCell>
                      <TableCell>
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Excluir"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setOpenDeleteCompanyModal(true);
                              setCompanyDetails(company);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {canDeleteFunds && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="editar"
                            renderIcon={Edit}
                            id="filter"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setOpenEditCompanyModal((prev) => !prev)
                              setCompanyDetails(company);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
          <Pagination
            totalItems={companys.length}
            pageSize={itemsPerPage}
            pageSizes={[5, 10, 20, 30, 40, 50]}
            onChange={({ page, pageSize }) => {
              setCurrentPage(page);
              setItemsPerPage(pageSize);
            }}
            page={currentPage}
            size="md"
          />
        </TableContainer>
        <Margin mb={50} />
      </Column>
      {/* <FilterModal onClose={() => setOpenModal(false)} open={openModal} />
       */}

      <DeleteCompanyModal
        open={openDeleteCompanyModal}
        onClose={() => setOpenDeleteCompanyModal(!openDeleteCompanyModal)}
      />
      <NewCompanyModal
        open={openNewCompanyModal}
        onClose={() => setOpenNewCompanyModal(false)}
      />
      <ModalEditCompany
        open={openEditCompanyModal}
        onClose={() => setOpenEditCompanyModal(false)}
      />
    </Grid>
  );
};

export default Companys;
