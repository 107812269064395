import {
  Dropdown,
  InputFluid,
  InputSelect,
  Modal,
  Radio,
} from "prosperita-dumbo-react";
import addicon from "~/assets/iconadd.png";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import * as Yup from "yup";
import { useFormik } from "formik";
import useProfile from "~/hooks/useProfile";
import {
  getProfilesAPI,
  IPatchProfileBody,
  patchProfileAPI,
} from "~/services/api/profile";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import "typeface-ibm-plex-mono";
import useUser from "~/hooks/useUser";
import useNotification from "~/hooks/useToast";
import styled from "styled-components";
import ReactInputMask from "react-input-mask";
import useCompany from "~/hooks/useCompany";
import { getCompanysAPI } from "~/services/api/companys";


type Imodal = {
  open: boolean;
  onClose?: () => void;
};

type Values = {
  full_name: string;
  document_number: string;
  company_name: string;
  company_document_number: string;
  email: string;
  company: string;
  type_profile: string[];
  status: string;
  payment_company_approver: boolean;
  payment_approver: boolean;
  beneficiary_approver: boolean;
  permission:
  | "payment_approver"
  | "payment_company_approver"
  | "beneficiary_approver"
  | "no_permission"
  | "";
};

const initialValues: Values = {
  full_name: "",
  document_number: "",
  company_name: "",
  company_document_number: "",
  email: "",
  company: "",
  type_profile: [""],
  status: "",
  payment_company_approver: false,
  payment_approver: false,
  beneficiary_approver: false,
  permission: "",
};

const validationSchema = Yup.object({
  full_name: Yup.string().required("Nome completo é obrigatório"),
  document_number: Yup.string()
    .matches(/^\d+$/, "Número do documento deve conter apenas números")
    .required("Número do documento é obrigatório"),
  company_name: Yup.string().required("Nome da empresa é obrigatório"),
  company_document_number: Yup.string()
    .matches(
      /^\d+$/,
      "Número do documento da empresa deve conter apenas números"
    )
    .required("Número do documento da empresa é obrigatório"),
  brand_name: Yup.string().required("Nome da marca é obrigatório"),
  email: Yup.string()
    .email("Email inválido")
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "Email deve ser um formato válido"
    ) // Verifica se o email está em um formato válido
    .required("Email é obrigatório"),
  profile: Yup.string().required("Usuário é obrigatório"),
  type_profile: Yup.string().required("Tipo de usuário é obrigatório"),
});

type Idropdown = {
  value: string;
  label: string;
};

const options: Idropdown[] = [
  {
    value: "tool_manager",
    label: "Gestor de ferramenta",
  },
  {
    value: "area_manager",
    label: "Gestor de àrea",
  },
  {
    value: "internal",
    label: "Atendente",
  },
];


const StyledTextInput = styled(ReactInputMask)`
  box-sizing: border-box;
  border: 2px solid transparent;
  background: transparent;
  color: #333333;
  font-family: "IBM Plex Sans", sans-serif;
  &::placeholder {
    color: #9b9898;
  }
  font-size: 14px;
  line-height: 18px;
  outline: none;
  width:300px;
  ;`



export const EditModalProfile = ({ open, onClose }: Imodal) => {

  const { setLoading } = useLoading();

  const [activeRadio, setActiveRadio] = useState<string | null>(null);

  const [clearFilesDrop, setclearFilesDrop] = useState<boolean>(false);

  const { setNotification, closeNotification } = useNotification()

  const [selectedProfiles, setSelectedProfiles] = useState<{ label: string; value: string }[]>([]);

  const { setProfiles, setEditModalProfile, detailsProfile } = useProfile();

  const { userProfile, setUserProfile } = useUser();

  const [isActive, setIsActive] = useState(false);

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI();
      setUserProfile(data.results[0]);
    } catch (e) {
    }
  }, [userProfile]);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getProfilesAPI({ mode: "list" });
      setProfiles(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
    }
  }, []);

  const { setCompanys, companys } = useCompany();

  const companysOptions = useMemo(() => {
    return companys.filter((company) => company.raw_data?.active)
      .map((company) => ({
        value: String(company.id),
        label: company?.raw_data?.brand_name?.trim() || "",
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [companys]);

  const fetchCompanys = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [setLoading, setCompanys]);


  const { values, handleChange, touched, errors, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => { },
    });

  const handleChangeSelect = (event: { label: string; value: string }[]) => {
    const selectedValues = event.map((perm) => perm.value);
    setFieldValue("type_profile", selectedValues);
    setSelectedProfiles(event);
  };

  const splitFullName = (fullName: string) => {
    const nameParts = fullName.trim().split(/\s+/);

    if (nameParts.length === 1) {
      return { first_name: nameParts[0], last_name: '' };
    }
    return {
      first_name: nameParts[0],
      last_name: nameParts.slice(1).join(' '),
    };
  };

  //
  const { first_name, last_name } = splitFullName(values?.full_name);

  const payload: IPatchProfileBody = useMemo(() => {

    return {
      name: values.full_name,
      ...(values.company && { company: Number(values.company) }),
      ...(detailsProfile?.document_number !== onlyNumbers(values.document_number) &&
        { document_number: onlyNumbers(values.document_number) }),
      type: activeRadio === "inactive" ? [] : values?.type_profile,
      raw_data: {
        first_login: detailsProfile?.raw_data?.first_login,
        status: values.status,
      },
      ...(detailsProfile?.user?.email !== values.email && {
        user: { email: values.email }
      }),
      payment_company_approver:
        activeRadio !== "inactive" && values.permission === "payment_company_approver"
          ? true
          : values.permission === "no_permission" && false,
      payment_approver:
        activeRadio !== "inactive" && values.permission === "payment_approver"
          ? true
          : values.permission === "no_permission" && false,
      beneficiary_approver:
        activeRadio !== "inactive" && values.permission === "beneficiary_approver"
          ? true
          : values.permission === "no_permission" && false,
    };
  }, [values, first_name, last_name, detailsProfile, activeRadio, detailsProfile]);

  //console.log(payload)

  const disabledButtonWhenTypeIsClient = useMemo(() => {

    const isFullNameValid = payload?.name && payload?.name?.trim().split(/\s+/).length >= 2;
    const isEmailValid = values?.email && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values?.email);
    const isTypeProfileValid = values?.type_profile?.length !== 0;
    const isPermissionValid = values?.permission !== "";
    const cpfs = onlyNumbers(values?.document_number)?.length === 11;

    return !(isFullNameValid && isEmailValid && isTypeProfileValid && isPermissionValid && cpfs);

  }, [payload, values]);


  const fetchProfilesEdit = useCallback(async () => {

    try {

      const { data } = await getProfilesAPI({ mode: "list" });

      setProfiles(data.results.reverse());

      setLoading(false);

    } catch (err: unknown) {

    } finally {

      setLoading(false);
    }
  }, [detailsProfile]);


  const fetchProfile = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await patchProfileAPI(payload, id);
        setEditModalProfile(false);
        setLoading(false);
        setNotification({
          status: "success",
          message: "O tipo de perfil do usuário  foi editado com sucesso.",
          title: "Usuário editado com sucesso",
          actived: true,
          onClose: () => closeNotification(),
        });
        resetForm();
        setclearFilesDrop(true);
      } catch (err: unknown) {
        console.error(err);
        setNotification({
          status: "error",
          message:
            "Não foi possível editar o tipo de perfil do usuário no momento, tente novamente mais tarde ou contate o administrador do sistema",
          title: "Falha ao editar usuário",
          actived: true,
          onClose: () => closeNotification(),
        });
        setLoading(false);
      } finally {
        fetchUserProfile()
        fetchProfiles();
      }
    },
    [setLoading, fetchProfiles, payload, detailsProfile, userProfile]
  );

  useEffect(() => {
    // Verifica se o status existe antes de atualizar o estado
    if (detailsProfile?.raw_data?.status) {
      setActiveRadio(detailsProfile?.raw_data.status); // Preenche o estado inicial
    } else {
      setActiveRadio(null); // Garante que o estado seja resetado caso não tenha status
    }
  }, [detailsProfile]); // Reexecuta se o detailsProfile mudar

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setActiveRadio(value); // Atualiza o estado do radio selecionado
    setFieldValue("status", value); // Atualiza o valor no formulário
  };


  useEffect(() => {
    if (detailsProfile?.type) {
      const selectedOptions = options.filter(option =>
        detailsProfile.type.includes(option.value)
      );
      setSelectedProfiles(selectedOptions); // Preenche o estado com as opções selecionadas
    }
  }, [detailsProfile?.type, options]);

  useEffect(() => {
    setFieldValue("document_number", detailsProfile?.document_number);
    setFieldValue("full_name", detailsProfile?.user?.first_name + " " + detailsProfile?.user?.last_name);
    setFieldValue("status", detailsProfile?.raw_data?.status);
    setFieldValue("type_profile", detailsProfile?.type);
    setFieldValue("email", detailsProfile?.user?.email);
  }, [detailsProfile, setFieldValue]);

  useEffect(() => {
    fetchCompanys()
  }, [])


  useEffect(() => {
    fetchProfilesEdit();
  }, [detailsProfile]);

  useEffect(() => {
    if (clearFilesDrop) {
      setclearFilesDrop(false);
    }
  }, [values, clearFilesDrop]);

  return (
    <>
      <Modal
        onClose={() => {
          if (detailsProfile) {
            resetForm()
          }
          setclearFilesDrop(true)
          onClose && onClose();
          setActiveRadio(null)
        }}
        open={open}
        height="690px"
        width="50%"
        overflow
        label={
          <>
            <div>
              <h4 className="m-0 p-0">Editar usuário</h4>
              <Margin mt={10} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[700] font-sans text-[21px]"
              >
                {detailsProfile?.user?.first_name} {detailsProfile?.user?.last_name}
              </h4>
              <Margin mt={6} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 font-[400] text-[18px]"
              >
                {formatCPFCNPJ(String(detailsProfile?.document_number))}
              </h4>
              <Margin mt={5} />
              <h4
                style={{ fontFamily: "IBM Plex Mono" }}
                className="m-0 p-0 text-[16px] font-[400]"
              >
                {detailsProfile?.user.email}
              </h4>
            </div>
          </>
        }
        background="#f4f4f4"
        buttons={[
          {
            label: "Cancelar",
            kind: "ghost",
            dimension: "74",
            onClick: () => {
              resetForm()
              setEditModalProfile(false);
              setActiveRadio(null)
              setclearFilesDrop(true)
              setSelectedProfiles([])

            },
          },
          {
            label: "Salvar edição",
            icon: addicon,
            dimension: "74",
            kind: "primary",
            onClick: () => fetchProfile(String(detailsProfile?.id)),
            disabled: disabledButtonWhenTypeIsClient,
          },
        ]}
      >
        <div className="mt-2 bg-[#c6c6c6] w-[100%] h-[2px]"></div>
        <div style={{}} className="w-[99%] mx-auto mt-0">
          <Padding pt={15} />
          <div className="flex w-[100%] gap-[30px] justify-start">
            <div className="ml-[10px]">
              <Radio
                label="Inativo"
                name="status"
                checked={activeRadio === "inactive"}
                value={"inactive"}
                onChange={handleToggleChange}
              />
            </div>
            <div>
              <Radio
                label="Ativo"
                name="status"
                checked={activeRadio === "active"}
                value={"active"}
                onChange={handleToggleChange}
              />
            </div>
          </div>
          <Margin mb={10} />
          <InputFluid
            label="Nome/razão social completo"
            borderNone
            noRadius
            type="text"
            id="full_name"
            name="full_name"
            disabled={detailsProfile?.user.id !== userProfile?.user.id}
            placeholder="Digite o nome completo"
            onChange={(event) => {
              setFieldValue("full_name", event.target.value);
            }}
            value={values.full_name}
            error={(errors.full_name && touched.full_name) || undefined}
            message={errors.full_name}
          />
          <Margin mb={10} />
          <p style={{ fontSize: '0.85rem', marginLeft: "6px" }}><strong>Obs:</strong> Somente o usuário logado pode alterar o nome.</p>
          <div className="flex justify-center mt-1 items-center">
            {/* <InputFluid
              label="CPF"
              borderNone
              noRadius
              maxLength={16}
              id="document_number"
              onChange={handleChange}
              name="document_number"
              placeholder="00.000.000/0001-00"
            /> */}
            <div
              className={`p-0 w-full flex flex-col justify-center ml-[10px] 
    ${isActive ? "border-[#3a3030]" : "border-[#ff0000]"} 
    focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <p className="text-[11px] text-[#525252]">CPF</p>
              <StyledTextInput
                mask="999.999.999-99"
                placeholder="CPF"
                type="text"
                name="document_number"
                onChange={handleChange}
                value={values.document_number}
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
              // message={errors.document_number}
              />
              <Margin mt={3} />
            </div>
            <Margin mr={2} />
            <InputFluid
              label="E-mail"
              borderNone
              noRadius
              placeholder="Digite o e-mail"
              id="email"
              name="email"
              onChange={handleChange}
              value={values.email}
              message={errors.email}
            />
          </div>
          <Margin mb={5} />
          <InputSelect
            kind="fluid"
            isMulti
            label="Editar Perfil"
            options={options}
            size="large"
            name="type_profile"
            value={selectedProfiles as any}
            placeholder="Selecione o perfil"
            onChange={(event) =>
              handleChangeSelect(event as { label: string; value: string }[])
            }
            noRadius
          />
          <Margin mb={5} />
          <Dropdown
            kind="fluid"
            label="Editar Permissoes"
            options={[
              {
                label: "Aprovador(a) de Pagamentos",
                value: "payment_approver",
              },
              {
                label: "Aprovador(a) de Beneficiários",
                value: "beneficiary_approver",
              },
              {
                label: "Aprovador(a) de Empresas",
                value: "payment_company_approver",
              },
              {
                label: "Nenhuma Permissão",
                value: "no_permission",
              },
            ]}
            size="large"
            name="permission"
            clearFiles={clearFilesDrop}
            placeholder="Selecione Permissoes"
            onChange={handleChange}
            noRadius
          />
          <Dropdown
            kind="fluid"
            label="Editar empresa"
            options={companysOptions}
            size="large"
            name="company"
            placeholder="Selecione empresa"
            noRadius
            onChange={(e) => handleChange(e)}
            clearFiles={clearFilesDrop}
            borderNone
          />
        </div>
        <Margin mt={250} />
      </Modal>
    </>
  );
};
