
export const userICON =
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="User">
            <rect width="16" height="16" fill="white" fill-opacity="0.01" />
            <g id="Vector">
                <path d="M8 2C8.49445 2 8.9778 2.14662 9.38893 2.42133C9.80005 2.69603 10.1205 3.08648 10.3097 3.54329C10.4989 4.00011 10.5484 4.50277 10.452 4.98773C10.3555 5.47268 10.1174 5.91814 9.76777 6.26777C9.41814 6.6174 8.97268 6.8555 8.48773 6.95196C8.00277 7.04843 7.50011 6.99892 7.04329 6.8097C6.58648 6.62048 6.19603 6.30005 5.92133 5.88893C5.64662 5.4778 5.5 4.99445 5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2ZM8 1C7.30777 1 6.63108 1.20527 6.0555 1.58986C5.47993 1.97444 5.03133 2.52107 4.76642 3.16061C4.50152 3.80015 4.4322 4.50388 4.56725 5.18282C4.7023 5.86175 5.03564 6.48539 5.52513 6.97487C6.01461 7.46436 6.63825 7.7977 7.31718 7.93275C7.99612 8.0678 8.69985 7.99849 9.33939 7.73358C9.97893 7.46867 10.5256 7.02007 10.9101 6.4445C11.2947 5.86892 11.5 5.19223 11.5 4.5C11.5 3.57174 11.1313 2.6815 10.4749 2.02513C9.8185 1.36875 8.92826 1 8 1Z" fill="#161616" />
                <path d="M13 15H12V12.5C12 12.1717 11.9353 11.8466 11.8097 11.5433C11.6841 11.24 11.4999 10.9644 11.2678 10.7322C11.0356 10.5001 10.76 10.3159 10.4567 10.1903C10.1534 10.0647 9.8283 10 9.5 10H6.5C5.83696 10 5.20107 10.2634 4.73223 10.7322C4.26339 11.2011 4 11.837 4 12.5V15H3V12.5C3 11.5717 3.36875 10.6815 4.02513 10.0251C4.6815 9.36875 5.57174 9 6.5 9H9.5C10.4283 9 11.3185 9.36875 11.9749 10.0251C12.6313 10.6815 13 11.5717 13 12.5V15Z" fill="#161616" />
            </g>
        </g>
    </svg>


export const firstProgress =
    <svg width="16" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Circle-dash">
            <rect width="20" height="20" fill="white" />
            <g id="Vector">
                <path d="M3.84961 2.35058C3.2761 2.79067 2.77066 3.31296 2.34961 3.90058L3.14961 4.50058C3.51683 3.9913 3.95453 3.53676 4.44961 3.15058L3.84961 2.35058Z" fill="#161616" />
                <path d="M2.29961 6.15058L1.34961 5.85058C1.10809 6.54126 0.989624 7.26896 0.999605 8.00058H1.99961C1.99758 7.37164 2.09893 6.74665 2.29961 6.15058Z" fill="#161616" />
                <path d="M1.34961 10.2006C1.58149 10.8978 1.91873 11.5554 2.34961 12.1506L3.14961 11.5506C2.78815 11.0445 2.50184 10.4887 2.29961 9.90058L1.34961 10.2006Z" fill="#161616" />
                <path d="M3.89961 13.6506C4.49477 14.0815 5.15239 14.4187 5.84961 14.6506L6.14961 13.7006C5.56147 13.4983 5.00571 13.212 4.49961 12.8506L3.89961 13.6506Z" fill="#161616" />
                <path d="M5.84961 1.35058L6.14961 2.30058C6.74567 2.0999 7.37067 1.99855 7.99961 2.00058V1.00058C7.26798 0.990601 6.54029 1.10906 5.84961 1.35058Z" fill="#161616" />
                <path d="M12.0996 13.6506C12.6886 13.2116 13.2107 12.6895 13.6496 12.1006L12.8496 11.5006C12.4778 12.0225 12.0215 12.4787 11.4996 12.8506L12.0996 13.6506Z" fill="#161616" />
                <path d="M13.6996 9.85058L14.6496 10.1506C14.8671 9.45401 14.9849 8.73017 14.9996 8.00058H13.9996C14.0016 8.62952 13.9003 9.25452 13.6996 9.85058Z" fill="#161616" />
                <path d="M14.5996 5.80058C14.3677 5.10336 14.0305 4.44575 13.5996 3.85058L12.7996 4.45058C13.1611 4.95669 13.4474 5.51245 13.6496 6.10058L14.5996 5.80058Z" fill="#161616" />
                <path d="M12.0496 2.30058C11.4544 1.8697 10.7968 1.53246 10.0996 1.30058L9.79961 2.25058C10.3877 2.45282 10.9435 2.73912 11.4496 3.10058L12.0496 2.30058Z" fill="#161616" />
                <path d="M10.1496 14.6506L9.84961 13.7006C9.25354 13.9013 8.62854 14.0026 7.99961 14.0006V15.0006C8.72631 14.9573 9.4467 14.84 10.1496 14.6506Z" fill="#161616" />
            </g>
        </g>
    </svg>

export const secondProgress =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Incomplete">
            <rect width="16" height="16" fill="white" />
            <g id="Vector">
                <path d="M11.8821 3.42965L12.5246 2.6639C11.904 2.13853 11.1963 1.72553 10.4336 1.4435L10.0918 2.3823C10.745 2.62459 11.3508 2.97902 11.8821 3.42965Z" fill="rgb(10, 74, 135)" />
                <path d="M13.905 7L14.8889 6.7936C14.7506 5.99468 14.4734 5.22616 14.07 4.52285L13.2044 5C13.5499 5.622 13.7868 6.29836 13.905 7Z" fill="rgb(10, 74, 135)" />
                <path d="M10.0918 13.6177L10.4336 14.5565C11.1963 14.2745 11.904 13.8615 12.5246 13.3361L11.8821 12.5703C11.3508 13.021 10.745 13.3754 10.0918 13.6177Z" fill="rgb(10, 74, 135)" />
                <path d="M13.2044 11L14.07 11.5C14.4737 10.7886 14.7508 10.0126 14.8891 9.2064L13.905 9.03295C13.7867 9.72415 13.5497 10.3897 13.2044 11Z" fill="rgb(10, 74, 135)" />
                <path d="M8 15V1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15Z" fill="rgb(10, 74, 135)" />
            </g>
        </g>
    </svg>

export const thirdProgress =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Checkmark--outline">
            <rect width="16" height="16" fill="white" />
            <g id="Vector">
                <path d="M7 10.707L4.5 8.2065L5.2065 7.5L7 9.293L10.7925 5.5L11.5 6.2075L7 10.707Z" fill="rgb(10, 74, 135)" />
                <path d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM8 14C6.81332 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14Z" fill="rgb(10, 74, 135)" />
            </g>
        </g>
    </svg>


export const verifyIcon =
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Checkmark--filled">
            <rect width="16" height="16" fill="white" />
            <path id="Vector" d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1ZM7 10.7954L4.5 8.2954L5.2953 7.5L7 9.2046L10.705 5.5L11.5029 6.29295L7 10.7954Z" fill="rgb(10, 74, 135)" />
        </g>
    </svg>


export const filterIcon =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Filter">
            <rect width="16" height="16" fill="white" fill-opacity="0.01" />
            <path id="Vector" d="M9 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V9.205L2.295 5.5C2.10721 5.31332 2.00112 5.05979 2 4.795V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4.795C13.9989 5.05979 13.8928 5.31332 13.705 5.5L10 9.205V13C10 13.2652 9.89464 13.5196 9.70711 13.7071C9.51957 13.8946 9.26522 14 9 14ZM3 3V4.795L7 8.795V13H9V8.795L13 4.795V3H3Z" fill="rgb(10, 74, 135)" />
        </g>
    </svg>

export const arrowIcon =

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrow--right">
            <path id="Vector"
                d="M11.25 3.75L10.3562 4.62063L15.0938 9.375H2.5V10.625H15.0938L10.3562
              15.3581L11.25 16.25L17.5 10L11.25 3.75Z" fill="rgb(10, 74, 135)" />
        </g>
    </svg>


export const arrowButtonIcon =
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Caret--right">
            <rect width="16" height="16" />
            <path id="Vector" d="M6 4L11 8L6 12V4Z" fill="#161616" />
        </g>
    </svg>


export const sendIconSVG =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Send">
            <rect width="16" height="16" fill-opacity="0.01" />
            <path id="Vector"
                d="M13.7249 7.55476L2.72494 2.05476C2.63873 2.01164 2.54189 1.99437 2.44608 2.00501C2.35028 2.01566 2.25959 2.05377 2.18494 2.11476C2.11365 2.17451 2.06045 2.25295 2.0313 2.34128C2.00215 2.42961 1.99822 2.52432 2.01994 2.61476L3.49994 7.99976L1.99994 13.3698C1.97956 13.4453 1.97718 13.5245 1.99299 13.6012C2.00881 13.6778 2.04239 13.7496 2.09102 13.8109C2.13965 13.8722 2.20199 13.9212 2.27301 13.954C2.34403 13.9868 2.42176 14.0025 2.49994 13.9998C2.57821 13.9993 2.65528 13.9805 2.72494 13.9448L13.7249 8.44476C13.8068 8.4028 13.8756 8.33905 13.9236 8.26054C13.9716 8.18202 13.997 8.09178 13.997 7.99976C13.997 7.90774 13.9716 7.8175 13.9236 7.73898C13.8756 7.66047 13.8068 7.59672 13.7249 7.55476ZM3.27494 12.5548L4.37994 8.49976H8.99994V7.49976H4.37994L3.27494 3.44476L12.3799 7.99976L3.27494 12.5548Z" fill="rgb(10, 74, 135)" />
        </g>
    </svg>

export const addIconSVG =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Add">
            <rect width="16" height="16" />
            <path id="Vector" d="M8.5 7.5V4H7.5V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5H8.5Z" fill="rgb(10, 74, 135)" />
        </g>
    </svg>


export const SearchIconSVG =

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Notification">
            <rect width="20" height="20" />
            <path id="Vector" d="M17.9419 12.0581L16.25 10.3662V8.125C16.248 6.57622 15.6718 5.08319 14.6328 3.93462C13.5938 2.78605 12.1658 2.06352 10.625 1.90675V0.625H9.375V1.90675C7.83417 2.06352 6.4062 2.78605 5.36721 3.93462C4.32822 5.08319 3.75201 6.57622 3.75 8.125V10.3662L2.05806 12.0581C1.94087 12.1753 1.87502 12.3343 1.875 12.5V14.375C1.875 14.5408 1.94085 14.6997 2.05806 14.8169C2.17527 14.9342 2.33424 15 2.5 15H6.875V15.4855C6.86145 16.2784 7.14095 17.0483 7.65993 17.6479C8.17891 18.2475 8.90088 18.6344 9.6875 18.7347C10.122 18.7778 10.5606 18.7295 10.9753 18.5929C11.39 18.4563 11.7715 18.2344 12.0953 17.9415C12.419 17.6486 12.6779 17.2912 12.8553 16.8922C13.0326 16.4933 13.1245 16.0616 13.125 15.625V15H17.5C17.6658 15 17.8247 14.9342 17.9419 14.8169C18.0592 14.6997 18.125 14.5408 18.125 14.375V12.5C18.125 12.3343 18.0591 12.1753 17.9419 12.0581ZM11.875 15.625C11.875 16.1223 11.6775 16.5992 11.3258 16.9508C10.9742 17.3025 10.4973 17.5 10 17.5C9.50272 17.5 9.02581 17.3025 8.67417 16.9508C8.32254 16.5992 8.125 16.1223 8.125 15.625V15H11.875V15.625ZM16.875 13.75H3.125V12.7588L4.81687 11.0669C4.93409 10.9497 4.99996 10.7907 5 10.625V8.125C5 6.79892 5.52678 5.52715 6.46447 4.58947C7.40215 3.65178 8.67392 3.125 10 3.125C11.3261 3.125 12.5979 3.65178 13.5355 4.58947C14.4732 5.52715 15 6.79892 15 8.125V10.625C15 10.7907 15.0659 10.9497 15.1831 11.0669L16.875 12.7588V13.75Z" fill="white" />
        </g>
    </svg>

export const IconSucess =
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Status icon">
            <rect width="20" height="20" />
            <rect id="Fill" x="5" y="5" width="10" height="10" fill="black" />
            <g id="Checkmark--filled">
                <rect width="20" height="20" />
                <path id="Vector" d="M10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM8.75 13.4942L5.625 10.3692L6.61913 9.375L8.75 11.5058L13.3813 6.875L14.3786 7.86619L8.75 13.4942Z" fill="#42BE65" />
            </g>
        </g>
    </svg>


export const InfoIcon =

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Status icon">
            <rect width="20" height="20" />
            <rect id="Fill" x="5" y="3.75" width="10" height="12.5" fill="black" />
            <g id="Information--filled">
                <rect width="20" height="20" />
                <path id="Vector" d="M10 1.25C8.26942 1.25 6.57769 1.76318 5.13876 2.72464C3.69983 3.6861 2.57832 5.05267 1.91606 6.65152C1.25379 8.25037 1.08051 10.0097 1.41813 11.707C1.75575 13.4044 2.58911 14.9635 3.81282 16.1872C5.03653 17.4109 6.59563 18.2442 8.29296 18.5819C9.9903 18.9195 11.7496 18.7462 13.3485 18.0839C14.9473 17.4217 16.3139 16.3002 17.2754 14.8612C18.2368 13.4223 18.75 11.7306 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25ZM10 5C10.1854 5 10.3667 5.05498 10.5209 5.158C10.675 5.26101 10.7952 5.40743 10.8661 5.57873C10.9371 5.75004 10.9557 5.93854 10.9195 6.1204C10.8833 6.30225 10.794 6.4693 10.6629 6.60041C10.5318 6.73152 10.3648 6.82081 10.1829 6.85699C10.001 6.89316 9.81254 6.87459 9.64124 6.80364C9.46993 6.73268 9.32351 6.61252 9.2205 6.45835C9.11749 6.30418 9.0625 6.12292 9.0625 5.9375C9.0625 5.68886 9.16128 5.4504 9.33709 5.27459C9.51291 5.09877 9.75136 5 10 5ZM12.5 15.0781H7.5V13.6719H9.29688V10.0781H8.125V8.67188H10.7031V13.6719H12.5V15.0781Z" fill="#4589FF" />
            </g>
        </g>
    </svg>

export const removeIconToast =
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <rect width="16" height="16" />
        <path d="M12 4.7L11.3 4L8 7.3L4.7 4L4 4.7L7.3 8L4 11.3L4.7 12L8 8.7L11.3 12L12 11.3L8.7 8L12 4.7Z" fill="#161616" />
    </svg>


export const iconTrasition =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Connect">
            <rect width="16" height="16" fill="white" fill-opacity="0.01" />
            <path id="Vector" d="M11.5 8.00509C10.7467 8.00581 10.0137 8.24955 9.40999 8.70009L7.29999 6.59009C7.75586 5.99077 8.00185 5.25809 7.99999 4.50509C8.00197 3.71654 7.73759 2.95044 7.24973 2.33092C6.76186 1.71141 6.07908 1.2748 5.31205 1.09185C4.54502 0.908892 3.73868 0.990319 3.02371 1.32293C2.30874 1.65554 1.72703 2.21984 1.37287 2.92438C1.01871 3.62893 0.912843 4.43242 1.07243 5.20466C1.23201 5.97689 1.64769 6.67261 2.25211 7.17906C2.85652 7.68551 3.61425 7.97301 4.4025 7.99498C5.19074 8.01695 5.96331 7.77209 6.59499 7.30009L8.69999 9.41009C8.22812 10.0416 7.98326 10.8139 8.00508 11.602C8.0269 12.39 8.31412 13.1476 8.82021 13.752C9.32631 14.3565 10.0216 14.7723 10.7936 14.9323C11.5655 15.0922 12.3688 14.9869 13.0735 14.6333C13.7781 14.2798 14.3427 13.6987 14.6758 12.9842C15.009 12.2698 15.0912 11.4637 14.9091 10.6967C14.727 9.92968 14.2913 9.24659 13.6726 8.75808C13.0539 8.26957 12.2883 8.00425 11.5 8.00509ZM1.99999 4.50509C1.99999 4.01064 2.14662 3.52729 2.42132 3.11617C2.69602 2.70504 3.08647 2.38461 3.54328 2.19539C4.0001 2.00617 4.50277 1.95667 4.98772 2.05313C5.47267 2.14959 5.91813 2.38769 6.26776 2.73733C6.61739 3.08696 6.85549 3.53241 6.95196 4.01737C7.04842 4.50232 6.99891 5.00499 6.80969 5.4618C6.62047 5.91862 6.30004 6.30906 5.88892 6.58377C5.4778 6.85847 4.99445 7.00509 4.49999 7.00509C3.83695 7.00509 3.20107 6.7417 2.73223 6.27286C2.26339 5.80402 1.99999 5.16813 1.99999 4.50509Z" fill="#29183A" />
        </g>
    </svg>

export const iconRole =

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Rule">
            <rect width="16" height="16" fill="white" fill-opacity="0.01" />
            <g id="Vector">
                <path d="M11 8H5V9H11V8Z" fill="rgb(10, 74, 135)" />
                <path d="M11 5H5V6H11V5Z" fill="rgb(10, 74, 135)" />
                <path d="M8 15L4.9121 13.3535C4.03173 12.8852 3.2955 12.186 2.78246 11.3309C2.26941 10.4758 1.99892 9.49719 2 8.5V2C2.00029 1.73487 2.10574 1.48069 2.29322 1.29321C2.48069 1.10574 2.73488 1.00029 3 1H13C13.2651 1.00029 13.5193 1.10574 13.7068 1.29321C13.8943 1.48069 13.9997 1.73487 14 2V8.5C14.0011 9.49717 13.7306 10.4758 13.2175 11.3309C12.7045 12.1859 11.9683 12.8851 11.0879 13.3534L8 15ZM3 2V8.5C2.99914 9.3159 3.22048 10.1166 3.64028 10.8163C4.06007 11.5159 4.66246 12.088 5.3828 12.4712L8 13.8666L10.6172 12.4712C11.3375 12.088 11.9399 11.5159 12.3597 10.8163C12.7795 10.1166 13.0009 9.3159 13 8.5V2H3Z" fill="#29183A" />
            </g>
        </g>
    </svg>
