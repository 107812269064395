import { Button, Column, Modal } from "@carbon/react";
import Badge from "~/components/Badge";
import Margin from "~/components/Margin";
import editIcon from "~/assets/editicon.png";
import useAttend from "~/hooks/useAttend";
import { checkDueDate, formatCPFCNPJ, formatDateTime } from "~/services/utils";
import { Edit } from "@carbon/icons-react";
import useUser from "~/hooks/useUser";
import { useCallback, useMemo, useState } from "react";
import {
  getAttendDetailsAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import { Tag, Button as ButtonProsperita } from "prosperita-dumbo-react";
import useArea from "~/hooks/useArea";
import { getNotificationsInvite, postNotificationsInvite } from "~/services/api/notifications";
import useNotification from "~/hooks/useToast";

const LateralDetailsAttends = () => {
  const {
    attendDetails,
    subAttends,
    // setOpenUpdatedAttendAreaModal,
    subAttendDetails,
    setOpenUpdatedOwnerAttendModal,
    // setOpenUpdatedAreaTypeModal,
    //  attendLog,
    attendParentData,
    setAttendDetails,
    setOpenUpdatedAttendStatusModal
  } = useAttend();

  const { attendAreaProfiles } =
    useArea();

  const {
    setInviteNotification,
    inviteNotification,
  } = useNotification()

  const { userProfile } = useUser();

  const isSolicitant = useMemo(() => {
    return attendDetails?.client.id === userProfile?.id;
  }, [attendDetails, userProfile]);


  const _fetchAttends = useCallback(async () => {
    if (attendDetails) {
      try {
        const { data } = await getAttendDetailsAPI(Number(attendDetails?.id));

        setAttendDetails(data);
      } catch (err: unknown) {
        console.error(err);
      }
    }
  }, [attendDetails, setAttendDetails]);

  const fetchSendNewStatus = useCallback(
    async (id: number) => {
      try {
        await patchAttendDetailsAPI(Number(attendDetails?.id), {
          status: id,
        });
        _fetchAttends();
        setOpenCancelAttendModal(false);
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [attendDetails, _fetchAttends]
  );

  const fetchSendNewStatusSubAttends = useCallback(
    async (id: number) => {
      try {
        await Promise.all(
          subAttends.map(async (subAttend) => {
            await patchAttendDetailsAPI(Number(subAttend.id), {
              status: id,
            });
          })
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [subAttends]
  );

  const fetchNotificatonFiltered = useCallback(async () => {

    try {
      const { data } = await getNotificationsInvite(Number(userProfile?.user?.id));
      setInviteNotification(data.results)
    } catch (e) {
    }
  }, [userProfile, inviteNotification])

  const fetchEnviteNotification = async () => {

    try {
      await postNotificationsInvite({
        attend: attendDetails?.id,
        body: "O atendimento foi cancelado",
        subject: `Aviso: Atendimento Cancelado  #${attendDetails?.id}`,
        notification_type: "App",
        type: "user_invite",
        user: attendDetails?.client?.user?.id,
      });
      fetchNotificatonFiltered()
    } catch {

    }
  }

  const [openCancelAttendModal, setOpenCancelAttendModal] =
    useState<boolean>(false);

  /// verificar os gestores da area

  const areaManagersForAttend = attendAreaProfiles.filter((user) =>
    user.profile.type.includes("area_manager")
  );
  const areaManagersIds = areaManagersForAttend.map(manager => manager.profile.id);
  const isUserAreaManager = areaManagersIds.includes(Number(userProfile?.id));

  /// verificar os gestores da area


  return (
    <Column span={4} sm={{}}>
      <div
        style={{
          width: "100%",
          flex: 1,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#f7f7f7",
          position: "relative",
          top: 20,
        }}
      >
        <Margin mb={20} />

        <div
          style={{
            width: "100%",
            padding: "20px"
          }}
        >
          {subAttendDetails !== undefined && (
            <div>
              <h6 style={{ fontWeight: "bold" }}>Vinculado ao</h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <a
                    href={`/app/attends/${attendParentData?.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    #{attendParentData?.id} -{" "}
                    {attendParentData?.type.name ?? "-"}
                  </a>
                </div>
              </div>
              <Margin mb={20} />
            </div>
          )}
          <h6 style={{ fontWeight: "bold" }}>Status</h6>
          <div className="w-[100%] flex items-center justify-between">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={isSolicitant ? 0 : -10} />
                {attendDetails?.status.name ? (
                  <Badge
                    label={
                      (isSolicitant || isUserAreaManager) && attendDetails.status.id === 25
                        ? "Alocado"
                        : attendDetails.status.name
                    }
                    color={
                      (isSolicitant || isUserAreaManager) && attendDetails.status.id === 3
                        ? "#e3e3e3"
                        : attendDetails.status?.color
                    }
                    textColor="#000000"
                  />
                ) : (
                  "Não encontrado"
                )}
              </div>
            </div>
            <div className="w-[50px]">
              {(isSolicitant || isUserAreaManager) &&
                <ButtonProsperita
                  icon={editIcon}
                  kind="ghost"
                  size="small"
                  noPadding="2px"
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    setOpenUpdatedAttendStatusModal((prev) => !prev)
                  }}
                />}
            </div>
          </div>
          <Margin mb={10} />
          {(isSolicitant || isUserAreaManager) && <Margin mb={20} />}
          <div>
            <h6 style={{ fontWeight: "bold" }}>Solicitante</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4 style={{ fontWeight: "600" }}>
                  {attendDetails?.client?.raw_data?.company_name
                    ? attendDetails?.client?.raw_data?.company_name
                    : !attendDetails?.client?.raw_data?.company_name &&
                    (attendDetails?.client.type.includes("internal") ||
                      attendDetails?.client.type.includes("area_manager") ||
                      attendDetails?.client.type.includes("tool_manager")) &&
                    "Banvox"}
                </h4>
                {attendDetails?.client?.raw_data?.company_document_number && (
                  <h5 style={{}}>
                    {formatCPFCNPJ(
                      attendDetails?.client?.raw_data?.company_document_number
                    )}
                  </h5>
                )}
                <h4>{attendDetails?.client?.name}</h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Fundo</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4> {attendDetails?.fund?.raw_data?.short_name}</h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Responsável</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <Margin mb={(isSolicitant || isUserAreaManager) ? 5 : -5} />
                <h4> {attendDetails?.owner?.name ?? "-"}</h4>
              </div>
              {(isSolicitant || isUserAreaManager) && (
                <Button
                  kind="ghost"
                  hasIconOnly
                  iconDescription="Atribuir responsável"
                  renderIcon={Edit}
                  onClick={() => setOpenUpdatedOwnerAttendModal(true)}
                  id="filter"
                />
              )}
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Área</h6>
            <Margin mb={(isSolicitant || isUserAreaManager) ? 5 : -5} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4>{attendDetails?.area?.name ?? "-"}</h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>Tipo de Atendimento</h6>
            <Margin mb={(isSolicitant || isUserAreaManager) ? 5 : -5} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h4>{attendDetails?.type?.name ?? "-"}</h4>
              </div>
            </div>
          </div>
          <Margin mb={20} />
          <div>
            <h6 style={{ fontWeight: "bold" }}>SLA</h6>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "100%", marginTop: "8px" }}>
                <div>
                  {attendDetails?.sla_deadline && (
                    <>
                      <div className="w-[100px] my-[7px]">
                        <Tag position="center"
                          type={checkDueDate(new Date(String(attendDetails.sla_deadline))).status
                            === "No prazo" ? "green" : "red"}>
                          <p className="p-[7px] m-0">
                            {checkDueDate(new Date(String(attendDetails.sla_deadline))).status}
                          </p>
                        </Tag>
                      </div>
                      <div className="my-[7px]">
                        {checkDueDate(new Date(String(attendDetails.sla_deadline))).status
                          === "No prazo" ? checkDueDate(new Date(String(attendDetails.sla_deadline))).hora :
                          `- ${checkDueDate(new Date(String(attendDetails.sla_deadline))).hora}`
                        }

                      </div>
                    </>
                  )}
                  {attendDetails?.sla_paused_on && !attendDetails?.sla_deadline && (
                    <div>
                      <div className="w-[100px] my-[7px]">
                        <Tag position="center"
                          type={"blue"}
                        >
                          <p className="p-[7px] m-0">
                            Pausado
                          </p>
                        </Tag>
                      </div>
                      <p>{formatDateTime(attendDetails?.sla_paused_on)}</p>
                    </div>
                  )}
                  {!attendDetails?.sla_paused_on && !attendDetails?.sla_deadline && (
                    <div>
                      -----
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Margin mb={20} />
            {((isSolicitant || isUserAreaManager)) &&
              (attendDetails?.status.name === "Aberto" || attendDetails?.status.name === "Alocado" ||
                attendDetails?.status.name === "Alocado – em análise") &&
              (
                <div>
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      setOpenCancelAttendModal(true);
                    }}
                    kind="danger--tertiary"
                  >
                    Cancelar atendimento
                  </Button>
                </div>
              )}
          </div>
          <Margin mb={20} />
        </div>
      </div>
      <Modal
        modalHeading={`Cancelar atendimento - ${attendDetails?.type.name}`}
        size="sm"
        open={openCancelAttendModal}
        onRequestClose={() => setOpenCancelAttendModal(false)}
        primaryButtonText="Sim, quero cancelar o atendimento"
        secondaryButtonText="Não, desejo voltar"
        onRequestSubmit={async () => {
          fetchSendNewStatus(6);
          fetchSendNewStatusSubAttends(5);
          fetchEnviteNotification()
        }}
        style={{ zIndex: 2 }}
      >
        <div>
          <Margin mb={50} />
          <h3 style={{ textAlign: "center" }}>
            {" "}
            Atenção, você realmente deseja cancelar este atendimento?{" "}
          </h3>
          <Margin mb={150} />
        </div>
      </Modal>
    </Column>
  );
};
export default LateralDetailsAttends;
