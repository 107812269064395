import { Breadcrumb, Button, Margin, TableBasic, Tag } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import useWorkflow from "~/hooks/useWorkflow"
import { getWorkflowStatus } from "~/services/api/workflow"
import viewIcon from "~/assets/viewIcon.png"
// import editIcon from "~/assets/editicon.png"
import EmptyStateIcon from "~/assets/EmptyState.png"
import { Loading } from "@carbon/react";
import { ModalCreateWorkflow } from "./ModalCreateWorkflow"

export const ListWorkflowStatus = () => {

    const navigate = useNavigate()

    const {
        flowList,
        setFlowList,
        modalCreatWorkflow,
        setModalCreateWorkflow,
        setActiveStatuses
    } = useWorkflow()

    const [error, setError] = useState<boolean>(false)

    const fecthWorkflowStatus = useCallback(async () => {

        try {

            const { data } = await getWorkflowStatus()

            setFlowList(data.results)

            setError(false)

        } catch (err) {

            setError(true)

            console.log(err)
        }

    }, [flowList])


    const listWorkflow = useMemo(() => {

        return flowList && flowList.map((flow) => ({
            "Nome": flow.name,
            "Descrição":
                flow.description.length > 15 ?
                    `${flow.description.slice(0, 40)}...` :
                    flow.description
            ,
            "Status": flow.active &&
                <div className="w-[50px] text-center m-0 p-0">
                    {flow.active ? (
                        <Tag
                            type={flow.active ? "green" : "red"}
                            position="center"
                            width="100px"
                        >
                            <p className="text-[14px] m-0 p-[5px]">
                                {flow.active ? "Ativo" : "Inativo"}
                            </p>
                        </Tag>
                    ) : null}
                </div>,
            view:
                < div className="w-[30px]" >
                    <Button
                        kind="ghost"
                        size="small"
                        noPadding="2px"
                        icon={viewIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/app/status-flow-details/${flow.id}`);
                        }}
                    >
                    </Button>
                </div >,
            edit:
                <div className="w-[30px]" >
                    {/* <Button
                        kind="ghost"
                        size="small"
                        noPadding="2px"
                        icon={editIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                    </Button> */}
                </div >

        }));

    }, [flowList]);

    useEffect(() => {
        setActiveStatuses(null)
        fecthWorkflowStatus()
    }, [])

    return (
        <div>
            <div className="w-[100%] mx-auto">
                {!error ? (
                    flowList === null ? (
                        <Loading withOverlay active style={{ zIndex: 4 }} />
                    ) : flowList.length > 0 ? (
                        <div>
                            <div className="w-full mx-auto">
                                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                                    <div className="flex flex-col">
                                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                                            <Breadcrumb
                                                crumbsOptions={[
                                                    { crumb: "Configurações", onClick: () => navigate("/app") },
                                                    { crumb: "Fluxos de status" },
                                                ]}
                                                endHiddenIndex={5}
                                                startHiddenIndex={5}
                                            />
                                        </div>
                                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                                            Fluxo de status de atendimento
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <Margin top={50} />
                            <div className="w-[95%] mx-auto">
                                <TableBasic
                                    search
                                    columnTitles={["Nome", "Descrição", "Status", "", ""]}
                                    pageSizes={[10, 20]}
                                    rowData={listWorkflow || []}
                                    buttons={[
                                        // {
                                        //     label: "Criar Workflow",
                                        //     kind: "primary",
                                        //     onClick: () => { setModalCreateWorkflow((prev) => !prev) },
                                        //     position: "right"
                                        // }
                                    ]}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="w-full mx-auto">
                                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                                    <div className="flex flex-col">
                                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                                            <Breadcrumb
                                                crumbsOptions={[
                                                    { crumb: "Configurações", onClick: () => navigate("/app") },
                                                    { crumb: "Fluxos de status" },
                                                ]}
                                                endHiddenIndex={5}
                                                startHiddenIndex={5}
                                            />
                                        </div>
                                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                                            Fluxo de status de atendimento
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-start text-start h-[60vh] ">
                                <div className="w-[92%] mx-auto">
                                    <div className="flex justify-end">
                                        <Button
                                            kind="primary"
                                            size="large"
                                            label="Criar Fluxo de status"
                                            onClick={() => setModalCreateWorkflow((prev) => !prev)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <img src={EmptyStateIcon} />
                                    </div>
                                    <Margin top={40} />
                                    <h6 className="text-[20px] not-italic font-normal leading-[28px]">Não há nenhum Fluxo de status de criado</h6>
                                    <Margin top={15} />
                                    <h5 className="text-[15px] not-italic font-normal leading-[28px]">Não há Fluxo de status de para serem exibidos no momento,
                                        para criar um Fluxo de status click no botão
                                        direito localizado no canto superior.</h5>
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <div className="flex items-center justify-center text-center h-[90vh]">
                        <div className="w-[500px] text-red-600 bg-red-100 p-4 rounded-lg">
                            Erro ao requisitar API. Tente novamente mais tarde.
                        </div>
                    </div>
                )}
            </div>
            <ModalCreateWorkflow
                onClose={() => setModalCreateWorkflow((prev) => !prev)}
                open={modalCreatWorkflow}
            />
        </div>
    )
}
