import constate from "constate";
import { useState } from "react";
import { Node } from "reactflow";
import { Flow, WorkflowStatus } from "~/services/api/workflow";


const [WorkflowProvider, useWorkflow] = constate(() => {

    const [flowList, setFlowList] = useState<Flow[] | null>(null);

    const [selectedFlow, setSelectedFlow] = useState<Flow>()

    const [activeStatuses, setActiveStatuses] = useState<WorkflowStatus[] | null>(null)

    const [modalAddRole, setModalAddRole] = useState<boolean>(false)

    const [modalAddTrasition, setmodalAddTrasition] = useState<boolean>(false)

    const [modalCreatWorkflow, setModalCreateWorkflow] = useState<boolean>(false)

    const [modaladdStatus, setmodaladdStatus] = useState<boolean>(false)

    const [editStatus, setEditStatus] = useState<Node | null>(null)

    const [modaleditStatus, setModaleditStatus] = useState<boolean>(false)

    const [modalremoveStatus, setModalremoveStatus] = useState<boolean>(false)

    return {
        setModalremoveStatus,
        modalremoveStatus,
        setModaleditStatus,
        modaleditStatus,
        setEditStatus,
        editStatus,
        modalAddRole,
        setModalAddRole,
        setmodalAddTrasition,
        modalAddTrasition,
        setmodaladdStatus,
        modaladdStatus,
        setFlowList,
        flowList,
        activeStatuses,
        setActiveStatuses,
        setSelectedFlow,
        selectedFlow,
        setModalCreateWorkflow,
        modalCreatWorkflow
    };
});

export { WorkflowProvider };

export default useWorkflow;
