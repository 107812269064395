import { Grid, Column } from "@carbon/react";
import editicon from "~/assets/editicon.png";

import renewicon from "~/assets/Renew.png";

import addicon from "~/assets/iconadd.png";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo } from "react";

import { getAttendTypeAPI } from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import NewAttendTypeContentModal from "./NewAttendTypeContentModal";
import DeleteAttendTypeContentModal from "./DeleteAttendTypeContentModal";
import EditAttendTypeModal from "./EditAttendTypeModal";
import useLoading from "~/hooks/useLoading";
import { Button, TableBasic, Tag, Tooltip } from "prosperita-dumbo-react";
import useWorkflow from "~/hooks/useWorkflow";
import { getWorkflowStatus } from "~/services/api/workflow";

const AttendTypes = () => {
  const {
    setAttendTypes,
    attendTypes,
    openNewAttendTypeModal,
    setOpenNewAttendTypeModal,
    openDeleteAttendTypeModal,
    setOpenDeleteAttendTypeModal,
    setAttendTypesDetails,
    setOpenEditAttendTypeModal,
    openEditAttendTypeModal,
  } = useAttendType();
  const { setLoading } = useLoading();

  const {
    flowList,
    setFlowList,
  } = useWorkflow()


  const fecthWorkflowStatus = useCallback(async () => {

    try {

      const { data } = await getWorkflowStatus()

      setFlowList(data.results)

    } catch (err) {

      console.log(err)
    }

  }, [flowList])


  const dataAttendTypes = useMemo(() => {
    return attendTypes.map((attendType, index) => {
      return {
        "Nome": (
          attendType.name
        ),
        SLA: `${attendType?.sla} ${attendType.sla_type === "day" ? "Dia(s)" : attendType.sla_type === "hour" ? "Hora(s)" : "Minuto(s)"}`,
        Status:
          <div className="w-[80px] m-0 p-0">
            <Tag type={attendType?.active === false ? "red" : "green"} position="center">
              <p className="text-[15px] m-0 p-[5px]">{attendType?.active ? "Ativo" : "inativo"}</p>
            </Tag>
          </div>
        ,
        "Fluxo": flowList?.filter((flow) => flow.id === attendType.workflow)
          .map((flow) => flow.name)
          .join(", ") || "Workflow não definido",
        edit: (
          <Tooltip align="top" label="Editar" size="large">
            <div key={index} className="m-0 p-0">
              <Button
                label=""
                size="small"
                icon={editicon}
                kind="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenEditAttendTypeModal(true);
                  setAttendTypesDetails(attendType);
                }}
              />
            </div>
          </Tooltip>
        ),
      };
    });
  }, [attendTypes, setAttendTypesDetails, setOpenEditAttendTypeModal]);

  const tableHeadersProfile: string[] = ["Nome", "SLA", "Status", "Fluxo", ""];

  const fetchAttendTypes = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      console.error(err);
    }
  }, [setAttendTypes, setLoading]);

  useEffect(() => {
    fetchAttendTypes();
  }, [fetchAttendTypes]);

  useEffect(() => {
    fecthWorkflowStatus()
  }, [])

  return (
    <Grid>
      <Column span={16}>
        <Margin mt={64} /> <h1>Tipos de atendimento</h1>
        <Margin mt={34} /> <Margin mt={20} />
        <TableBasic
          search
          buttons={[
            {
              kind: "ghost",
              label: "",
              dimension: "37",
              width: "37px",
              onClick: () => fetchAttendTypes(),
              position: "right",
              icon: renewicon,
            },

            {
              kind: "primary",
              label: "Novo tipo de atendimento",
              dimension: "",
              onClick: () => setOpenNewAttendTypeModal(true),
              position: "right",
              icon: addicon,
            },
          ]}
          pageSizes={[20, 5, 25, 30, 40, 50]}
          rowData={dataAttendTypes}
          columnTitles={tableHeadersProfile}
        />
      </Column>
      <NewAttendTypeContentModal
        onClose={() => setOpenNewAttendTypeModal(false)}
        open={openNewAttendTypeModal}
      />
      <EditAttendTypeModal
        onClose={() => setOpenEditAttendTypeModal(false)}
        open={openEditAttendTypeModal}
      />

      <DeleteAttendTypeContentModal
        onClose={() => setOpenDeleteAttendTypeModal(false)}
        open={openDeleteAttendTypeModal}
      />
    </Grid>
  );
};

export default AttendTypes;
