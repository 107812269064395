import {
  RadioButtonGroup,
  TextInput,
  RadioButton,
  Toggle,
} from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { Dropdown } from "carbon-components-react";
import {
  getAttendTypeAPI,
  ISendNewAttendTypeBody,
  patchAttendTypeAPI,
} from "~/services/api/type";
import useAttendType from "~/hooks/useType";
import { toast } from "react-toastify";
import Label from "~/components/Label";
import { Modal } from "prosperita-dumbo-react";
import useWorkflow from "~/hooks/useWorkflow";

type Values = {
  name: string;
  sla: number;
  workflow: number;
  active: boolean | undefined;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const EditAttendTypeModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    setOpenEditAttendTypeModal,
    setAttendTypes,
    attendTypesDetails } =
    useAttendType();

  const {
    flowList,
  } = useWorkflow()

  const [selectedRadio, setSelectedRadio] = useState<string>("");

  const handleChangeRadio = (value: unknown) => {
    setSelectedRadio(String(value));
  };

  const notifySuccess = () =>
    toast.success("Tipo de atendimento atualizado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível atualizar o tipo de atendimento. Tente novamente mais tarde."
    );
  const initialValues: Values = {
    name: attendTypesDetails?.name || "",
    sla: attendTypesDetails?.sla || 0,
    workflow: attendTypesDetails?.workflow || 1,
    active:
      attendTypesDetails?.active !== undefined
        ? attendTypesDetails?.active
        : false, // Ensure it's a boolean
  };

  const { handleSubmit, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        console.log(values);
      },
    });

  const handleToggle = () => {
    setFieldValue("active", !values.active);
  };
  const sendNewAttendType: ISendNewAttendTypeBody = useMemo(() => {
    return {
      name: values.name,
      sla: values.sla,
      sla_type: String(selectedRadio),
      workflow: values.workflow,
      active: values.active,
    };
  }, [values, selectedRadio]);

  const flowListDrop = useMemo(() => {
    return flowList?.map((flow) => ({
      label: flow.name,
      id: String(flow.id)
    }))
  }, [flowList])

  const fetchAttendTypes = useCallback(async () => {
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAttendTypes]);

  const fetchSendNewAttendType = async (payload: ISendNewAttendTypeBody) => {
    setLoading(true);
    try {
      if (attendTypesDetails) {
        await patchAttendTypeAPI(payload, attendTypesDetails?.id);
        setOpenEditAttendTypeModal(false);

        await fetchAttendTypes();

        setLoading(false);
        notifySuccess();
      }
    } catch (error) {
      console.error("Erro ao excluir", error);
      setLoading(false);
      notifyError();
    }
  };

  useEffect(() => {
    if (attendTypesDetails) {
      setFieldValue("name", attendTypesDetails?.name);
      setFieldValue("active", attendTypesDetails?.active);
      setFieldValue("sla", attendTypesDetails?.sla);
      setSelectedRadio(attendTypesDetails.sla_type);
    }
  }, [attendTypesDetails, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        onClose={onClose}
        width="700px"
        label={`Editar tipo de atendimento - ${attendTypesDetails?.name}`}
        open={open}

        buttons={
          [

            {
              label: "Cancelar",
              onClick: () => {
                onClose()
              },

            },
            {
              label: "Concluir",
              kind: "primary",
              onClick: () => {
                fetchSendNewAttendType(sendNewAttendType)
              },
              // disabled:
              //   values.name === "" ||
              //   Number(values.sla) < 0 ||
              //   String(values.sla) === "" ||
              //   Number(values.workflow) === 0
            }
          ]
        }

      >
        <div className="w-[95%] mx-auto">
          <TextInput
            id="name"
            name="name"
            labelText="Nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          <Margin mb={24} />
          <Label text="Fluxo De Status" />
          <Dropdown
            id="workflow"
            items={flowListDrop || []}
            onChange={(event) => setFieldValue("workflow", Number(event.selectedItem?.id))}
            label="Selecione o Fluxo De Status"
          />
          <Margin mb={20} />
          <Label text="Tipo do SLA" />
          <RadioButtonGroup
            name="radio-group"
            valueSelected={selectedRadio}
            onChange={handleChangeRadio}
          >
            <RadioButton
              labelText="Dia(s)"
              value="day"
              id="edit-1"
              style={{ display: "block" }}
            />
            <RadioButton
              labelText="Hora(s)"
              value="hour"
              id="edit-2"
              style={{ display: "block" }}
            />
            <RadioButton
              labelText="Minuto(s)"
              value="minute"
              id="edit-3"
              style={{ display: "block" }}
            />
          </RadioButtonGroup>
          <Margin mb={10} />
          <TextInput
            type="number"
            id="sla"
            name="sla"
            labelText={`SLA (${selectedRadio === "day" ? "Dias" : selectedRadio === "hour" ? "Horas" : "Minutos"})`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sla}
          />
          <Margin mb={24} />
          <Toggle
            id="active"
            labelText="Status"
            onToggle={handleToggle}
            toggled={values.active}
            labelA="Inativo"
            labelB="Ativo"
          />

          <Margin mb={24} />
        </div>
      </Modal>
    </form>
  );
};

export default EditAttendTypeModal;
