import AlertIcon from "../../../assets/alert.png";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
// import { useFormik } from "formik";
import {
  getBeneficiariesAPI,
  getBeneficiariesDefaultBankAccount
} from "~/services/api//beneficiaries";
import useBeneficiary from "~/hooks/useBeneficiary";
import { formatCPFCNPJ, onlyNumbers } from "~/services/utils";
import DeleteBeneficiaryModal from "./DeleteBeneficiariesModal";
//  import FilterModal from "./FilterModal";
import ApproveBeneficiaryModal from "./ApproveBeneficiaryModal";
import ReproveBeneficiaryModal from "./ReproveModalBeneficiary";
import Badge from "~/components/Badge";
import ChangeStatusBeneficiaryModal from "./ChangeStatusBeneficiaryModal";
import { useFormik } from "formik";
import {
  Breadcrumb,
  Tab,
  TableBasic,
  TabPane,
  Tag,
  Tooltip,
  Button,
  Dropdown,
  InputFluid
} from "prosperita-dumbo-react";
import { IBeneficiary, IFund } from "~/types";
// import { permissions } from "~/services/permissions";
import viewIcon from "~/assets/viewIcon.png"
import trashIcon from "~/assets/trashIcon.png"
import editIcon from "~/assets/editicon.png"
import useUser from "~/hooks/useUser";
import useNotification from "~/hooks/useToast";
import renewIcon from "~/assets/Renew.png"
import filterIcon from "~/assets/Filter.png"
import closeIcon from "~/assets/close.png"
import Padding from "~/components/Padding";
import useFund from "~/hooks/useFund";
import { getFundsAPI } from "~/services/api/fund";
import { Empty } from "./Empty";
import { AccessRestricted } from "../AccessRestricted";

const renderStatus: { [key: string]: React.ReactNode } = {
  inactive: <Badge label="Inativo" color="#e3e3e3" textColor="#696868" />,

  waiting_approval: (
    <Badge color="#ffe9c9" textColor="#b46c00" label="Aguardando aprovação" />
  ),
  approved: <Badge color="#CCEFDF" textColor="#00B360" label="Ativo" />,

  reproved: <Badge label="Reprovado" textColor="#ff0000" color="#f9c6c6" />,

};

const statusTranslate: { [key: string]: string } = {
  inactive: "Inativo",
  waiting_approval: "Aguardando Aprovação",
  approved: "Ativo",
  reproved: "Reprovado",
};

const Beneficiaries = () => {

  const navigate = useNavigate();

  const { userProfile } = useUser();

  const {
    Beneficiaries,
    setBeneficiaries,
    openModalDeleteBeneficiary,
    setOpenModalDeleteBeneficiary,
    setBeneficiaryDetails,
    openApproveBeneficiaryModal,
    setOpenApproveBeneficiaryModal,
    openReproveBeneficiaryModal,
    setOpenReproveBeneficiaryModal,
    setEditStatusBeneficiaryModal,
    editStatusBeneficiaryModal,
    setAccountBeneficiary,
    accountBeneficiary,
    setMainTab,
    mainTab
  } = useBeneficiary();

  const [currentPage] = useState(1);

  const { setFunds, funds } = useFund();

  const [itemsPerPage] = useState(20);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { setNotification, closeNotification } = useNotification()

  const { handleChange, values } = useFormik({
    initialValues: { search: "" },
    onSubmit: () => { }
  })

  const { setLoading } = useLoading();

  const paginatedBeneficiaries = useMemo(() => {

    const lowerSearchTerm = values.search.toLowerCase();

    const searchIsNumeric = onlyNumbers(values.search).length > 0;

    const filteredBeneficiaries = Beneficiaries.filter(

      (beneficiary) => beneficiary?.status !== "waiting_approval"
    ).filter((beneficiary) => {
      const beneficiaryName = beneficiary.name?.toLowerCase() || "";

      const beneficiaryDocument =
        onlyNumbers(beneficiary.document_number) || "";

      const beneficiaryStatus =
        statusTranslate[String(beneficiary?.status)]?.toLowerCase() ||
        "";

      if (lowerSearchTerm === "ativo") {
        return beneficiary?.status === "approved";
      }

      if (searchIsNumeric) {
        return beneficiaryDocument.includes(onlyNumbers(values.search));
      }

      return (
        beneficiaryName.includes(lowerSearchTerm) ||
        beneficiaryStatus.includes(lowerSearchTerm)
      );
    });

    const hasPending = (beneficiary: IBeneficiary) =>
      beneficiary.bank_account?.some(
        (account) => account.status === "waiting_approval"
      ) || beneficiary.raw_data?.pending_funds_approvations;

    const sortedBeneficiaries = filteredBeneficiaries.sort((a, b) => {
      const aHasPending = hasPending(a);
      const bHasPending = hasPending(b);

      if (aHasPending && !bHasPending) return -1;
      if (!aHasPending && bHasPending) return 1;

      return (a.name || "").localeCompare(b.name || "", "pt-BR", {
        sensitivity: "base",
      });
    });

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedBeneficiaries.slice(start, end);
  }, [currentPage, itemsPerPage, Beneficiaries, values]);

  const [
    currentPageBeneficiariesInactive,
  ] = useState(1);
  const [
    itemsPerPageBeneficiariesInactive,
  ] = useState(20);

  const paginatedBeneficiariesInactive = useMemo(() => {
    const start =
      (currentPageBeneficiariesInactive - 1) *
      itemsPerPageBeneficiariesInactive;
    const end = start + itemsPerPageBeneficiariesInactive;

    const filteredBeneficiaries = Beneficiaries.filter(
      (beneficiary) => beneficiary?.status === "waiting_approval"
    );

    return filteredBeneficiaries?.slice(start, end);
  }, [
    currentPageBeneficiariesInactive,
    itemsPerPageBeneficiariesInactive,
    Beneficiaries,
  ]);

  const fetchBeneficiary = useCallback(async () => {

    setLoading(true);

    try {
      const { data } = await getBeneficiariesAPI();

      setBeneficiaries(data.results.reverse());

      setLoading(false);

    } catch (err: unknown) {

      setLoading(false);
    }
  }, []);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.log(err);
    }
  }, [setFunds]);

  const fetchBankAccountBeneficiary = useCallback(async () => {
    try {
      const { data } = await getBeneficiariesDefaultBankAccount();

      setAccountBeneficiary(data.results.reverse());

    } catch (err) {
      console.error("Erro ao buscar contas bancárias:", err);
    }
  }, []);

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => fund.raw_data.active === true)
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const allDataTableRows = useMemo(() => {
    return paginatedBeneficiaries.map((beneficiary) => ({
      "Nome/razão social": beneficiary.name,
      "CPF/CNPJ": formatCPFCNPJ(beneficiary.document_number),
      "Status":
        <>
          <div className="relative w-[80px]">
            <div
              style={{ position: "absolute", left: "-30px" }}
            >
              {(accountBeneficiary?.some(
                (account) =>
                  beneficiary.id === account.beneficiary && account.status === "waiting_approval"
              ) ||
                beneficiary.raw_data
                  ?.pending_funds_approvations) && (
                  <Tooltip
                    align="left"
                    size="medium"
                    label="Aprovações pendentes"
                  >
                    {" "}
                    <img
                      style={{
                        width: "22px",
                      }}
                      src={AlertIcon}
                      alt=""
                    />{" "}
                  </Tooltip>
                )}
            </div>
            <div style={{ minWidth: "80px" }}>
              {beneficiary?.status === "reproved" ?
                renderStatus[beneficiary?.status] : beneficiary?.raw_data?.status && renderStatus[beneficiary?.raw_data?.status]
              }
            </div>
          </div>
        </>,
      "editar":
        <div className="flex gap-[80px] m-0 p-0">
          <div className="w-[30px]">
            <Button
              size="small"
              noPadding="2px"
              kind="ghost"
              icon={editIcon}
              onClick={(e) => {
                if (beneficiary?.status === "reproved") {
                  setNotification({
                    status: "error",
                    message: "Não foi possível editar o beneficiário, pois ele foi reprovado. Por favor, contate o administrador do sistema para mais informações.",
                    title: "Beneficiário Reprovado",
                    actived: true,
                    zindex: "20",
                    onClose: () => closeNotification()
                  });
                } else {
                  e.stopPropagation();
                  setBeneficiaryDetails(beneficiary);
                  setEditStatusBeneficiaryModal(true);
                }
              }}
            >
            </Button>
          </div>
          <div className="w-[30px]">
            <Button
              size="small"
              noPadding="2px"
              kind="ghost"
              icon={trashIcon}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalDeleteBeneficiary(true),
                  setBeneficiaryDetails(beneficiary);
              }}
            >
            </Button>
          </div>
          <div className="w-[30px]">
            <Button
              size="small"
              noPadding="2px"
              kind="ghost"
              icon={viewIcon}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${beneficiary.id}`);
              }}
            >
            </Button>
          </div>
        </div>,
    }))
  }, [paginatedBeneficiaries])

  const pendingApprovalData = useMemo(() => {
    return paginatedBeneficiariesInactive.map((beneficiary) => ({
      "Nome/razão social": beneficiary.name,
      "CPF/CNPJ": formatCPFCNPJ(beneficiary.document_number),
      "Status":
        <div className="p-[4px] w-[200px]">
          <Tag position="center" type="cyan">
            <div className="p-[5px] w-[160px]">
              {statusTranslate[beneficiary.status]}
            </div>
          </Tag>
        </div>,
      aprovar:
        <div className="flex gap-[30px]">
          <div style={{ width: userProfile?.beneficiary_approver ? "72px" : "0px" }} className="m-0 p-0">
            {userProfile?.beneficiary_approver &&
              <Button
                noPadding="6px"
                size="small"
                label={"Aprovar"}
                kind="tertiary"
                onClick={(e) => {
                  e.stopPropagation();
                  setBeneficiaryDetails(beneficiary);
                  setOpenApproveBeneficiaryModal(true);
                }}
              >
              </Button>
            }
          </div>
          <div style={{ width: userProfile?.beneficiary_approver ? "78px" : "0px" }}
            className="m-0 p-0 w-[78px]">
            {userProfile?.beneficiary_approver &&
              <Button
                noPadding="6px"
                size="small"
                label={"Reprovar"}
                onClick={(e) => {
                  e.stopPropagation();
                  setBeneficiaryDetails(beneficiary);
                  setOpenReproveBeneficiaryModal(true);
                }}
                kind="dangerPrimary"
              >
              </Button>}
          </div>
        </div>
      ,
      "view":
        <>
          <div className="w-[30px]">
            <Button
              kind="ghost"
              size="small"
              noPadding="2px"
              icon={viewIcon}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${beneficiary.id}`);
              }}
            >
            </Button>
          </div>
        </>
    }))
  }, [paginatedBeneficiariesInactive])


  useEffect(() => {
    fetchBeneficiary();
    fetchBankAccountBeneficiary();
    fetchFunds()
  }, []);


  return (
    <div>
      <div className="w-full mx-auto">
        <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
          <div className="flex flex-col">
            <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
              <Breadcrumb
                crumbsOptions={[
                  { crumb: "Configurações", onClick: () => navigate("/app") },
                  { crumb: "Beneficiários" },
                ]}
                endHiddenIndex={5}
                startHiddenIndex={5}
              />
            </div>
            <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
              Beneficiários
            </h2>
          </div>
        </div>
      </div>
      <Margin mt={64} />
      {userProfile?.type.includes("tool_manager") || userProfile?.type.includes("manager") ?
        <>
          {Beneficiaries.length > 0 ?
            <div className="w-[96%] mx-auto">
              <Tab width="100%">
                <TabPane
                  width={"100px"}
                  isActive={mainTab === "all"}
                  label={"Todos"}
                  position="center"
                  onClick={() => setMainTab("all")}
                  bordercolor="rgb(10, 74, 135)"
                >
                  <div className="flex flex-col w-[100%] m-0 p-0">
                    <div className="mt-[30px]"></div>
                    {isFilterOpen && (
                      <div
                        style={{ borderRadius: "8px 8px 0px 0" }}
                        className="bg-[#f4f4f4] w-[100%] mx-auto m-0 p-0"
                      >
                        <div className="d-flex justify-between py-3 w-[97%] mx-auto">
                          <h3 className="m-0 p-0">Filtros</h3>
                          <div className="flex pt-1 w-[300px] justify-around box-border ">
                            <div className="flex w-[100%]">
                              <Dropdown
                                kind="default"
                                options={[
                                  {
                                    label: "Ultimos 6 Meses",
                                    value: "180",
                                  },
                                  {
                                    label: "Ultimos 3 Meses",
                                    value: "90",
                                  },
                                  {
                                    label: "Ultimos 1 Mês",
                                    value: "30",
                                  },
                                ]}
                                size="large"
                                name="period"
                                onChange={handleChange}
                                placeholder="Ultimos 6 Meses"
                                borderNone
                                noRadius
                              />
                            </div>
                            <div className="w-[10%] box-border align-end">
                              <Button
                                kind="ghost"
                                size="small"
                                borderNone
                                icon={closeIcon}
                                width="100%"
                                onClick={() => setIsFilterOpen(false)}
                              />
                            </div>
                          </div>
                        </div>
                        <Margin mt={20} />
                        <div
                          style={{ borderBottom: "1px solid #8D8D8D" }}
                          className="flex m-0 p-0 align-center gap-[10px] mx-auto w-[97%]"
                        >
                          <Dropdown
                            kind="fluid"
                            size="large"
                            label="Nome"
                            message=""
                            type="text"
                            name="name"
                            options={fundsOptions}
                            onChange={handleChange}
                            placeholder="Selecione o nome do fundo"
                            noRadius
                            borderNone
                          />
                          <InputFluid
                            label="Nome/Razão Social"
                            message=""
                            type="text"
                            onChange={handleChange}
                            name="document_number"
                            placeholder="DIgite o Nome/Razão Social do befeficiário"
                            noRadius
                            borderNone
                          />
                          <InputFluid
                            label="CPF/CNPJ"
                            message=""
                            type="text"
                            onChange={handleChange}
                            name="document_number"
                            placeholder="DIgite o CPF/CNPJ do befeficiário"
                            noRadius
                            borderNone
                          />
                        </div>
                        <Margin mt={30} />
                        <div className="flex justify-between w-[97%] mx-auto items-center">
                          <div>
                            <h4 className="text-[18px] mb-[20px]">Status</h4>
                            <div className="m-0 p-0 flex gap-[10px]">
                              <Tag position="center" type="border" sizeFont="13" width="60px">
                                <div className="p-[6px]" >Todos</div>
                              </Tag>
                              <Tag position="center" type="gray" sizeFont="13" width="60px">
                                <div className="p-[6px]"> Ativo</div>
                              </Tag>
                              <Tag position="center" type="gray" sizeFont="13" width="60px">
                                <div className="p-[6px]"> Inativo</div>
                              </Tag>
                            </div>
                          </div>
                          <Button
                            label="Aplicar filtros"
                            kind="tertiary"
                            size="large"
                            icon={filterIcon}
                            onClick={() => fetchBeneficiary()}
                          />
                        </div>
                        <Padding pb={30} />
                      </div>
                    )}
                    <div className="my-[0px] w-[100%]">
                      <TableBasic
                        sizeSearch="small"
                        search
                        buttons={[
                          {
                            label: "",
                            onClick: () => fetchBeneficiary(),
                            kind: "ghost",
                            position: "right",
                            size: "small",
                            width: "36px",
                            icon: renewIcon,
                            dimension: "38"
                          },
                          {
                            label: "",
                            onClick: () => setIsFilterOpen((prev) => !prev),
                            kind: "ghost",
                            position: "right",
                            size: "small",
                            width: "36px",
                            icon: filterIcon,
                            dimension: "36"
                          },
                          {
                            label: "Adicionar  beneficiário",
                            onClick: () => navigate("/app/beneficiaries/register"),
                            kind: "primary",
                            position: "right",
                            size: "small",
                            dimension: "36"
                          }

                        ]}
                        columnTitles={["Nome/razão social", "CPF/CNPJ", "Status", "",]}
                        rowData={allDataTableRows}
                        pageSizes={[5, 10]}
                      />
                      <div className="mt-[50px]"></div>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  isActive={mainTab === "waiting_aprovall"}
                  label={`Aguardando aprovação (${pendingApprovalData.length})`}
                  onClick={() => setMainTab("waiting_aprovall")}
                  bordercolor="rgb(10, 74, 135)"
                >
                  <div className="mt-[30px] w-[100%] mb-[30px]">
                    <TableBasic
                      search
                      buttons={[
                        {
                          label: "",
                          onClick: () => fetchBeneficiary(),
                          kind: "ghost",
                          position: "right",
                          size: "small",
                          width: "36px",
                          icon: renewIcon,
                          dimension: "38"
                        },
                      ]}
                      columnTitles={
                        ["Nome/razão social", "CPF/CNPJ", "Status", "", ""]
                      }
                      rowData={pendingApprovalData}
                      pageSizes={[5, 10]}
                    />
                    <div className="mt-[50px]"></div>
                  </div>
                </TabPane>
              </Tab >
            </div> :
            <Empty openModal={() => navigate("/app/beneficiaries/register")} />
          }
        </> :
        <AccessRestricted text="Você não tem permissão para ver o painel de beneficiários, contate o administrador do sistema para mais informações." />
      }
      <Margin mt={34} />
      <DeleteBeneficiaryModal
        open={openModalDeleteBeneficiary}
        onClose={() => setOpenModalDeleteBeneficiary(false)}
      />
      <ApproveBeneficiaryModal
        open={openApproveBeneficiaryModal}
        onClose={() => setOpenApproveBeneficiaryModal(false)}
      />
      <ReproveBeneficiaryModal
        open={openReproveBeneficiaryModal}
        onClose={() => setOpenReproveBeneficiaryModal(false)}
      />
      <ChangeStatusBeneficiaryModal
        open={editStatusBeneficiaryModal}
        onClose={() => setEditStatusBeneficiaryModal(false)}
      />
    </div>
  );
};

export default Beneficiaries;
