import { Loading, Modal, Search } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IProfiles } from "~/types";

import {
  getAttendAreaProfilesAPI,
  IAssociateAttendAreaProfile,
  postAttendAreaProfileAPI,
} from "~/services/api/attendAreaProfile";
import useProfile from "~/hooks/useProfile";
import { getProfilesForAttendAPI } from "~/services/api/profileForAttend";
import styled from "styled-components";
import { formatCPFCNPJ, getFirstLetters } from "~/services/utils";
import { toast } from "react-toastify";

const notifySuccess = () => toast.success("Usuário atribuído com sucesso!");
const notifyError = () =>
  toast.error(
    "Não foi possível atribuir o usuário. Tente novamente mais tarde."
  );

const ContainerProfiles = styled.div`
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #e9edff;
  }
`;

type Values = {
  profile: string;
  type_profile: string;
  search: string;
};

const initialValues: Values = {
  profile: "",
  type_profile: "internal",
  search: "",
};

const validationSchema = Yup.object({
  profile: Yup.string().required("Usuário é obrigatório"),
  type_profile: Yup.string().required("Tipo de usuário é obrigatório"),
});

const AssociateAttendAreaProfileModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    areaDetails,
    setOpenAssociateAttendAreaProfileModal,
    setAttendAreaProfiles,
  } = useArea();
  const { setProfiles, profiles } = useProfile();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(true);
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);

  const { setFieldValue, values, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI(Number(areaDetails?.id));
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles, areaDetails?.id]);

  const fetchProfiles = useCallback(async () => {
    setLoadingSearch(true);
    setSelectedIndex(null);
    try {
      const { data } = await getProfilesForAttendAPI(values.search);

      setProfiles(data.results);
      setLoadingSearch(false);
    } catch (err: unknown) {
      setLoadingSearch(false);
      console.error(err);
    }
  }, [values.search, setProfiles]);

  const profilesFiltered = useMemo(() => {
    return profiles?.filter(
      (profile: IProfiles) =>
        (profile?.type?.includes("area_manager") ||
          profile?.type?.includes("internal")) && profile.raw_data?.status === "active"
    );
  }, [profiles]);

  const payloadAssociateNewAttendAreaProfile = useMemo(() => {
    return {
      profile: Number(values.profile),
      area: Number(areaDetails?.id),
    };
  }, [values.profile, areaDetails]);

  const fetchAssociateAttendAreaProfile = useCallback(
    async (payload: IAssociateAttendAreaProfile) => {
      setLoading(true);

      try {
        await postAttendAreaProfileAPI(payload);
        await fetchAttendAreaProfiles();
        setOpenAssociateAttendAreaProfileModal(false);
        setLoading(false);
        setFieldValue("search", "");
        setSelectedIndex(null);

        notifySuccess();
      } catch (err: unknown) {
        console.error(err);

        setLoading(false);
        notifyError();
      }
    },
    [
      setLoading,
      fetchAttendAreaProfiles,
      setFieldValue,
      setOpenAssociateAttendAreaProfileModal,
    ]
  );

  useEffect(() => {
    fetchProfiles();
  }, [values.search, fetchProfiles]);

  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Atribuir usuário"
      size="sm"
      open={open}
      onRequestClose={() => {
        onClose();
        setFieldValue("search", "");
        setSelectedIndex(null);
      }}
      primaryButtonDisabled={selectedIndex === null}
      primaryButtonText="Concluir"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchAssociateAttendAreaProfile(payloadAssociateNewAttendAreaProfile);
      }}
    >
      <Margin mt={20} />

      <h4>Buscar usuário</h4>
      <Margin mt={10} />
      <div style={{ zIndex: 1 }}>
        {" "}
        <Search
          id="search"
          labelText="Pesquisar"
          placeholder="Digite o CPF ou o nome do usuário"
          value={values.search}
          onChange={handleChange}
        />
        <Margin mb={30} />
        {profilesFiltered.length > 0 && !loadingSearch ? (
          <div style={{ height: "400px", overflow: "auto" }}>
            <p>Clique para selecionar um usuário</p>
            <Margin mb={10} />
            {profilesFiltered?.map((profile: IProfiles, index: number) => {
              return (
                <ContainerProfiles
                  onClick={() => {
                    if (selectedIndex === index) {
                      setSelectedIndex(null);
                    } else {
                      setSelectedIndex(index);
                      setFieldValue("profile", profile.id);
                    }
                  }}
                  key={String(index)}
                  style={{
                    background: selectedIndex === index ? "#6ea0fc" : "",
                  }}
                >
                  <Margin ml={5} />
                  <div
                    style={{
                      background:
                        selectedIndex === index ? "#85b0ff" : "#b8b8b8",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h4
                      style={{
                        color: selectedIndex === index ? "#0f62fe" : "",
                      }}
                    >
                      {getFirstLetters(profile.name)}
                    </h4>
                  </div>
                  <Margin ml={15} />
                  <div>
                    <Margin mb={15} />
                    <p style={{ fontWeight: "bold" }}>{profile.name}</p>
                    <Margin mb={-15} />
                    <p>{formatCPFCNPJ(profile.document_number)}</p>
                    <Margin mb={-15} />
                    <p style={{ fontWeight: "normal" }}>
                      {profile.type.includes("internal") &&
                        !profile.type.includes("area_manager") &&
                        "Atendente"}
                      {!profile.type.includes("internal") &&
                        profile.type.includes("area_manager") &&
                        "Gestor de área"}
                      {profile.type.includes("internal") &&
                        profile.type.includes("area_manager") &&
                        "Gestor de área | Atendente "}
                    </p>
                  </div>
                </ContainerProfiles>
              );
            })}
          </div>
        ) : loadingSearch ? (
          <div
            style={{
              height: "400px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading withOverlay={false} />
          </div>
        ) : !loadingSearch && values.search !== "" ? (
          <div
            style={{
              height: "400px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>Nenhum resultado encontrado</h4>
          </div>
        ) : (
          <div
            style={{
              height: "400px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        )}
      </div>
    </Modal>
  );
};

export default AssociateAttendAreaProfileModal;
