import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { formatCPFCNPJ } from "~/services/utils";
import useCompany from "~/hooks/useCompany";
import { deleteCompanyAPI, getCompanysAPI } from "~/services/api/companys";

const DeleteCompanyModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { companyDetails, setOpenDeleteCompanyModal, setCompanys } =
    useCompany();

  const notifySuccess = () =>
    toast.success("Empresa gestora removida com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover a empresa gestora. Tente novamente mais tarde."
    );

  const fetchCompanys = useCallback(async () => {
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanys]);

  const fetchDeleteCompany = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        await deleteCompanyAPI(String(id));
        await fetchCompanys();
        setOpenDeleteCompanyModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading]
  );

  useEffect(() => {
    fetchCompanys();
  }, []);



  return (
    <Modal
      style={{ zIndex: 6 }}
      modalHeading="Remover Empresa Gestora"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        companyDetails?.id && fetchDeleteCompany(companyDetails?.id);
      }}
    >
      {/* {true && (
        <InlineNotification
          title="Erro!"
          // subtitle={error}
          subtitle="Não foi possivel atribuir o tipo de atendimento"
          hideCloseButton
          kind="error"
          lowContrast
        />
      )} */}
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente remover a empresa abaixo ?
      </h3>
      <Margin mb={40} />
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Razão Social: </span>{" "}
        {companyDetails?.name}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Nome Fantasia: </span>{" "}
        {companyDetails?.raw_data?.brand_name}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>CNPJ: </span>{" "}
        {companyDetails?.raw_data?.document_number &&
          formatCPFCNPJ(companyDetails?.raw_data?.document_number)}
      </h4>
      <h4>
        {" "}
        <span style={{ fontWeight: "bold" }}>Tipo: </span>{" "}
        {companyDetails?.raw_data?.company_type?.name}
      </h4>

      <Margin mb={100} />
    </Modal>
  );
};

export default DeleteCompanyModal;
