import ApiService from "../api";

export type Flow = {
    active: boolean;
    created_on: Date;
    deleted: string | null;
    deleted_by_cascade: boolean;
    description: string;
    id: number;
    name: string;
    updated_on: Date;
};

export type WorkflowStatus = {
    active: boolean;
    color: string;
    created_on: string;
    deleted: null | string;
    deleted_by_cascade: boolean;
    id: number;
    name: string;
    order: number;
    slug: null | string;
    updated_on: string;
    workflow: number;
};


export const getWorkflowStatus = () => {
    return ApiService.HttpGet<{
        results: Flow[]
    }>({
        route: "workflow/workflow/",
        token: true,
    });
};

export const getWorkflowFiltered = (id: number) => {
    return ApiService.HttpGet<
        Flow
    >({
        route: `workflow/workflow/${id}`,
        token: true,
    });
};

export const getStatusFiltered = (id: number) => {
    return ApiService.HttpGet<{
        results: WorkflowStatus[]
    }>({
        route: `workflow/workflow-status/?workflow=${id}`,
        token: true,
    });
};


export const postWorkflow = (
    body: {
        name: string,
        description: string,
        active: boolean
    }) => {
    return ApiService.HttpPost<{
        id: string,
        deleted: string,
        deleted_by_cascade: boolean,
        name: string,
        description: string,
        active: boolean,
        created_on: string,
        updated_on: string
    }>({
        route: "workflow/workflow/",
        token: true,
        body
    });
};

export const postWorkflowStatus = (
    body: {
        name: string,
        order?: number,
        slug?: string,
        color: string,
        active?: boolean,
        workflow: number

    }) => {
    return ApiService.HttpPost<{
        id: string,
    }>({
        route: "workflow/workflow-status/",
        token: true,
        body
    });
};


export const pacthStatusAPI = (number: number, body: { name: string, color: string, }) => {
    return ApiService.HttpPatch<{
        id: string,
    }>({
        route: `workflow/workflow-status/${number}/`,
        token: true,
        body
    });
};


export const deleteStatusAPI = (number: number) => {
    return ApiService.HttpDelete<{
        id: number,
    }>({
        route: `workflow/workflow-status/${number}/`,
        token: true,
    });
};

export const roleStatusWorkflowAPI = () => {
    return ApiService.HttpGet<{
        id: number,
    }>({
        route: `workflow/workflow-transition-condition/`,
        token: true,
    });
};


export const StatusWorkflowTransitionAPI = () => {
    return ApiService.HttpGet<{
        id: number,
    }>({
        route: `workflow/workflow-transition/`,
        token: true,
    });
};

export const workflowTransitionValidateAPI = (body: {
    workflow: number,
    from_status: number,
    to_status: number
}) => {
    return ApiService.HttpPost<{
        message: string,
        valid: boolean
    }>({
        route: `workflow/workflow-transition/validate/`,
        token: true,
        body
    });
};

