import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Grid,
  Modal,
  SideNav,
  SideNavItems,
  SideNavLink,
  Stack,
  TextArea,
} from "@carbon/react";
import { TrashCan, View } from "@carbon/icons-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useLoading from "~/hooks/useLoading";
import {
  postAttendAPI,
  ISendNewAttendBody,
  getAttendAPI,
} from "~/services/api/attend";
import { IAreaType, ICompanyFund, IFund } from "~/types";
import { getFundsAPI } from "~/services/api/fund";
import useFund from "~/hooks/useFund";
import Select from "react-select";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { permissions } from "~/services/permissions";
// import { postAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";
import { formatCPFCNPJ } from "~/services/utils";

import Label from "~/components/Label";
import { toast } from "react-toastify";
import { postAttendTaskFileAPI } from "~/services/api/attendTaskFile";
import { postAttendLogAPI } from "~/services/api/attendLog";
import { getCompanyFundAPI } from "~/services/api/companyFund";
import useAttend from "~/hooks/useAttend";
import { getAreasAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";
import { Link } from "react-router-dom";

interface PreviewFile extends File {
  preview: string;
}

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;

    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

const ContainerFile = styled.div`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid rgba(38, 0, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
`;

type Values = {
  message: string;
  search: string;
  client: string;
  fund: string;

  area_type: string;
  area: string;
};

const initialValues: Values = {
  message: "",
  search: "",
  client: "",
  fund: "",
  area_type: "",
  area: ""
};

const validationSchema = Yup.object({
  message: Yup.string().required("Descrição é obrigatória"),
  client: Yup.string(),
  fund: Yup.string().required("Fundo é obrigatório"),
  area_type: Yup.string().required("Tipo é obrigatório"),
});

const NewAttend = () => {
  const navigate = useNavigate();

  const { setFunds, funds, fundsCompany, setFundsCompany } = useFund();
  const { userProfile } = useUser();
  const { setAreaTypes, areaTypes } = useAreaType();
  const { setLoading, loading } = useLoading();
  const { setAttends } = useAttend();

  const canCreateAttends = permissions.includes("can_create_attends");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const {
    setAreas,
    areas,
  } = useArea();

  const openModal = (file: PreviewFile) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedFile(null);
    setModalIsOpen(false);
  };

  const notifySuccess = () => toast.success("Atendimento criado com sucesso!");
  const notifyError = () => toast.error("O atendimento não pode ser criado");

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const sendNewAttendPayload: ISendNewAttendBody = {
        message: values.message,
        client: userProfile?.id ?? 0,
        fund: Number(values.fund),
        type: Number(values.area_type),
        status: 1,
        area: Number(values.area)
      };

      fetchSendNewAttend(sendNewAttendPayload);
    },
  });

  const [files, setFiles] = useState<PreviewFile[]>([]);

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as PreviewFile[];
      setFiles((prevFiles) => [...prevFiles, ...previewFiles]);
    },
  });
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const filteredFunds = funds.filter((fund) => fund.raw_data.active === true);

  const fundsOptions = useMemo(() => {
    return filteredFunds
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [filteredFunds]);

  const fetchCompanyFunds = useCallback(async () => {
    try {
      if (userProfile?.company_get?.id) {
        const { data } = await getCompanyFundAPI(
          Number(userProfile?.company_get?.id)
        );
        setFundsCompany(data.results.reverse());
      }
      //   setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [userProfile, setFundsCompany]);

  const companyFundsOptions = useMemo(() => {
    return fundsCompany
      ?.map((fundsCompany: ICompanyFund) => ({
        value: String(fundsCompany.fund.id),
        label: `${fundsCompany?.fund.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fundsCompany?.fund.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [fundsCompany]);


  const filteredAreaTypes = useMemo(
    () =>
      areaTypes.filter((areaType: IAreaType) => {
        return areaType.area.id === Number(values.area);
      }),
    [areaTypes, values.area])

  const areaTypesOptions = useMemo(() => {
    const options = filteredAreaTypes
      .filter((item) => {
        return item.area.active && item.type.active;
      })
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const uniqueOptions = Array.from(
      new Map(options.map((option) => [option.value, option])).values()
    );
    return uniqueOptions;
  }, [filteredAreaTypes]);

  const areaID = useMemo(() => {
    return areaTypes.filter(
      (areaType) => areaType.type.id === Number(values.area_type)
    )[0]?.area?.id;
  }, [areaTypes, values.area_type]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectFund = (selectedOption: SelectOption | null) => {
    if (selectedOption) {
      setFieldValue("fund", selectedOption.value);
    }
  };

  const handleSelectAreaType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area_type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area_type", "");
    }
  };

  const handleSelectArea = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area", "");
    }
  };

  const areaOptions = useMemo(() => {
    return areas
      ?.map((areas) => ({
        value: String(areas.id),
        label: areas?.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [areas]);


  const sendFile = async (file: PreviewFile, dataId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("sender", String(userProfile?.id || ""));
    formData.append("attend", String(dataId));
    formData.append("description", `${file?.name}`);

    await postAttendTaskFileAPI(formData); // Envia um único arquivo
  };

  const fetchAttends = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getAttendAPI();
      setAttends(data.results.reverse().sort((a, b) => b.id - a.id));
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  const fetchSendNewAttend = async (payload: ISendNewAttendBody) => {
    setLoading(true);
    try {
      const { data } = await postAttendAPI(payload);

      await postAttendLogAPI({
        attend: data.id,
        actual_area: areaID,
      });

      if (data && files.length > 0) {
        const filePromises = files.map((file: PreviewFile) =>
          sendFile(file, String(data.id))
        );

        await Promise.all(filePromises);

        setLoading(false);
        navigate("/app/attends/");
        fetchAttends();
        resetForm();
        notifySuccess();

        return;
      }

      setLoading(false);
      navigate("/app/attends/");
      window.location.reload();
      resetForm();
      notifySuccess();
    } catch (e) {
      console.error(e);
      setLoading(false);
      notifyError();
    }
  };
  const fetchAreas = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setAreas, setLoading]);


  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);


  useEffect(() => {
    fetchCompanyFunds();
    fetchFunds();
    fetchAreas()

    fetchAreaTypes();
  }, [fetchFunds, fetchAreaTypes, fetchCompanyFunds]);


  return (
    <TicketFormContainer>
      {canCreateAttends ? (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Column span={16}>
              <Padding y={24}>
                <Margin mb={30} />
                <Breadcrumb>
                  <BreadcrumbItem onClick={() => navigate("/app/attends/")}>
                    Atendimentos
                  </BreadcrumbItem>
                  <BreadcrumbItem onClick={() => navigate("/app/attends/new")}>
                    Novo atendimento
                  </BreadcrumbItem>
                </Breadcrumb>
              </Padding>
              <h1>Novo atendimento</h1>
              <Margin mb={22} />
              <Grid className="form">
                <Column span={4}>
                  <SideNav
                    style={{ zIndex: 0 }}
                    aria-label="Side navigation"
                    className="form-sidenav"
                  >
                    <SideNavItems>
                      <SideNavLink href="#" isActive>
                        Geral
                      </SideNavLink>
                    </SideNavItems>
                  </SideNav>
                </Column>

                <Column span={12}>
                  <Grid>
                    <Column span={12}>
                      <Padding y={12}></Padding>
                    </Column>
                    <Column span={6}>
                      <h2>Geral</h2>
                      <Margin mb={20} />{" "}
                      <Stack gap={5}>
                        <Margin mb={5} />
                        <Label text="Selecione o fundo" />
                        <Select
                          options={
                            userProfile?.type !== "client" &&
                              userProfile?.type !== "manager"
                              ? fundsOptions
                              : companyFundsOptions
                          }
                          onChange={handleSelectFund}
                          placeholder="Pesquisar ou selecionar fundo..."
                        />
                        <Margin mb={5} />
                        <Label text="Selecione a Area" />
                        <Select
                          isClearable={true}
                          options={areaOptions}
                          onChange={(selectedOption, actionMeta) =>
                            handleSelectArea(selectedOption, actionMeta)
                          }
                          placeholder="Pesquisar ou selecionar Tipo de atendimento..."
                        />
                        <Margin mb={5} />
                        {values.area !== "" &&
                          <>
                            {areaTypesOptions.length === 0 ?
                              <>
                                <Link
                                  to={`/app/areas/${values.area}/`}
                                  style={{
                                    display: "inline-block",
                                    padding: "10px 16px",
                                    backgroundColor: "#ffcc00",
                                    color: "#333",
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    textDecoration: "none",
                                  }}
                                >
                                  ⚠️ A área não possui nenhum Tipo de Atendimento!{" "}
                                  <span style={{ fontWeight: "normal" }}>Clique aqui para ir para a área e cadastrar um tipo de atendimento.</span>
                                </Link>

                              </> :
                              <>
                                <Label text="Selecione o tipo de atendimento" />
                                <Select
                                  isClearable={true}
                                  options={areaTypesOptions}
                                  onChange={(selectedOption, actionMeta) =>
                                    handleSelectAreaType(selectedOption, actionMeta)
                                  }
                                  placeholder="Pesquisar ou selecionar Tipo de atendimento..."
                                />
                              </>
                            }
                          </>
                        }
                        <Margin mb={5} />
                        <Label text="Selecione seus documentos para enviar" />
                        <div
                          {...getRootProps()}
                          style={{
                            border: "2px dashed #cccccc",
                            padding: "20px",
                            textAlign: "center",
                          }}
                        >
                          <input {...getInputProps()} />

                          <p>
                            Arraste e solte alguns arquivos aqui, ou clique para
                            selecionar os arquivos
                          </p>
                        </div>
                        <div>
                          <Margin mb={10} />
                          <div>
                            <Modal
                              open={modalIsOpen}
                              onRequestClose={closeModal}
                              primaryButtonText="Fechar visualização"
                              onRequestSubmit={closeModal}
                            >
                              <>
                                {selectedFile && (
                                  <div>
                                    <h2>{selectedFile.name}</h2>
                                    {selectedFile.type.startsWith("image/") ? (
                                      <img
                                        src={selectedFile.preview}
                                        alt={selectedFile.name}
                                        style={{ width: "100%" }}
                                      />
                                    ) : selectedFile.type ===
                                      "application/pdf" ? (
                                      <iframe
                                        src={selectedFile.preview}
                                        title={`Preview of ${selectedFile.name}`}
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          height: "400px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h4>
                                          Tipo de arquivo não suportado para
                                          visualização.{" "}
                                          <a
                                            href={selectedFile.preview}
                                            download={selectedFile.name}
                                          >
                                            Clique aqui para baixar
                                          </a>
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            </Modal>
                            {files.map((file, index) => (
                              <ContainerFile key={String(index)}>
                                <p>{file.name}</p>
                                <div
                                  className="d-flex"
                                  style={{ display: "flex" }}
                                >
                                  <Button
                                    hasIconOnly
                                    renderIcon={View}
                                    iconDescription="Visualizar"
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => openModal(file)}
                                  />
                                  <Margin ml={10} />
                                  <Button
                                    hasIconOnly
                                    renderIcon={TrashCan}
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => {
                                      handleDelete(file);
                                    }}
                                    iconDescription="Excluir"
                                  />
                                </div>
                              </ContainerFile>
                            ))}
                          </div>
                        </div>
                        <Margin mb={5} />
                        <TextArea
                          rows={9}
                          name="message"
                          labelText={`Mensagem - ${values.message.length}/500 caracteres`}
                          required
                          placeholder="Digite a mensagem"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ resize: "none" }}
                          maxLength={500}
                        />
                      </Stack>
                    </Column>
                    <Column span={12}>
                      <Margin mb={64} />
                      <Grid>
                        <Column span={3}>
                          <Button
                            kind="tertiary"
                            className="action-button"
                            onClick={() => navigate("/app/attends/")}
                          >
                            Cancelar
                          </Button>
                        </Column>
                        <Column span={3}>
                          <Button
                            kind="primary"
                            className="action-button"
                            type="submit"
                            disabled={!isValid || loading}
                          >
                            Enviar
                          </Button>
                        </Column>
                      </Grid>
                    </Column>

                    <Column span={6} />
                  </Grid>

                  <Margin mb={64} />
                </Column>
              </Grid>

              <Margin mb={64} />
            </Column>
          </Grid>
        </form >
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar atendimentos</h4>
        </div>
      )}
    </TicketFormContainer >
  );
};

export default NewAttend;
