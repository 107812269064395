import { InputFluid, Modal } from "prosperita-dumbo-react";
import { ContainedList, ContainedListItem } from "@carbon/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Search } from "carbon-components-react";
import useProfile from "~/hooks/useProfile";
import useLoading from "~/hooks/useLoading";
import { getProfilesAPI } from "~/services/api/profile";
import styled from "styled-components";
import userIcon from "~/assets/User.png";
import { getTypeUserRole, postTypeUserRole } from "~/services/api/forum";
import useNotification from "~/hooks/useToast";
import useForum from "~/hooks/useForum";
import { postNotificationsInvite } from "~/services/api/notifications";
import { useFormik } from "formik";
import { getAttendHistoryAPI } from "~/services/api/attend";
import { postAttendTaskMessageAPI } from "~/services/api/AttendTaskMessage";
import useUser from "~/hooks/useUser";
import useAttend from "~/hooks/useAttend";


type ItypeModal = {
    open: boolean,
    onClose: () => void,
    forum_type?: string | null
}

const CustomSearch = styled(Search)`
  .bx--search-input {
    background-color: #080303;  // Altere a cor de fundo conforme necessário
    color: #333;  // Altere a cor do texto
  }

  .bx--search-close {
    color: #ff6347;  // Altere a cor do ícone de fechar (exemplo: tomate)
  }

  .bx--label {
    color: #0073e6;  // Altere a cor do texto da label
  }

  .bx--search-magnifier {
    fill: #0073e6;  // Altere a cor do ícone de lupa
  }
`;

const StyledContainedListItem = styled(ContainedListItem) <{ selected: boolean }>`
  cursor: pointer;
  border: ${(props) => (props.selected ? "1px solid #0073e6" : "2px solid transparent")};
  border-radius: 4px;
  
  .bx--list-box__menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  img {
    filter: brightness(100%) invert(1) brightness(100%);
    width: 20px;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const ModalInviteParticipant = ({ open, onClose, forum_type }: ItypeModal) => {

    const { setNotification, closeNotification } = useNotification()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const { profiles, setProfiles } = useProfile();
    const { setLoading } = useLoading();
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
    const {
        setParticipantList,
        participantList,
        listForumFiltered,
    } = useForum()

    const {
        setHistory,
    } = useAttend();

    const { userProfile } = useUser();

    const { setFieldValue, values, resetForm } = useFormik({
        initialValues: { textarea: "" },
        onSubmit: () => { }
    })

    const fetchProfiles = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await getProfilesAPI({ mode: "list" });

            setProfiles(data.results.reverse());

            setLoading(false);

        } catch (err: unknown) {
            console.error(err);
            setLoading(false);
        }
    }, [setProfiles, setLoading]);


    const listProfiles = useMemo(() => {
        return profiles?.filter((profile) => (
            profile.raw_data?.status === "active"
        )).map((profile) => (
            {
                label: profile.name,
                value: String(profile.user.id),
            }
        ));
    }, [profiles]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const toggleSelection = (item: string) => {

        setSelectedItems((prev) =>
            prev.includes(item)
                ? prev.filter((selectedItem) => selectedItem !== item)
                : [...prev, item]
        );

        setSelectedEmails((prevEmails) => {
            const email = profiles
                .filter(user => String(user?.user?.id) === item)  // Filtra baseado no item (ID)
                .map(user => user.user.email)[0];  // Pega o primeiro email encontrado

            // Se o email já está nos emails selecionados, remove, caso contrário, adiciona
            return prevEmails.includes(email)
                ? prevEmails.filter((selectedEmail) => selectedEmail !== email)
                : [...prevEmails, email];
        });

        // console.log(uniqueEmails);
        // console.log(selectedItems);

    };

    const fetchParticipantMandatoryForum = useCallback(async () => {

        setLoading(true);

        try {
            const { data } = await getTypeUserRole(Number(forum_type))

            setParticipantList(data.results)

        } catch { }

        finally {

            setLoading(false);
        }

    }, [participantList])

    const fetchHistory = useCallback(async () => {

        try {

            const { data } = await getAttendHistoryAPI(Number(listForumFiltered?.attend));

            setHistory(data);

        } catch (err: unknown) {

            console.error(err);
        }

    }, [listForumFiltered]);

    const fetchSendUserRole = useCallback(async () => {

        setLoading(true);

        try {

            const promises = selectedItems?.map((user) => (

                postTypeUserRole({
                    forum_type: Number(forum_type),
                    type_vote: "nao_vota",
                    user: Number(user),
                    user_status: "convidado",
                })
            ));

            await Promise.all(promises);

            if (listForumFiltered) {

                const uniqueUsers = new Set();

                const uploadsInvites = selectedItems?.map((list) => {

                    const userId = list


                    if (!uniqueUsers.has(userId)) {

                        uniqueUsers.add(userId);

                        const inviteData = {
                            attend: Number(listForumFiltered?.attend),
                            body: `Você foi convidado para participar deste fórum. ${values?.textarea}`,
                            subject: `Convite para participar do fórum: ${listForumFiltered?.name} #${listForumFiltered?.id}`,
                            notification_type: "App",
                            type: "user_invite",
                            user: Number(userId),
                        };

                        return postNotificationsInvite(inviteData);
                    }

                    return null;

                }).filter(Boolean);

                if (uploadsInvites && uploadsInvites.length > 0) {
                    await Promise.all(uploadsInvites);
                }

                await postAttendTaskMessageAPI({
                    sender: "client",
                    message: `Convidou ${selectedItems}`,
                    subject: String(userProfile?.id),
                    attend: listForumFiltered?.attend,
                })

                await fetchHistory()

            }

            if (listForumFiltered) {

                const uniqueUsers = new Set();

                const uploadsInvites = selectedEmails?.map((list) => {

                    const userId = list;

                    selectedItems?.map((item) => {

                        if (!uniqueUsers.has(userId)) {

                            uniqueUsers.add(userId);

                            const inviteData = {
                                attend: Number(listForumFiltered?.attend),
                                body: `${values?.textarea}`,
                                subject: `Convite para participar do fórum: ${listForumFiltered?.name} #${listForumFiltered?.id}`,
                                notification_type: "Email",
                                type: "user_invite",
                                recipient: userId,
                                user: Number(item),
                            };

                            return postNotificationsInvite(inviteData);
                        }

                        return null;
                    });

                }).filter(Boolean);

                if (uploadsInvites && uploadsInvites.length > 0) {
                    await Promise.all(uploadsInvites);
                }
            }

            if (listForumFiltered) {
                setNotification({
                    status: "success",
                    message: "Seu convite foi enviado com sucesso.",
                    title: "Enviado com sucesso",
                    actived: true,
                    onClose: () => closeNotification()
                });


            } else {
                setNotification({
                    status: "success",
                    message: "Usuário adicionado com sucesso. Seu convite será enviado logo após a criação do fórum.",
                    title: "Usuário adicionado com sucesso",
                    actived: true,
                    onClose: () => closeNotification()
                });
            }


        } catch (error) {

            setNotification({
                status: "error",
                message: "Não foi possível enviar seu convite, tente novamente mais tarde ou contate o administrador do sistema.",
                title: "Falha ao enviar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            fetchParticipantMandatoryForum()
            setLoading(false);
            onClose && onClose()
            resetForm()
        }

    }, [forum_type, selectedItems, values, listForumFiltered, userProfile]);


    useEffect(() => {

        const filteredResults = listProfiles?.filter((listItem) =>
            listItem.label.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setSearchResults(filteredResults || []);

    }, [searchTerm, listProfiles]);



    const filteredResultsProfiles = searchResults.filter((users) =>
        !participantList?.some((items) => items.user.id === Number(users.value))
    );



    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    return (
        <Modal
            radius
            label={<h3 className="text-[23px] mt-2">Convidar participante</h3>}
            open={open}
            height={listForumFiltered ? "560px" : "400px"}
            width="45%"
            onClose={() => {
                onClose && onClose();
                resetForm()
            }}
            background="#ffffff"
            buttons={[
                {
                    label: "Cancel",
                    kind: "secondary",
                    onClick: () => {
                        onClose && onClose();
                        resetForm()
                    },
                },
                {
                    label: "Convidar participante",
                    kind: "primary",
                    onClick: () => fetchSendUserRole(),
                },
            ]}
        >
            <p className="w-[97%] mx-auto"></p>
            <ContainedList
                label="List title"
                kind="on-page"
                action={
                    <CustomSearch
                        placeholder="Digite para buscar um contato"
                        labelText="Search"
                        value={searchTerm}
                        onChange={handleChange}
                        closeButtonLabelText="Clear search input"
                        size="lg"
                        autoComplete="off"
                    />
                }
            >
                {filteredResultsProfiles?.slice(0, 8).map((listItem, key) => (
                    <StyledContainedListItem
                        key={key}
                        selected={selectedItems?.includes(listItem.value)}
                        onClick={() => {
                            toggleSelection(listItem.value)
                        }}
                    >
                        <div className="flex items-center justify-start gap-[10px]">
                            <img src={userIcon} />
                            <p>{listItem.label}</p>
                        </div>
                    </StyledContainedListItem>
                ))}
            </ContainedList>
            {listForumFiltered
                &&
                <div style={{
                    position: "relative",
                    margin: "10px"
                }}>
                    <div className="w-[99%] mx-auto mt-[5px]">
                        <InputFluid
                            type="textarea"
                            label="Mensagem"
                            noRadius
                            autoComplete="off"
                            rows={8}
                            maxLength={500}
                            value={values.textarea}
                            onChange={(event) => setFieldValue("textarea", event.target.value)}
                        />
                    </div>
                    <div className="absolute top-0 right-2 text-right text-[13px] text-gray-600 mt-1">
                        {values.textarea.length}/{500}
                    </div>
                </div>
            }
        </Modal>
    );
};
