
import { Margin, Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import Badge from "~/components/Badge";
import useLoading from "~/hooks/useLoading";
import useWorkflow from "~/hooks/useWorkflow";
import { deleteStatusAPI, getStatusFiltered, getWorkflowFiltered } from "~/services/api/workflow";


export const ModalRemoveStatus = ({
    onClose,
    open,
    workflow
}: {
    open: boolean;
    onClose: () => void;
    workflow: number

}) => {

    const { setLoading } = useLoading();

    const {
        setActiveStatuses,
        setSelectedFlow,
        selectedFlow,
        activeStatuses,
        editStatus,
        setEditStatus,
    } = useWorkflow()


    const fetchStatus = useCallback(async () => {

        try {
            const { data } = await getStatusFiltered(Number(workflow))

            const { data: work } = await getWorkflowFiltered(Number(workflow))

            setSelectedFlow(work)

            setActiveStatuses(data.results)

        } catch {

        }
    }, [workflow, activeStatuses, selectedFlow])


    const fecthdeleteStatus = useCallback(async () => {

        setLoading(true);

        try {

            await deleteStatusAPI(Number(editStatus?.id))
            await fetchStatus()

            onClose()

        } catch (error) {

        } finally {

            setLoading(false);

            setEditStatus(null)
        }

    }, [editStatus?.id])


    return (
        <Modal
            label={
                <div className="m-[0] text-[25px] font-light text-center p-[0] ml-[50px]">
                    Remover esse Status
                </div>
            }
            open={open}
            width="800px"
            height={"250px"}
            radius
            background="#f4f4f4"
            onClose={() => {
                onClose()
                setEditStatus(null)
            }}
            buttons={
                [
                    {
                        label: "Cancelar",
                        kind: "ghost",
                        size: "large",
                        dimension: "80",
                        onClick: () => {
                            onClose && onClose()
                            setEditStatus(null)
                        }
                    },
                    {
                        label: "Remover",
                        kind: "dangerPrimary",
                        dimension: "80",
                        size: "large",
                        disabled: false,
                        onClick: () => { fecthdeleteStatus() }
                    },
                ]
            }
        >
            <Margin top={20} />
            <div className="text-center text-[16px] text-gray-600">
                <p>Tem certeza de que deseja remover este status? Essa ação não poderá ser desfeita.</p>
            </div>
            <div className="w-[100%] mx-auto text-center">
                <div className="w-[100%] mx-auto text-center flex justify-center">
                    <Badge
                        label={editStatus?.data?.label}
                        color={editStatus ? String(editStatus?.style?.background) : "#312828"}
                    />
                </div>
            </div>
        </Modal>
    )
}
