import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { getFundsAPI } from "~/services/api/fund";
import { formatCPFCNPJ } from "~/services/utils";
import useFund from "~/hooks/useFund";
import { IFund } from "~/types";
import Label from "~/components/Label";
import { MultiValue } from "react-select";

import {
  getCompanyFundAPI,
  postCompanyFundAPI,
} from "~/services/api/companyFund";
import useCompany from "~/hooks/useCompany";
import { useParams } from "react-router-dom";
import { InputSelect, Modal } from "prosperita-dumbo-react";

const notifySuccessCompanyFunds = () =>
  toast.success("Fundos associados com sucesso!");
const notifyErrorCompanyFunds = () =>
  toast.error(
    "Não foi possível associar os fundos. Tente novamente mais tarde"
  );

type Values = {
  name: string;
  document_number: string;
  brand_name: string;
  fund: string[];
  master_email: string;
  master_name: string;
  master_document_number: string;
  company_type: {
    id: number;
    name: string;
  };
};

const initialValues: Values = {
  name: "",
  brand_name: "",
  document_number: "",
  fund: [],
  master_email: "",
  master_document_number: "",
  master_name: "",
  company_type: { id: -1, name: "" },
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sort_name: Yup.string().required("Nome abreviado da tarefa é obrigatório"),
  document_number: Yup.string().required("Nome da tarefa é obrigatório"),
  fund: Yup.array().required("Fundo é obrigatório"),
});

const AddFundsInCompany = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setFunds, funds } = useFund();

  const { id } = useParams();

  const [clearInput, setClearInput] = useState<boolean>(false)

  const { setOpenAddFundsCompanyModal, setCompanyFunds } = useCompany();

  const { handleSubmit, resetForm, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
    },
  });

  const resetFormFields = useCallback(() => {
    resetForm(); // Reseta todos os campos do formulário
    setFieldValue("fund", []); // Limpa os fundos selecionados
    setFieldValue("company_type", {});
  }, [resetForm, setFieldValue]);

  type SelectOption = {
    value: string;
    label: string;
  };

  const fetchCompanyFunds = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getCompanyFundAPI(Number(id));
      setCompanyFunds(data.results.reverse());

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [id, setCompanyFunds, setLoading]);

  const handleSelectFunds = (newValue: MultiValue<SelectOption>) => {
    setClearInput(false)
    if (newValue) {
      setFieldValue(
        "fund",
        newValue.map((option) => option.value)
      );
    } else {
      setFieldValue("fund", []);
    }
  };

  const fundsOptions = useMemo(() => {
    return funds
      ?.filter((fund) => fund.raw_data.active === true)
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund.raw_data?.short_name ?? fund?.name} - ${formatCPFCNPJ(fund.raw_data?.document_number)} `,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [funds]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();

      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchSendNewFunds = useCallback(async () => {
    setOpenAddFundsCompanyModal(false);
    setLoading(true);

    try {
      const promises = values.fund.map((fundId) => {
        return postCompanyFundAPI({
          company: Number(id),
          fund: Number(fundId),
        });
      });

      await Promise.all(promises);

      fetchCompanyFunds();

      notifySuccessCompanyFunds();
      resetForm();
      setFieldValue("fund", []);
      resetFormFields(); //
      setLoading(false);
      setClearInput(true)
    } catch (err: unknown) {
      console.error(err);
      setFieldValue("fund", []);
      setLoading(false);
      setClearInput(true)
      notifyErrorCompanyFunds();
      setOpenAddFundsCompanyModal(false);
    }
  }, [
    fetchCompanyFunds,
    id,
    resetForm,
    resetFormFields,
    setFieldValue,
    setOpenAddFundsCompanyModal,
    setLoading,
    values.fund,
  ]);

  useEffect(() => {
    fetchFunds();
  }, [fetchFunds]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        label="Atribuir fundos"
        open={open}
        height="550px"
        width="750px"
        onClose={() => {
          setClearInput(true)
          onClose();
          setFieldValue("fund", []);
        }}
        buttons={[
          {
            label: "Cancelar",
            onClick: () => {
              onClose();
              resetForm();
              setClearInput(true)
              setFieldValue("fund", []);
              resetFormFields();
            }
          },
          {
            label: "Concluir",
            kind: "primary",
            onClick: async () => {
              fetchSendNewFunds();
            },
            disabled: values.fund.length === 0
          },
        ]}
      >
        <div className="w-[96%] mx-auto">
          <Margin mb={10} />
          <Label text="Selecione os fundos que deseja associar à esta empresa" />
          <InputSelect
            kind="default"
            size="medium"
            clearInput={clearInput}
            options={fundsOptions}
            onChange={handleSelectFunds}
            placeholder="Pesquisar ou selecionar fundos..."
            isMulti
          />
          <Margin mb={104} />
        </div>
      </Modal>
    </form>
  );
};

export default AddFundsInCompany;
