import { Modal } from "prosperita-dumbo-react"
import { useCallback, useEffect } from "react";
import useCompany from "~/hooks/useCompany";
import useLoading from "~/hooks/useLoading";
import { getCompanysAPI, updateCompanyAPI } from "~/services/api/companys";

type Imodal = {
    open: boolean;
    onClose?: () => void;
};

export const ModalEditCompany = ({ open, onClose }: Imodal) => {

    const { setLoading } = useLoading();
    const {
        companyDetails,
        setOpenDeleteCompanyModal,
        setCompanys
    } =
        useCompany();

    const fetchCompanys = useCallback(async () => {
        try {
            const { data } = await getCompanysAPI();
            setCompanys(data.results.reverse());
        } catch (err: unknown) {
            console.error(err);
        }
    }, [setCompanys]);

    const fetchUpdateCompany = useCallback(

        async () => {
            setLoading(true);

            try {
                await updateCompanyAPI(
                    {
                        raw_data: {
                            active: !companyDetails?.raw_data?.active,
                            brand_name: companyDetails?.raw_data?.brand_name,
                            company_type: companyDetails?.raw_data?.company_type,
                            document_number: companyDetails?.raw_data?.document_number,
                            master_user: companyDetails?.raw_data?.master_user,
                        },
                    },
                    String(companyDetails?.id)
                );
                setOpenDeleteCompanyModal(false);
                setLoading(false);
            } catch (err: unknown) {
                console.error(err);
                setLoading(false);
            } finally {
                fetchCompanys()
                onClose && onClose();
            }
        },
        [setLoading, companyDetails]
    );

    useEffect(() => {
        fetchCompanys()
    }, [])


    return (
        <div>
            <Modal
                onClose={() => {
                    onClose && onClose();

                }}
                open={open}
                height="290px"
                width="600px"
                overflow
                label={
                    <>
                        {companyDetails?.raw_data?.active ? "Inativar Empresa" : "Ativar Empresa"}
                    </>
                }
                background="#f4f4f4"
                buttons={[
                    {
                        label: "Não, manter como está",
                        kind: "ghost",
                        dimension: "74",
                        onClick: () => {
                            //  resetForm()
                            onClose && onClose();
                        },
                    },
                    {
                        label: companyDetails?.raw_data?.active ? `Sim, inativar` : "Sim, Ativar",
                        icon: "",
                        dimension: "74",
                        kind: companyDetails?.raw_data?.active ? "dangerPrimary" : "primary",
                        onClick: () => { fetchUpdateCompany() },
                        disabled: false,
                    },
                ]}
            >
                <div className="">
                    <div>
                        <div className="p-4 space-y-2 text-gray-800">
                            <p className="text-lg font-medium">Tem certeza que deseja {companyDetails?.raw_data?.active ? "inativar" : "ativar"} esta empresa?</p>
                            <p className="text-sm text-gray-600">
                                <span className="font-semibold">Razão Social:</span> {companyDetails?.name}
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
