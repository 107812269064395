
import { useFormik } from "formik";
import { InputFluid, Margin, Modal } from "prosperita-dumbo-react";
import { useCallback, useEffect, useState } from "react";
import Badge from "~/components/Badge";
import useLoading from "~/hooks/useLoading";
import useWorkflow from "~/hooks/useWorkflow";
import { getStatusFiltered, getWorkflowFiltered, pacthStatusAPI } from "~/services/api/workflow";


type initialValues = {
    name: string,
    color: string,
    order: string,
    workflow: number,
    active: boolean
}

const initialValues = {
    name: "",
    active: false,
    color: "",
    workflow: 0,
    order: "",

}

export const ModalEditStatus = ({
    onClose,
    open,
    workflow
}: {
    open: boolean;
    onClose: () => void;
    workflow: number

}) => {

    const { setLoading } = useLoading();

    const [clearFild, setclearFild] = useState<boolean>(false)

    const {
        setActiveStatuses,
        setSelectedFlow,
        selectedFlow,
        activeStatuses,
        editStatus,
        setEditStatus,
    } = useWorkflow()

    const { handleChange, values, resetForm, setFieldValue } = useFormik({
        onSubmit: () => { },
        initialValues
    })

    const payload: initialValues = {
        name: values.name,
        active: true,
        color: values.color,
        workflow: Number(workflow),
        order: values.order
    }

    const fetchStatus = useCallback(async () => {

        try {
            const { data } = await getStatusFiltered(Number(workflow))

            const { data: work } = await getWorkflowFiltered(Number(workflow))

            setSelectedFlow(work)

            setActiveStatuses(data.results)

        } catch {

        }
    }, [workflow, activeStatuses, selectedFlow])


    const sendWorkflow = useCallback(async () => {

        setLoading(true);

        try {

            await pacthStatusAPI(Number(editStatus?.id), payload)

            await fetchStatus()

            resetForm()

            onClose()

        } catch (error) {

        } finally {

            setLoading(false);

            setEditStatus(null)
        }

    }, [payload, editStatus?.id])

    useEffect(() => {

        if (clearFild) {
            setclearFild(false)
        }
    }, [clearFild]);

    useEffect(() => {
        setFieldValue("name", editStatus?.data?.label)
        setFieldValue("color", editStatus?.style?.background)
    }, [editStatus, activeStatuses])


    return (
        <Modal
            label={<div className="m-[0]">
                Editar o Status :
                <div className="w-[40%] m-[0] mt-[10px]">
                    <Badge
                        label={editStatus?.data?.label}
                        color={editStatus ? String(editStatus?.style?.background) : "#312828"}
                    />
                </div>
            </div>}
            open={open}
            width="800px"
            height={"550px"}
            radius
            background="#f4f4f4"
            onClose={() => {
                onClose()
                resetForm()
                setclearFild(true)
                setEditStatus(null)
            }}
            buttons={
                [
                    {
                        label: "Cancelar",
                        kind: "ghost",
                        size: "large",
                        dimension: "80",
                        onClick: () => {
                            onClose && onClose()
                            setclearFild(true)
                            resetForm()
                            setEditStatus(null)
                        }
                    },
                    {
                        label: "Editar",
                        kind: "primary",
                        dimension: "80",
                        size: "large",
                        disabled: false,
                        onClick: () => { sendWorkflow() }
                    },
                ]
            }
        >
            <Margin top={20} />
            <div className="w-[97%] mx-auto"
                onClick={() => {
                }}
            >
                <InputFluid
                    label="Nome do status"
                    noRadius
                    name="name"
                    value={values.name}
                    placeholder="Digite"
                    onChange={handleChange}
                />
                <Margin top={10} />
                <InputFluid
                    label="Orderm"
                    noRadius
                    name="order"
                    value={values.order}
                    onChange={handleChange}
                    placeholder="Digite a posição"
                />
                <Margin top={10} />
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <label htmlFor="colorPicker" className="bx--label">
                        Cor do Badge
                    </label>
                    <input
                        type="color"
                        id="color"
                        value={values.color}
                        onChange={handleChange}
                        style={{
                            height: "3rem",
                            width: "3rem",
                            border: "none",
                            cursor: "pointer",
                        }}
                    />
                    <InputFluid
                        label="Cor (obrigatorio)"
                        noRadius
                        name="color"
                        value={values.color}
                        onChange={handleChange}
                        placeholder="#000000"
                    />
                </div>
            </div>
        </Modal>
    )
}
