import { Breadcrumb } from "prosperita-dumbo-react";
import { useNavigate } from "react-router-dom";
import lockicon from "~/assets/lock.png";
import Margin from "~/components/Margin";


type Irestrict = {
    text: string,
    title: string,
}

export const PageInactiveUser = ({ text, title }: Irestrict) => {

    const navigate = useNavigate()

    return (
        <div>
            <div className="flex justify-end w-[99%] mx-auto py-0 mt-[60px] gap-[0px]">
            </div>
            <div>
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                    <div className="flex flex-col">
                        <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                            <Breadcrumb
                                crumbsOptions={[
                                    { crumb: "Painel", onClick: () => navigate("/") },

                                ]}
                                endHiddenIndex={5}
                                startHiddenIndex={5}
                            />
                        </div>
                        <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                            {title}
                        </h2>
                    </div>
                </div>
            </div>
            <Margin mt={100} />
            <div className="flex flex-col justify-center w-[95%] mx-auto mt-0">
                <img src={lockicon} className="w-[160px]" alt="Ícone de lupa" />
                <div className="flex flex-col justify-center w-full mx-auto">
                    <h3 className="mt-4 text-[20px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans']">
                        Acesso restrito
                    </h3>
                    <h4 className="mt-2 text-[15px] font-normal leading-[28px] text-[#161616] font-['IBM Plex Sans']">
                        {text}
                    </h4>
                </div>
                <Margin mt={25} />
                <div className="w-[95%] mx auto flex justify-start">
                </div>
            </div>
        </div>
    );
};
