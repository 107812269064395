import {
  Button,
  Column,
  Grid,
  Modal,
  Stack,
  TextArea,
  Link
} from "@carbon/react";
import { ArrowLeft, TrashCan, View } from "@carbon/icons-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useLoading from "~/hooks/useLoading";
import {
  postAttendAPI,
  ISendNewAttendBody,
  getAttendHistoryAPI,
  // patchAttendDetailsAPI,
} from "~/services/api/attend";
import Select from "react-select";

import { permissions } from "~/services/permissions";
// import { postAttendLogAPI } from "~/services/api/attendLog";
import useUser from "~/hooks/useUser";

import Label from "~/components/Label";
import { toast } from "react-toastify";
import { postAttendTaskFileAPI } from "~/services/api/attendTaskFile";
import { postAttendLogAPI } from "~/services/api/attendLog";
import useAttend from "~/hooks/useAttend";
import { getAreasAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";
import { Link as LinkReactRouter, useParams } from "react-router-dom";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import { IAreaType } from "~/types";

interface PreviewFile extends File {
  preview: string;
}

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;

    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

const ContainerFile = styled.div`
  padding: 10px 30px;
  border-radius: 4px;
  border: 1px solid rgba(38, 0, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 5px;
`;

type Values = {
  message: string;

  client: string;

  area_type: string;

  type: string;
};

const initialValues: Values = {
  message: "",
  type: "",

  client: "",
  area_type: "",
};

const validationSchema = Yup.object({
  message: Yup.string().required("Descrição é obrigatória"),
  client: Yup.string(),

  area_type: Yup.string().required("Tipo é obrigatório"),
});

const NewSubAttend = () => {
  const { userProfile } = useUser();
  const { areas, setAreas } = useArea();
  const { setLoading, loading } = useLoading();
  const { attendDetails, setNewSubAttend } = useAttend();
  const canCreateAttends = permissions.includes("can_create_attends");
  const { setHistory } = useAttend()
  const { setAreaTypes, areaTypes } = useAreaType();

  const { id } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<PreviewFile | null>(null);

  const openModal = (file: PreviewFile) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedFile(null);
    setModalIsOpen(false);
  };

  const notifySuccess = () => toast.success("Atendimento criado com sucesso!");
  const notifyError = () => toast.error("O atendimento não pode ser criado");

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => undefined,
  });

  const [files, setFiles] = useState<PreviewFile[]>([]);

  const handleDelete = (fileToDelete: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ) as PreviewFile[];
      setFiles((prevFiles) => [...prevFiles, ...previewFiles]);
    },
  });

  const handleSelectType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("type", "");
    }
  };

  const createNewSubAttend = () => {
    const sendNewAttendPayload: ISendNewAttendBody = {
      message: values.message,
      type: Number(values?.type),
      client: userProfile?.id ?? 0,
      fund: attendDetails?.fund?.id ?? 0,
      area: Number(values.area_type),
      parent: attendDetails?.id ?? 0,
      status: 1
    };

    fetchSendNewAttend(sendNewAttendPayload);
  };



  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const areaOptions = useMemo(() => {
    return areas
      .map((area) => ({
        value: String(area.id),
        label: `${area.name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [areas]);

  const filteredAreaTypes = useMemo(
    () =>
      areaTypes.filter((areaType: IAreaType) => {
        return areaType.area.id === Number(values.area_type);
      }),
    [areaTypes, values.area_type])

  const areaTypesOptions = useMemo(() => {
    const options = filteredAreaTypes
      .filter((item) => {
        return item.area.active && item.type.active;
      })
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const uniqueOptions = Array.from(
      new Map(options.map((option) => [option.value, option])).values()
    );
    return uniqueOptions;
  }, [filteredAreaTypes]);


  type SelectOption = {
    value: string;
    label: string;
  };

  const handleSelectAreaType = (
    selectedOption: SelectOption | null,
    actionMeta: { action: string }
  ) => {
    if (selectedOption) {
      setFieldValue("area_type", selectedOption.value);
    } else if (actionMeta.action === "clear") {
      setFieldValue("area_type", "");
    }
  };

  const sendFile = async (file: PreviewFile, dataId: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("sender", String(userProfile?.id || ""));
    formData.append("attend", String(dataId));
    formData.append("description", `${file?.name}`);

    await postAttendTaskFileAPI(formData); // Envia um único arquivo
  };

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchSendNewAttend = async (payload: ISendNewAttendBody) => {
    setLoading(true);
    try {
      const { data } = await postAttendAPI(payload);

      await postAttendLogAPI({
        attend: data.id,
        actual_area: Number(values.area_type),
      });

      // if (userProfile?.id === attendDetails?.owner?.id) {
      //   await patchAttendDetailsAPI(Number(attendDetails?.id), {
      //     status: 5,
      //   });
      // }

      if (data && files.length > 0) {
        const filePromises = files.map((file: PreviewFile) =>
          sendFile(file, String(data.id))
        );

        await Promise.all(filePromises);

        setLoading(false);
        setNewSubAttend(false);
        resetForm();
        notifySuccess();
        fetchHistory();

        return;
      }

      setLoading(false);
      setNewSubAttend(false);
      resetForm();
      notifySuccess();
      fetchHistory();
    } catch (e) {
      console.error(e);
      setLoading(false);
      notifyError();
    }
  };

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreas]);


  useEffect(() => {
    fetchAreas();
  }, [fetchAreas]);

  useEffect(() => {
    fetchAreaTypes();
  }, []);

  return (
    <TicketFormContainer style={{ padding: "20px" }}>
      {canCreateAttends ? (
        <form onSubmit={handleSubmit}>
          <Grid>
            <Column span={16}>
              <Padding y={24}>
                <Link onClick={() => setNewSubAttend(false)}>
                  {" "}
                  <ArrowLeft
                    className="link-icon"
                    style={{ marginRight: "10px" }}
                  />
                  <span>Voltar</span>
                </Link>
              </Padding>
              <h2>Novo Chamado</h2>
              <Margin mb={12} />
              <Grid className="form">
                <Column span={12}>
                  <Grid>
                    <Column span={12}>
                      <Padding y={12}></Padding>
                    </Column>
                    <Column span={12}>
                      <Stack gap={5}>
                        <Margin mb={5} />
                        <Label text="Selecione a área que deseja abrir o chamado" />
                        <Select
                          isClearable={true}
                          options={areaOptions}
                          onChange={(selectedOption, actionMeta) =>
                            handleSelectAreaType(selectedOption, actionMeta)
                          }
                          placeholder="Pesquisar ou selecionar Tipo de atendimento..."
                        />
                        <Margin mb={5} />
                        {values.area_type !== "" &&
                          <>
                            {areaTypesOptions.length === 0 ?
                              <>
                                <LinkReactRouter
                                  to={`/app/areas/${values.area_type}/`}
                                  style={{
                                    display: "inline-block",
                                    padding: "10px 16px",
                                    backgroundColor: "#ffcc00",
                                    color: "#333",
                                    fontWeight: "bold",
                                    borderRadius: "5px",
                                    textDecoration: "none",
                                  }}
                                >
                                  ⚠️ A área não possui nenhum Tipo de Atendimento!{" "}
                                  <span style={{ fontWeight: "normal" }}>Clique aqui para ir para a área e cadastrar um tipo de atendimento.</span>
                                </LinkReactRouter>

                              </> :
                              <>
                                <Label text="Selecione o tipo de atendimento" />
                                <Select
                                  isClearable={true}
                                  options={areaTypesOptions}
                                  onChange={(selectedOption, actionMeta) => {
                                    handleSelectType(selectedOption, actionMeta)
                                  }
                                  }
                                  placeholder="Pesquisar ou selecionar Tipo de atendimento..."
                                />
                              </>
                            }
                          </>
                        }

                        <Margin mb={5} />
                        <Label text="Selecione seus documentos para enviar" />
                        <div
                          {...getRootProps()}
                          style={{
                            border: "2px dashed #cccccc",
                            padding: "20px",
                            textAlign: "center",
                          }}
                        >
                          <input {...getInputProps()} />

                          <p>
                            Arraste e solte alguns arquivos aqui, ou clique para
                            selecionar os arquivos
                          </p>
                        </div>
                        <div>
                          <Margin mb={10} />
                          <div>
                            <Modal
                              open={modalIsOpen}
                              onRequestClose={closeModal}
                              primaryButtonText="Fechar visualização"
                              onRequestSubmit={closeModal}
                            >
                              <>
                                {selectedFile && (
                                  <div>
                                    <h2>{selectedFile.name}</h2>
                                    {selectedFile.type.startsWith("image/") ? (
                                      <img
                                        src={selectedFile.preview}
                                        alt={selectedFile.name}
                                        style={{ width: "100%" }}
                                      />
                                    ) : selectedFile.type ===
                                      "application/pdf" ? (
                                      <iframe
                                        src={selectedFile.preview}
                                        title={`Preview of ${selectedFile.name}`}
                                        style={{
                                          width: "100%",
                                          height: "500px",
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          height: "400px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h4>
                                          Tipo de arquivo não suportado para
                                          visualização.{" "}
                                          <a
                                            href={selectedFile.preview}
                                            download={selectedFile.name}
                                          >
                                            Clique aqui para baixar
                                          </a>
                                        </h4>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            </Modal>
                            {files.map((file, index) => (
                              <ContainerFile key={String(index)}>
                                <p>{file.name}</p>
                                <div
                                  className="d-flex"
                                  style={{ display: "flex" }}
                                >
                                  <Button
                                    hasIconOnly
                                    renderIcon={View}
                                    iconDescription="Visualizar"
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => openModal(file)}
                                  />
                                  <Margin ml={10} />
                                  <Button
                                    hasIconOnly
                                    renderIcon={TrashCan}
                                    size="sm"
                                    kind="ghost"
                                    onClick={() => {
                                      handleDelete(file);
                                    }}
                                    iconDescription="Excluir"
                                  />
                                </div>
                              </ContainerFile>
                            ))}
                          </div>
                        </div>
                        <Margin mb={5} />
                        <TextArea
                          id="message"
                          name="message"
                          labelText="Mensagem"
                          required
                          placeholder="Digite a mensagem"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ resize: "none" }}
                        />
                      </Stack>
                    </Column>

                    <Column span={12}>
                      <Margin mb={64} />
                      <Grid>
                        <Column span={3}>
                          <Button
                            kind="tertiary"
                            className="action-button"
                            onClick={() => setNewSubAttend(false)}
                          >
                            Cancelar
                          </Button>
                        </Column>
                        <Column span={3}>
                          <Button
                            onClick={() => createNewSubAttend()}
                            kind="primary"
                            className="action-button"
                            disabled={!isValid || loading || values.type === ""}
                          >
                            Enviar
                          </Button>
                        </Column>
                      </Grid>
                    </Column>

                    <Column span={6} />
                  </Grid>

                  <Margin mb={64} />
                </Column>
              </Grid>

              <Margin mb={64} />
            </Column>
          </Grid>
        </form >
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar atendimentos</h4>
        </div>
      )}
    </TicketFormContainer >
  );
};

export default NewSubAttend;
