import {
  RadioButton,
  RadioButtonGroup,
  TextInput,
  Toggle,
} from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import {
  getAttendTypeAPI,
  ISendNewAttendTypeBody,
  postAttendTypeAPI,
} from "~/services/api/type";

import useAttendType from "~/hooks/useType";
import { toast } from "react-toastify";
import Label from "~/components/Label";
import useWorkflow from "~/hooks/useWorkflow";
import { Dropdown, Modal } from "prosperita-dumbo-react";

type Values = {
  name: string;
  sla: number;
  sla_type: string;
  workflow: number
};

const initialValues: Values = {
  name: "",
  sla: 1,
  sla_type: "",
  workflow: 0
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sla: Yup.number().required("Nome da tarefa é obrigatório"),
});

const NewAttendTypeContentModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setOpenNewAttendTypeModal, setAttendTypes } = useAttendType();
  const [clearFild, setclearFild] = useState<boolean>(false)

  const {
    flowList,
  } = useWorkflow()

  const [selectedRadio, setSelectedRadio] = useState<string>("day");

  const handleChangeRadio = (value: unknown) => {
    setSelectedRadio(String(value));
  };

  const notifySuccess = () =>
    toast.success("Tipo de atendimento criado com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível criar o tipo de atendimento. Tente novamente mais tarde."
    );

  const { handleSubmit, resetForm, values, handleBlur, handleChange, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => { },
    });

  const [active, setActive] = useState<boolean>(true);

  const handleToogleHasBankAccount = (state: boolean) => {
    setActive(state);
  };

  const flowListDrop = useMemo(() => {
    return flowList?.map((flow) => ({
      label: flow.name,
      value: String(flow.id)
    }))
  }, [flowList])

  const sendNewAttendType: ISendNewAttendTypeBody = useMemo(() => {
    return {
      name: values.name,
      sla: values.sla,
      sla_type: String(selectedRadio),
      workflow: values.workflow,
      active,
    };
  }, [values, selectedRadio, active]);


  const fetchAttendTypes = useCallback(async () => {
    try {
      const { data } = await getAttendTypeAPI();
      setAttendTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAttendTypes]);

  const fetchSendNewAttendType = async (payload: ISendNewAttendTypeBody) => {
    
    setLoading(true);

    try {

      await postAttendTypeAPI(payload);

      resetForm();

      await fetchAttendTypes();

      setLoading(false);
      notifySuccess();
      setOpenNewAttendTypeModal(false);
      setclearFild(true)
    } catch (error) {
      console.error("Erro ao enviar Area:", error);
      setLoading(false);
      notifyError();
    }
  };


  useEffect(() => {

    if (clearFild) {
      setclearFild(false)
    }
  }, []);


  return (
    <form onSubmit={handleSubmit}>
      <Modal
        open={open}
        label={"Novo tipo de atendimento"}
        onClose={() => {
          onClose()
          setclearFild(true)
        }}
        width="700px"
        buttons={
          [

            {
              label: "Cancelar",
              onClick: () => {
                onClose();
                resetForm();
                setclearFild(true)
              },

            },
            {
              label: "Concluir",
              kind: "primary",
              onClick: () => {
                fetchSendNewAttendType(sendNewAttendType)
              },
              disabled: values.name === "" ||
                Number(values.sla) < 0 ||
                String(values.sla) === "" ||
                Number(values.workflow) === 0
            }
          ]
        }
      >
        <div className="w-[95%] mx-auto">
          <Margin mt={20} />
          <TextInput
            id="name"
            name="name"
            labelText="Nome"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          <Margin mb={24} />
          <Dropdown
            kind="default"
            size="medium"
            placeholder="Selecione o Fluxo De Status"
            noRadius
            id="workflow"
            options={flowListDrop || []}
            onChange={(event) => setFieldValue("workflow", Number(event?.target?.value))}
            label="Fluxo De Status"
            clearFiles={clearFild}
          />
          <Margin mb={20} />
          <Label text="Tipo do SLA" />
          <Margin mb={20} />
          <RadioButtonGroup
            name="radio-group"
            valueSelected={selectedRadio}
            onChange={handleChangeRadio}
          >
            <RadioButton labelText="Dia(s)" value="day" id="radio-1" />
            <RadioButton labelText="Hora(s)" value="hour" id="radio-2" />
            <RadioButton labelText="Minuto(s)" value="minute" id="radio-3" />
          </RadioButtonGroup>

          <Margin mb={10} />
          <TextInput
            type="number"
            id="sla"
            name="sla"
            labelText={`SLA (${selectedRadio === "day" ? "Dias" : selectedRadio === "hour" ? "Horas" : "Minutos"})`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.sla}
          />
          <Margin mb={24} />
          <Toggle
            id="toggle-1"
            labelText="Status"
            labelA="Inativo"
            onToggle={(e) => handleToogleHasBankAccount(e)}
            labelB="Ativo"
            toggled={active}
          />
          <Margin mb={24} />
        </div>
      </Modal>
    </form >
  );
};

export default NewAttendTypeContentModal;
