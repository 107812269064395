import { createBrowserRouter } from "react-router-dom";
import PrivateRouter from "~/components/PrivateRouter";
import PublicRouter from "~/components/PublicRouter";
import DashboardStructure from "~/pages/app/DashboardStructure";
import NewAttend from "~/pages/app/Attends/NewAttend";
import Attends from "~/pages/app/Attends/Attends";
import Login from "~/pages/auth/Login";
import Tasks from "~/pages/app/Tasks/Tasks";
import NewTask from "~/pages/app/Tasks/NewTask";
import Profiles from "~/pages/app/Profiles/Profiles";
import NewProfile from "~/pages/app/Profiles/NewProfile";
import Areas from "~/pages/app/Areas/Areas";
import AttendTypes from "~/pages/app/AttendTypes/AttendTypes";
import AttendDetails from "~/pages/app/Attends/AttendDetails";
import AreaDetails from "~/pages/app/Areas/AreaDetails";
import SubAttendDetails from "~/pages/app/Attends/SubAttends/SubAttendDetails";
import General from "~/pages/app/Profiles/General";
import Funds from "~/pages/app/Funds/Funds";
import Companys from "~/pages/app/Companys/Companys";
import CompanyTypes from "~/pages/app/CompanyTypes/CompanyTypes";
import MyData from "~/pages/app/MyData/MyData";
import CompanyData from "~/pages/app/Companys/CompanyData";
import Payment from "~/pages/app/Payments/Payments";
import NewPayment from "~/pages/app/Payments/NewPayment";
import PaymentDetails from "~/pages/app/Payments/PaymentDetails";
import PaymentsTypes from "~/pages/app/PaymentsTypes/PaymentsTypes";
import CompanyDetails from "~/pages/app/Companys/CompanyDetails";
import Approvers from "~/pages/app/Payments/Approvers";
import Beneficiaries from "~/pages/app/Beneficiaries/Beneficiaries";
import NewBeneficiaries from "~/pages/app/Beneficiaries/NewBeneficiaries";
import GeneralBeneficiaries from "~/pages/app/Beneficiaries/General";
import BeneficiaryDetails from "~/pages/app/Beneficiaries/BeneficiaryDetails";
import NewPaymentDetails from "~/pages/app/Payments/NewPaymentDetails";
import Status from "~/pages/app/Status/Status";
import { RegisterBeneficiary } from "~/pages/app/Beneficiaries/Register/Register";
import { ListForum } from "~/pages/app/Forum/ListForum";
import { TypeForumList } from "~/pages/app/Forum/TypeForum/TypeForumList";
import { NewTypeForum } from "~/pages/app/Forum/TypeForum/NewTypeForum";
import { SummaryForum } from "~/pages/app/Forum/TypeForum/SummaryForum";
import { CreatForumGeneral } from "~/pages/app/Forum/CreatForum/CreatForumGeneral";
import { SessionActivities } from "~/pages/app/Forum/Activities/SessionActivities";
import { Forum } from "~/pages/app/Forum/Forum";
import AuthenticateFund from "~/pages/app/Funds/AuthenticateFund/AuthenticateFund";
import { CreateNewTopic } from "~/pages/app/Forum/Topical/CreateNewTopic";
import { Comments } from "~/pages/app/Forum/Topical/Comments";
import DagreGraph from "~/pages/app/Status/StatusFlow";
import { ListWorkflowStatus } from "~/pages/app/Status/ListWorkflowStatus";
import useUser from "~/hooks/useUser";
import { PageInactiveUser } from "~/pages/app/PageInactiveUser";

const defaultOptions = {
  errorElement: <></>,
};

const AppRouter = () => {

  const { userProfile } = useUser()

  return createBrowserRouter([
    {
      path: "/",
      element: (
        <PublicRouter>
          <Login />
        </PublicRouter>
      ),
      ...defaultOptions,
    },

    {
      path: "/login",
      element: (
        <PublicRouter>
          <Login />
        </PublicRouter>
      ),
      ...defaultOptions,
    },

    {
      path: "/app",
      element: (
        <PrivateRouter>
          <DashboardStructure />
        </PrivateRouter>
      ),
      ...defaultOptions,
      children: [
        {
          path: "forum",
          element: <Forum />,
          ...defaultOptions,
          children: [
            {
              path: "forum-type",
              element: <TypeForumList />,
              ...defaultOptions,
            },
            {
              path: "list-forum",
              element: <ListForum />,
              ...defaultOptions,
            },
            {
              path: "new-type-forum",
              element: <NewTypeForum />,
              ...defaultOptions,
            },
            {
              path: "summary-forum",
              element: <SummaryForum />,
              ...defaultOptions,
            },
            {
              path: "creat-forum",
              element: <CreatForumGeneral />,
              ...defaultOptions,
            },
            {
              path: "activities",
              element: <SessionActivities />,
              ...defaultOptions,
            },
            {
              path: "new-topic",
              element: <CreateNewTopic />,
              ...defaultOptions,
            },
            {
              path: "comments",
              element: <Comments />,
              ...defaultOptions,
            },
          ],
        },
        {
          path: "autheticate-fund/:id",
          element: <AuthenticateFund />,
          ...defaultOptions,
        },
        {
          path: "payments",
          element: <Payment />,
          ...defaultOptions,
        },
        {
          path: "payments/new",
          element: <NewPayment />,
          ...defaultOptions,
        },
        {
          path: "payments/new-details",
          element: <NewPaymentDetails />,
          ...defaultOptions,
        },
        {
          path: "payments/:id",
          element: <PaymentDetails />,
          ...defaultOptions,
        },
        {
          path: "approvers",
          element: <Approvers />,
          ...defaultOptions,
        },
        {
          path: "approvers-company",
          element: <Payment />,
          ...defaultOptions,
        },
        {
          path: "",
          element: <Attends />,
          ...defaultOptions,
        },
        {
          path: "attends",
          element: <Attends />,
          ...defaultOptions,
        },
        {
          path: "attends/:id",
          element:
            <AttendDetails />,
        },
        {
          path: "attends/sub-attend/:id",
          element: userProfile?.raw_data?.status === "active" ?
            <SubAttendDetails /> :
            <PageInactiveUser
              title="Atendimentos"
              text="Você não tem acesso a esta página!"
            />,
        },
        {
          path: "attends/new",
          element: userProfile?.raw_data?.status === "active" ?
            <NewAttend /> :
            <PageInactiveUser
              title="Atendimentos"
              text="Você não tem acesso a esta página!"
            />,
          ...defaultOptions,
        },

        {
          path: "beneficiaries",
          element: <Beneficiaries />,
          ...defaultOptions,
        },
        {
          path: "beneficiaries/:id",
          element: <BeneficiaryDetails />,
          ...defaultOptions,
        },
        {
          path: "beneficiaries/register",
          element: userProfile?.raw_data?.status === "active" ?
            <RegisterBeneficiary /> :
            <PageInactiveUser
              title="beneficiarios"
              text="Você não tem acesso a esta página!"
            />,
          ...defaultOptions,
        },
        {
          path: "beneficiaries/new",
          element: <NewBeneficiaries />,
          ...defaultOptions,
          children: [
            {
              path: "general",
              element: <GeneralBeneficiaries />,
            },
          ],
        },
        {
          path: "tasks",
          element: <Tasks />,
          ...defaultOptions,
        },
        {
          path: "tasks/:id",
          element: <AttendDetails />,
        },
        {
          path: "tasks/new",
          element: <NewTask />,
          ...defaultOptions,
        },
        {
          path: "status",
          element: <Status />,
          ...defaultOptions,
        },
        {
          path: "status-flow",
          element: <ListWorkflowStatus />,
          ...defaultOptions,
        },
        {
          path: "status-flow-details/:id",
          element: <DagreGraph />,
          ...defaultOptions,
        },
        {
          path: "areas",
          element: userProfile?.raw_data?.status === "active" ?
            <Areas /> :
            <PageInactiveUser
              title="Areas"
              text="Acesso Restrito"
            />,
          ...defaultOptions,
        },
        {
          path: "areas/:id",
          element: userProfile?.raw_data?.status === "active" ?
            <AreaDetails /> :
            <PageInactiveUser
              title="Areas"
              text="Acesso Restrito"
            />,
          ...defaultOptions,
        },
        {
          path: "attend-types",
          element: userProfile?.raw_data?.status === "active" ?
            <AttendTypes /> :
            <PageInactiveUser
              title="Atendimento"
              text="Acesso Restrito"
            />,
          ...defaultOptions,
        },
        {
          path: "company-types",
          element: <CompanyTypes />,
          ...defaultOptions,
        },
        {
          path: "payments-types",
          element: <PaymentsTypes />,
          ...defaultOptions,
        },
        {
          path: "my-data",
          element: <MyData />,
          ...defaultOptions,
        },
        {
          path: "company-data",
          element: <CompanyData />,
          ...defaultOptions,
        },

        {
          path: "profiles",
          element: <Profiles />,
          ...defaultOptions,
        },
        {
          path: "profiles/:id",
          element: <>any</>,
        },
        {
          path: "profiles/new",
          element: <NewProfile />,
          ...defaultOptions,
          children: [
            {
              path: "general",
              element: <General />,
              ...defaultOptions,
            },
          ],
        },
        {
          path: "funds",
          element: <Funds />,
          ...defaultOptions,
        },

        {
          path: "companys",
          element: <Companys />,
          ...defaultOptions,
        },
        {
          path: "companys/:id",
          element: <CompanyDetails />,
          ...defaultOptions,
        },
      ],
    },
  ]);

}


export default AppRouter;
