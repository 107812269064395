import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button,
  Pagination,
} from "@carbon/react";

import { TrashCan } from "@carbon/icons-react";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";

// import { useFormik } from "formik";
import Padding from "~/components/Padding";

import useArea from "~/hooks/useArea";

import { useParams } from "react-router-dom";

import useAreaType from "~/hooks/useAreaType";
import { IAreaType } from "~/types";

import { permissions } from "~/services/permissions";

import { formatCPFCNPJ } from "~/services/utils";

import { useFormik } from "formik";
import useUser from "~/hooks/useUser";
import { getCompanyFundAPI } from "~/services/api/companyFund";
import useFund from "~/hooks/useFund";

const tableHeadersArea: string[] = ["Nome", "CPF", ""];
const tableHeaderFunds: string[] = ["Nome", "CNPJ", "Nome Abreviado"];

const CompanyData = () => {
  const { id } = useParams();
  const { userProfile } = useUser();
  const { setFundsCompany, fundsCompany } = useFund();
  const { setOpenDeleteAreaTypeModal, setAreaTypeDetails } = useArea();
  const { areaTypes } = useAreaType();

  const canReadAreas = permissions.includes("can_read_areas");
  const canEditAreas = permissions.includes("can_edit_areas");

  const [currentPageAreaType, setCurrentPageAreaType] = useState(1);
  const [itemsPerPageAreaTypes, setItemsPerPageAreaTypes] = useState(20);

  const [currentPageFundsCompany, setCurrentPageAreaProfile] = useState(1);
  const [itemsPerPageFundsCompany, setItemsPerPageAreaProfile] = useState(20);

  const { values } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values: { search: string }) => {
      console.log(values);
    },
  });
  console.log(values);

  const fetchCompanyFunds = useCallback(async () => {
    try {
      if (userProfile?.company_get?.id) {
        const { data } = await getCompanyFundAPI(
          Number(userProfile?.company_get?.id)
        );
        setFundsCompany(data.results.reverse());
      }
      //   setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [userProfile, setFundsCompany]);

  const filteredAreaTypes = useMemo(
    () =>
      areaTypes.filter((areaType: IAreaType) => {
        return areaType.area.id === Number(id);
      }),
    [areaTypes, id]
  );

  const paginatedAreaTypes = useMemo(() => {
    const start = (currentPageAreaType - 1) * itemsPerPageAreaTypes;
    const end = start + itemsPerPageAreaTypes;
    return filteredAreaTypes?.slice(start, end);
  }, [currentPageAreaType, itemsPerPageAreaTypes, filteredAreaTypes]);

  const paginatedFundsCompany = useMemo(() => {
    const start = (currentPageFundsCompany - 1) * itemsPerPageFundsCompany;
    const end = start + itemsPerPageFundsCompany;
    return fundsCompany?.slice(start, end);
  }, [fundsCompany, currentPageFundsCompany, itemsPerPageFundsCompany]);

  useEffect(() => {
    fetchCompanyFunds();

    return () => { };
  }, [fetchCompanyFunds]);

  return (
    <Grid>
      {canReadAreas ? (
        <Column span={16}>
          <Padding y={24}>
            {/* <Breadcrumb>
              <BreadcrumbItem onClick={() => navigate("/app/areas/")}>
                Áreas
              </BreadcrumbItem>
              <BreadcrumbItem onClick={() => navigate(`/app/areas/${id}`)}>
                {areaDetails?.name ?? "Detalhes"}
              </BreadcrumbItem>
            </Breadcrumb> */}
          </Padding>
          <h1>{userProfile?.company_get?.raw_data?.brand_name}</h1>
          <h3>
            {userProfile?.company_get?.raw_data?.document_number &&
              formatCPFCNPJ(userProfile?.company_get?.raw_data?.document_number)}
          </h3>
          <h3>{userProfile?.company_get?.raw_data.company_type.name}</h3>
          <Margin mt={34} /> <Margin mt={20} />
          <TableContainer title="Fundos atribuídos">
            <Margin mb={-20} />
            <TableToolbar>
              {/* {!canReadAreas && (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4 style={{ position: "relative", left: "45%" }}>
                    Você não possui permissão para visualizar as áreas
                  </h4>
                </div>
              )} */}

              <TableToolbarContent>
                {/* <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                  /> */}
                {/* {canEditAreas && (
                  <Button
                    tabIndex={0}
                    onClick={() => {
                      setOpenAssociateAttendAreaProfileModal(true);
                    }}
                    kind="primary"
                  >
                    Atribuir usuário
                  </Button>
                )} */}
              </TableToolbarContent>
            </TableToolbar>

            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                  {tableHeaderFunds.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedFundsCompany?.length === 0 ? (
                  <TableCell colSpan={tableHeaderFunds.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Esta empresa não possui nenhum fundo atribuído</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedFundsCompany.length > 0 ? (
                  paginatedFundsCompany?.map((companyFund, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}

                      <TableCell>{companyFund?.fund.name}</TableCell>
                      <TableCell>
                        <p
                          style={{ whiteSpace: "nowrap" }}>
                          {formatCPFCNPJ(
                            companyFund?.fund?.raw_data?.document_number
                          )}
                        </p>
                      </TableCell>
                      <TableCell>
                        {companyFund?.fund?.raw_data.short_name}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedFundsCompany?.map((companyFund, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}
                      <TableCell>{companyFund?.fund.name}</TableCell>
                      <TableCell>
                        {formatCPFCNPJ(
                          companyFund?.fund?.raw_data?.document_number
                        )}
                      </TableCell>
                      <TableCell>
                        {companyFund?.fund?.raw_data.short_name}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination
              totalItems={paginatedFundsCompany.length}
              pageSize={itemsPerPageFundsCompany}
              pageSizes={[5, 10, 20, 30, 40, 50]}
              onChange={({ page, pageSize }) => {
                setCurrentPageAreaProfile(page);
                setItemsPerPageAreaProfile(pageSize);
              }}
              page={currentPageFundsCompany}
              size="md"
            />
          </TableContainer>
          <Margin mt={50} />
          <TableContainer title="Usuários atribuídos">
            <Margin mb={-20} />
            <TableToolbar>
              {/* {!canReadAreas && (
                <div
                  style={{
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4 style={{ position: "relative", left: "45%" }}>
                    Você não possui permissão para visualizar as áreas
                  </h4>
                </div>
              )} */}

              <TableToolbarContent>
                {/* <TableToolbarSearch
                    tabIndex={0}
                    onChange={handleChange}
                    value={values.search}
                    id="search"
                    placeholder="Pesquisar"
                  /> */}
              </TableToolbarContent>
            </TableToolbar>

            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableSelectAll
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                      ariaLabel={""}
                    /> */}
                  {tableHeadersArea.map((columnTitle, index) => {
                    return (
                      <TableHeader key={String(index)}>
                        {columnTitle}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAreaTypes?.length === 0 ? (
                  <TableCell colSpan={tableHeadersArea.length + 1}>
                    <Padding pt={24} pb={24} pl={12}>
                      <div
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Somente Você</h4>
                      </div>
                    </Padding>
                  </TableCell>
                ) : paginatedAreaTypes.length > 0 ? (
                  paginatedAreaTypes?.map((areaType, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}

                      <TableCell>{areaType?.type?.name}</TableCell>
                      <TableCell>
                        {areaType.type?.sla}{" "}
                        {areaType.type?.sla_type === "day"
                          ? "Dia(s)"
                          : areaType.type?.sla_type === "hour"
                            ? "Hora(s)"
                            : "Minuto(s)"}
                      </TableCell>
                      <TableCell>
                        {/* <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Detalhes"
                            renderIcon={View}
                            id="filter"
                          /> */}
                        {canEditAreas && (
                          <Button
                            kind="ghost"
                            hasIconOnly
                            iconDescription="Remover"
                            renderIcon={TrashCan}
                            id="filter"
                            onClick={() => {
                              setAreaTypeDetails(areaType);
                              setOpenDeleteAreaTypeModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  paginatedAreaTypes?.map((areaType, index) => (
                    <TableRow key={String(index)} style={{ cursor: "pointer" }}>
                      {/* <TableSelectRow
                          name={""}
                          id={""}
                          onSelect={console.log}
                          checked={false}
                        /> */}
                      <TableCell>{areaType?.type?.name}</TableCell>
                      <TableCell>{areaType.type?.sla}h</TableCell>
                      <TableCell>
                        {" "}
                        <Button
                          kind="ghost"
                          hasIconOnly
                          renderIcon={TrashCan}
                          onClick={() => setOpenDeleteAreaTypeModal(true)}
                          id="filter"
                        ></Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination
              totalItems={paginatedAreaTypes.length}
              pageSize={itemsPerPageAreaTypes}
              pageSizes={[5, 10, 20, 30, 40, 50]}
              onChange={({ page, pageSize }) => {
                setCurrentPageAreaType(page);
                setItemsPerPageAreaTypes(pageSize);
              }}
              page={currentPageAreaType}
              size="md"
            />
          </TableContainer>
          <Margin mb={50} />
        </Column>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "500px",
            position: "relative",
            left: "520%",
            bottom: "10%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para visualizar áreas</h4>
        </div>
      )}
      {/* <DeleteAreaTypeModal
        onClose={() => setOpenDeleteAreaTypeModal(false)}
        open={openDeleteAreaTypeModal}
      />
      <AssociateAreaTypeModal
        onClose={() => setOpenAssociateAreaTypeModal(false)}
        open={openAssociateAreaTypeModal}
      />
      <AssociateAttendAreaProfileModal
        onClose={() => setOpenAssociateAttendAreaProfileModal(false)}
        open={openAssociateAttendAreaProfileModal}
      />

      <DeleteAttendAreaProfileModal
        onClose={() => setOpenDeleteAttendAreaProfileModal(false)}
        open={openDeleteAttendAreaProfileModal}
      /> */}
    </Grid>
  );
};

export default CompanyData;
