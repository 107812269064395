import { useFormik } from "formik";
import { InputFluid, Margin, Modal } from "prosperita-dumbo-react";
import { useCallback, useEffect, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import useWorkflow from "~/hooks/useWorkflow";
import { getStatusFiltered, getWorkflowFiltered, postWorkflowStatus } from "~/services/api/workflow";


type initialValues = {
    name: string,
    color: string,
    workflow: number,
    active: boolean
}

const initialValues = {
    name: "",
    active: false,
    color: "",
    workflow: 0,

}

export const ModalAddStatus = ({
    onClose,
    open,
    workflow
}: {
    open: boolean;
    onClose: () => void;
    workflow: number

}) => {

    const { setLoading } = useLoading();

    const [clearFild, setclearFild] = useState<boolean>(false)


    const {
        setNotification,
        closeNotification
    } = useNotification()

    const {
        setActiveStatuses,
        setSelectedFlow,
        selectedFlow,
        activeStatuses
    } = useWorkflow()

    const { handleChange, values, resetForm } = useFormik({
        onSubmit: () => { },
        initialValues
    })

    const payload: initialValues = {
        name: values.name,
        active: true,
        color: values.color,
        workflow: Number(workflow)
    }

    const fetchStatus = useCallback(async () => {

        try {

            const { data } = await getStatusFiltered(Number(workflow))

            const { data: work } = await getWorkflowFiltered(Number(workflow))

            setSelectedFlow(work)

            setActiveStatuses(data.results)

        } catch {

        }
    }, [workflow, activeStatuses, selectedFlow])

    
    const sendWorkflow = useCallback(async () => {

        setLoading(true);

        try {

            await postWorkflowStatus(payload)

            setNotification({
                status: "success",
                message: "",
                title: "Criado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });;

            await fetchStatus()

            resetForm()

            onClose()


        } catch (error) {

            setNotification({
                status: "error",
                message: "",
                title: "Falha ao editar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false);
        }

    }, [payload])


    useEffect(() => {

        if (clearFild) {
            setclearFild(false)
        }
    }, [clearFild]);

    return (
        <Modal
            label="Adicionar status"
            open={open}
            width="800px"
            height={"450px"}
            radius
            background="#f4f4f4"
            onClose={() => {
                onClose()
                resetForm()
                setclearFild(true)
            }}
            buttons={
                [
                    {
                        label: "Cancelar",
                        kind: "ghost",
                        size: "large",
                        dimension: "80",
                        onClick: () => {
                            onClose && onClose()
                            setclearFild(true)
                            resetForm()
                        }
                    },
                    {
                        label: "Adicionar",
                        kind: "primary",
                        dimension: "80",
                        size: "large",
                        disabled: false,
                        onClick: () => { sendWorkflow() }
                    },
                ]
            }
        >
            <h4 className="text-[17px] mx-[12px]">Adicione um status ao fluxo</h4>
            <Margin top={20} />
            <div className="w-[97%] mx-auto"
                onClick={() => {
                }}
            >
                <InputFluid
                    label="Nome do status (obrigatorio)"
                    noRadius
                    name="name"
                    value={values.name}
                    placeholder="Digite"
                    onChange={handleChange}
                />
                <Margin top={10} />
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <label htmlFor="colorPicker" className="bx--label">
                        Cor do Badge
                    </label>
                    <input
                        type="color"
                        id="color"
                        value={values.color}
                        onChange={handleChange}
                        style={{
                            height: "3rem",
                            width: "3rem",
                            border: "none",
                            cursor: "pointer",
                        }}
                    />
                    <InputFluid
                        label="Cor (obrigatorio)"
                        noRadius
                        name="color"
                        value={values.color}
                        onChange={handleChange}
                        placeholder="#000000"
                    />
                </div>
                {/* <div
                    onClick={(e) => {
                        e.stopPropagation()
                        setopenDrop(true)
                    }}
                >
                    <Dropdown
                        kind="fluid"
                        size="medium"
                        placeholder="Selecione"
                        noRadius
                        id="color"
                        options={[
                            { label: "Azul", value: "#0043CE" },
                            { label: "Cyan", value: "#00539A" },
                            { label: "Verde", value: theme.tagColorGreen },
                            { label: "Magenta", value: "#9F1853" },
                            { label: "Magenta", value: "#9F1853" },
                            { label: "Skeleton", value: theme.skeletonElement },
                            { label: "Teal", value: "#005D5D" },
                            { label: "Vermelho", value: "#A2191F" },
                            { label: "Gray", value: "#161616" },
                        ]}
                        onChange={(event) => {

                            setFieldValue("color", event?.target?.value)
                        }}

                        label="Cor"
                        clearFiles={clearFild}
                    />
                </div> */}
            </div>
        </Modal>
    )
}
