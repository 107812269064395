import { InlineNotification, Modal } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import useAttend from "~/hooks/useAttend";
import { getStatusAPI } from "~/services/api/status";
import { useParams } from "react-router-dom";
import {
  getAttendDetailsAPI,
  getAttendHistoryAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import { Dropdown } from "prosperita-dumbo-react";
import { getStatusFiltered } from "~/services/api/workflow";
import useWorkflow from "~/hooks/useWorkflow";
import { getNotificationsInvite, postNotificationsInvite } from "~/services/api/notifications";
import useNotification from "~/hooks/useToast";
import useUser from "~/hooks/useUser";

type Values = {
  status: string;
};

const initialValues: Values = {
  status: "",
};

const validationSchema = Yup.object({
  status: Yup.string(),
});

const UpdateStatusAttendModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();

  const {
    attendDetails,
    setStatus,
    setAttendDetails,
    setOpenUpdatedAttendStatusModal,
  } = useAttend();

  const { userProfile } = useUser()

  const {
    setInviteNotification,
    inviteNotification,
    setNotification,
    closeNotification
  } = useNotification()

  const {
    setActiveStatuses,
    activeStatuses
  } = useWorkflow()

  const { setHistory } = useAttend();

  const { id } = useParams();

  const [error, setError] = useState<boolean>(false);

  const fetchStatus = async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => { },
  });

  const statusOptions = useMemo(() => {
    return activeStatuses && activeStatuses
      ?.filter((status) => status.active)
      .map((status) => ({
        value: String(status.id),
        label: `${status.name}`,
      }));
  }, [activeStatuses]);


  const handleSelectStatus = (selectedOption: number) => {
    if (selectedOption) {
      setFieldValue("status", selectedOption);
    }
  };

  const fetchStatusWorkflow = useCallback(async () => {

    try {

      const { data } = await getStatusFiltered(Number(attendDetails?.status?.workflow))

      setActiveStatuses(data.results)

    } catch {
    }
  }, [activeStatuses])


  const fetchAttends = useCallback(async () => {

    if (id) {

      try {
        const { data } = await getAttendDetailsAPI(Number(id));
        setAttendDetails(data);
        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id, setAttendDetails, setLoading]);

  const fetchHistory = useCallback(async () => {
    try {
      const { data } = await getAttendHistoryAPI(Number(id));
      setHistory(data);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setHistory, id]);

  const fetchNotificatonFiltered = useCallback(async () => {
    try {
      const { data } = await getNotificationsInvite(Number(userProfile?.user?.id));
      setInviteNotification(data.results)
    } catch (e) {
    }
  }, [userProfile, inviteNotification])

  const fetchSendNewStatus = useCallback(async () => {
    setLoading(true);
    try {
      await patchAttendDetailsAPI(Number(id), {
        status: Number(values.status),
      });

      if (Number(values.status) === 6) {
        await postNotificationsInvite({
          attend: attendDetails?.id,
          body: "O atendimento foi cancelado",
          subject: `Aviso: Atendimento Cancelado  #${attendDetails?.id}`,
          notification_type: "App",
          type: "user_invite",
          user: attendDetails?.client?.user?.id,
        });
        await fetchNotificatonFiltered()
      }
      fetchHistory();
      fetchAttends();
      setOpenUpdatedAttendStatusModal(false);
    } catch (err: any) {
      if (
        err?.response?.data.includes("Não é permitido alterar o status de um atendimento cancelado.")) {
        setNotification({
          status: "error",
          message: "Não é permitido alterar o status de um atendimento cancelado.",
          title: "Atendimento cancelado",
          actived: true,
          onClose: () => closeNotification()
        });
      }
      console.log(err?.response?.data)
      setLoading(false);
    }
  }, [
    values.status,
    id,
    fetchHistory,
    fetchAttends,
    setOpenUpdatedAttendStatusModal,
    setLoading,
  ]);

  useEffect(() => {
    fetchStatus();
    fetchStatusWorkflow()
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        style={{ zIndex: 3 }}
        modalHeading={
          <div className="w-[100%] mx-auto">
            Atualizar status - {attendDetails?.id} {attendDetails?.type.name}
          </div>
        }
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          setError(false);
        }}
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        primaryButtonDisabled={
          values.status === "" || values.status === undefined
        }
        onRequestSubmit={async () => {
          fetchSendNewStatus();
        }}
      >
        {error && (
          <InlineNotification
            title="Erro!"
            // subtitle={error}
            subtitle="Não foi possivel criar o tipo de atendimento."
            hideCloseButton
            kind="error"
            lowContrast
          />
        )}
        <Margin mt={30} />
        {/* <Label text="Selecione um status" />
        <Select
          options={statusOptions}
          onChange={handleSelectStatus}
          placeholder="Pesquisar ou selecionar um status..."
        /> */}
        <Dropdown
          kind="default"
          size="medium"
          label="Alterar Status"
          noRadius
          placeholder="Selecionar um status"
          options={statusOptions || []}
          onChange={(event) => handleSelectStatus(Number(event.target.value))}
        />
        <Margin mb={204} />
      </Modal>
    </form>
  );
};

export default UpdateStatusAttendModal;
