import { IProfiles } from "~/types";
import ApiService from "../api";

export type IPostProfileBody = {
  name: string;
  document_number: string;
  email: string;
  password?: string;
  type: string[];
  info?: string;
  user?: number;
  beneficiary_approver?: boolean;
  payment_company_approver?: boolean;
  payment_approver?: boolean;
  company?: number;
  raw_data?: {
    first_login?: boolean;
    status?: string
  };
};

export type IPatchProfileBody = {
  name?: string;
  document_number?: string;
  email?: string;
  password?: string;
  type?: string[];
  info?: string;
  user?: {
    email?: string;
    date_joined?: string;
    first_name?: string;
    is_active?: boolean;
    last_login?: string;
    last_name?: string;
    username?: string;
  };
  beneficiary_approver?: boolean;
  payment_company_approver?: boolean;
  payment_approver?: boolean;
  company?: number;
  raw_data?: {
    first_login?: boolean;
    status?: string
  };
};

type IResponseGetProfiles = {
  count: number;
  next: null;
  previous: null;
  results: IProfiles[];
};

export const getProfilesAPI = (params?: { mode: string; company?: number }) => {
  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile/",
    token: true,
    params,
  });
};

export const getInternalApprovers = () => {
  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile/",
    token: true,
    params: {
      mode: "list",
      // type: "internal,area_manager,tool_manager",
      // payment_approver: true,
    },
  });
};

export const getFilteredProfiles = (
  name?: string,
  document_number?: string,
  period?: string,
  payment_company_approver?: boolean,
  payment_approver?: boolean,
  beneficiary_approver?: boolean,
  type?: string
) => {

  const params: {
    mode: string;
    name?: string;
    document_number?: string,
    period?: string,
    payment_company_approver?: boolean,
    payment_approver?: boolean,
    beneficiary_approver?: boolean,
    type?: string
  } = {
    mode: "list",
  };

  if (name) {
    params.name = name;
  }
  if (document_number) {
    params.document_number = document_number;
  }
  if (period) {
    params.period = period;
  }
  if (payment_company_approver) {
    params.payment_company_approver = payment_company_approver;
  }
  if (payment_approver) {
    params.payment_approver = payment_approver;
  }
  if (beneficiary_approver) {
    params.beneficiary_approver = beneficiary_approver;
  }
  if (type) {
    params.type = type
  }

  return ApiService.HttpGet<IResponseGetProfiles>({
    route: "profile/",
    token: true,
    params,
  });
};

export const getProfileAPI = (id: string) => {
  return ApiService.HttpGet<IProfiles>({
    route: `profile/${id}/`,
    token: true,
  });
};

export const PostProfileAPI = (data: IPostProfileBody) => {
  return ApiService.HttpPost<IProfiles>({
    route: "profile/",
    body: data,
    token: true,
  });
};

export const deleteProfileAPI = (id: number) => {
  return ApiService.HttpDelete({
    route: `profile/${id}/`,
    token: true,
  });
};

export const patchProfileAPI = (data: IPatchProfileBody, id: string) => {
  return ApiService.HttpPatch<IProfiles>({
    route: `profile/${id}/`,
    token: true,
    body: data,
  });
};

export const putProfileAPI = (data: unknown, id: string) => {
  return ApiService.HttpPut({
    route: `profile/${id}/`,
    token: true,
    body: data,
  });
};
