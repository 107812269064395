import { Modal, RadioButton, RadioButtonGroup, TextInput } from "@carbon/react";
import { useFormik } from "formik";
import Margin from "~/components/Margin";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import MaskedTextInput from "~/components/InputMask";
import { getFundsAPI } from "~/services/api/fund";
import { onlyNumbers } from "~/services/utils";
import useFund from "~/hooks/useFund";

import Label from "~/components/Label";

import { getCompanysAPI } from "~/services/api/companys";
import { IPostProfileBody, PostProfileAPI } from "~/services/api/profile";

import { getCompanyTypesAPI } from "~/services/api/company-types";
import useCompanyType from "~/hooks/useCompanyType";
import useCompany from "~/hooks/useCompany";
import { useParams } from "react-router-dom";

const notifySuccessProfile = () =>
  toast.success(
    "Usuário associado à empresa com sucesso! Enviaremos um email com as instruções de acesso a plataforma!"
  );
const notifyErrorProfile = () =>
  toast.error(
    "Não foi possível associar o Usuário. Tente novamente mais tarde."
  );

type Values = {
  name: string;
  document_number: string;
  brand_name: string;
  fund: string[];
  master_email: string;
  master_name: string;
  master_document_number: string;
  company_type: {
    id: number;
    name: string;
  };
};

const initialValues: Values = {
  name: "",
  brand_name: "",
  document_number: "",
  fund: [],
  master_email: "",
  master_document_number: "",
  master_name: "",
  company_type: { id: -1, name: "" },
};

const validationSchema = Yup.object({
  name: Yup.string().required("Nome da tarefa é obrigatório"),
  sort_name: Yup.string().required("Nome abreviado da tarefa é obrigatório"),
  document_number: Yup.string().required("Nome da tarefa é obrigatório"),
  fund: Yup.array().required("Fundo é obrigatório"),
});

const AddProfilesInCompany = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const { setFunds } = useFund();
  const { setCompanyTypes } = useCompanyType();
  const { id } = useParams();
  const { setOpenNewCompanyModal, setCompanys, setOpenAddProfileCompanyModal } =
    useCompany();

  const {
    handleSubmit,
    resetForm,
    setFieldValue,
    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const resetFormFields = useCallback(() => {
    resetForm(); // Reseta todos os campos do formulário
    setFieldValue("fund", []); // Limpa os fundos selecionados
    setFieldValue("company_type", {});
  }, [resetForm, setFieldValue]);

  const [selectedValue, setSelectedValue] = useState<string>("client");
  const handleRadioChange = (selection: string | number | undefined) => {
    if (typeof selection === "string") {
      setSelectedValue(selection);
      setFieldValue("type_profile", selection);
    }
  };

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();

      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchCompanyTypes = useCallback(async () => {
    try {
      const { data } = await getCompanyTypesAPI();
      setCompanyTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanyTypes]);

  const payloadCompany = useMemo(() => {
    return {
      name: values.name,
    };
  }, [values.name]);

  const fetchCompanys = useCallback(async () => {
    try {
      const { data } = await getCompanysAPI();
      setCompanys(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setCompanys]);

  const fetchSendNewProfileInCompany = useCallback(async () => {
    setLoading(true);
    setOpenAddProfileCompanyModal(false);

    try {
      const payloadMasterUser: IPostProfileBody = {
        name: values.master_name,
        document_number: onlyNumbers(values.master_document_number),
        type: [selectedValue],
        company: Number(id),
        email: values.master_email,
        raw_data: {
          first_login: true,
        },
      };

      const { data: profileData } = await PostProfileAPI(payloadMasterUser);
      console.log(profileData);

      notifySuccessProfile();

      //   fetchCompanys();
      resetForm();

      resetFormFields(); //
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
      notifyErrorProfile();
    }
  }, [
    setLoading,
    payloadCompany,
    values,
    resetForm,
    resetFormFields,
    setFieldValue,
    fetchCompanys,
    setOpenNewCompanyModal,
  ]);

  useEffect(() => {
    fetchFunds();
    fetchCompanyTypes();
  }, [fetchFunds, fetchCompanyTypes]);

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        modalHeading="Novo usuário"
        size="sm"
        open={open}
        onRequestClose={() => {
          onClose();
          resetForm();
          setFieldValue("fund", []);
          resetFormFields();
        }}
        primaryButtonDisabled={
          onlyNumbers(values.master_document_number).length < 11 ||
          values.master_email === "" ||
          values.master_name === ""
        }
        primaryButtonText="Concluir"
        secondaryButtonText="Cancelar"
        onRequestSubmit={async () => {
          fetchSendNewProfileInCompany();
        }}
      >
        <Margin mt={20} />
        <Label text="Tipo de usuário" />
        <div>
          <RadioButtonGroup
            name="radio-button-group"
            valueSelected={selectedValue}
            onChange={handleRadioChange}
          >
            <RadioButton id="option1" labelText="Comum" value="client" />
            <RadioButton id="option2" labelText="Master" value="manager" />
          </RadioButtonGroup>
        </div>
        <Margin mb={30} />
        <Margin mb={10} />
        <TextInput
          id="master_name"
          name="master_name"
          labelText="Nome completo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.master_name}
          placeholder="Ex: Jhon Doe"
        />
        <Margin mb={20} />
        <p style={{ fontSize: "12px", color: "black", fontWeight: "300" }}>
          {" "}
          CPF
        </p>
        <MaskedTextInput
          mask="999.999.999-99"
          id="master_document_number"
          onChange={handleChange}
          name="master_document_number"
          placeholder="000.000.000-00"
          value={values.master_document_number}
        />
        <Margin mb={15} />
        <TextInput
          id="master_email"
          name="master_email"
          labelText="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.master_email}
          placeholder="seuemail@email.com"
        />
        <Margin mb={104} />
      </Modal>
    </form>
  );
};

export default AddProfilesInCompany;
