import { IAttend, IHistory } from "~/types";
import ApiService from "../api";

type IResponseAttend = {
  results: IAttend[];
};

const expand = "status,type,fund,client,owner,area";
const expand_id = "status,type,fund,client,owner,area";

export const getAttendAPI = () => {
  return ApiService.HttpGet<IResponseAttend>({
    route: "attend/attend/",
    token: true,
    params: {
      expand,
    },
  });
};
export const getFilteredAttend = (
  id: string,
  client_id?: string,
  owner_id?: string,
  fund_id?: string,
  sla?: string,
  type_id?: string
) => {
  const expand = "status,type,fund,client,owner,area";
  const params: {
    expand: string;
    id?: string;
    client_id?: string;
    owner_id?: string;
    fund_id?: string;
    sla?: string;
    type_id?: string;
  } = {
    expand,
  };

  if (id) params.id = id;
  if (client_id) params.client_id = client_id;
  if (owner_id) params.owner_id = owner_id;
  if (fund_id) params.fund_id = fund_id;
  if (sla) params.sla = sla;
  if (type_id) params.type_id = type_id;

  return ApiService.HttpGet<IResponseAttend>({
    route: "attend/attend/",
    token: true,
    params,
  });
};



export const getAttendDetailsAPI = (id: number) => {
  return ApiService.HttpGet<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    params: {
      expand: expand_id,
      origin: "payment",
    },
  });
};

export const getAttendDetailsforumAPI = (id: number) => {
  return ApiService.HttpGet<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    params: {
      expand: expand_id,
    },
  });
};

export const getAttendHistoryAPI = (id: number) => {
  return ApiService.HttpGet<IHistory[]>({
    route: `history/`,
    token: true,
    params: {
      attend: id,
    },
  });
};

export const postAttendHistoryAPI = (body: unknown) => {
  return ApiService.HttpPost<unknown>({
    route: `history/`,
    body,
    token: true,
  });
};

export type ISendUpdatePatchBody = {
  status?: number;
  owner?: number | null;
  type?: number;
  in_client_revision?: boolean;
  opened_on?: string
};

export const patchAttendDetailsAPI = (
  id: number,
  body: ISendUpdatePatchBody
) => {
  return ApiService.HttpPatch<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
    body: body,
  });
};

export const postUpdateStatusAPI = () => {
  return ApiService.HttpPost<unknown>({
    route: `attend/attend/update_status/`,
    token: true,
  });
};

export type ISendNewAttendBody = {
  message: string;
  type?: number;
  fund: number | null;
  client: number;
  owner?: number;
  area?: number;
  parent?: number;
  status?: number
};

export const postAttendAPI = (data: ISendNewAttendBody) => {
  return ApiService.HttpPost<IAttend>({
    route: "attend/attend/",
    body: data,
    token: true,
  });
};

export const deleteAttendDetailsAPI = (id: number) => {
  return ApiService.HttpDelete<IAttend>({
    route: `attend/attend/${id}/`,
    token: true,
  });
};
