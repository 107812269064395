import { useFormik } from "formik";
import { Modal } from "prosperita-dumbo-react"
import { useCallback, useEffect, useMemo } from "react";
import Label from "~/components/Label";
import Margin from "~/components/Margin";
import useCompany from "~/hooks/useCompany";
import useLoading from "~/hooks/useLoading";
import useProfile from "~/hooks/useProfile";
import { getCompanyAPI, getCompanysAPI, updateCompanyAPI } from "~/services/api/companys";
import { getProfilesAPI, patchProfileAPI } from "~/services/api/profile";
import Select, { SingleValue } from "react-select";

type Imodal = {
    open: boolean;
    onClose?: () => void;
};

type Ivalues = {
    master_user: {
        id: string;
        full_name: string;
        doc_number: string;
        type_profile: string;
    }
};

const initialValues: Ivalues = {
    master_user: {
        id: "",
        full_name: "",
        doc_number: "",
        type_profile: "",
    }
};

type OptionType = {
    value: {
        id: number;
        full_name: string;
        doc_number: string;
        type_profile: string;
    };
    label: string;
};

export const ModalSelectUserMaster = ({ open, onClose }: Imodal) => {

    const { setLoading } = useLoading();
    const {
        companyDetails,
        setOpenDeleteCompanyModal,
        setCompanys,
        setCompanyDetails
    } = useCompany();

    const {
        setProfiles,
        profiles,
    } = useProfile();

    const {
        setFieldValue,
        values,
        resetForm
    } = useFormik({
        initialValues,
        onSubmit: () => {
        },
    });

    const fetchCompanys = useCallback(async () => {
        try {
            const { data } = await getCompanysAPI();
            setCompanys(data.results.reverse());
        } catch (err: unknown) {
            console.error(err);
        }
    }, [setCompanys]);

    const fetchProfiles = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await getProfilesAPI({ mode: "list" });
            setProfiles(data.results.reverse());
            setLoading(false);
        } catch (err: unknown) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchCompany = useCallback(async () => {
        try {
            const { data } = await getCompanyAPI(Number(companyDetails?.id));
            setCompanyDetails(data);
        } catch (err: unknown) {
            console.error(err);
        }
    }, [companyDetails?.id, setCompanyDetails]);

    const handleSelectProfiles = (
        newValue: SingleValue<OptionType>
    ) => {
        if (newValue) {
            setFieldValue("master_user", newValue.value);
        } else {
            setFieldValue("master_user", {});
        }
    };

    const profilesList = useMemo(() => {
        return profiles.filter((profiles) => profiles.raw_data?.status === "active")
            ?.map((profiles) => ({
                value: {
                    id: profiles.id,
                    full_name: profiles.name,
                    doc_number: profiles.document_number,
                    type_profile: profiles.type
                },
                label: profiles.name,
            }))
            .sort((a, b) =>
                a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
            );
    }, [profiles]);

    const fetchUpdateCompany = useCallback(
        async () => {
            setLoading(true);
            try {
                await updateCompanyAPI(
                    {
                        raw_data: {
                            active: !companyDetails?.raw_data?.active,
                            brand_name: companyDetails?.raw_data?.brand_name,
                            company_type: companyDetails?.raw_data?.company_type,
                            document_number: companyDetails?.raw_data?.document_number,
                            master_user: {
                                document_number: values?.master_user?.doc_number,
                                full_name: values?.master_user?.full_name,
                                id: Number(values.master_user.id),
                            },
                        },
                    },
                    String(companyDetails?.id)
                );
                await patchProfileAPI(
                    {
                        company: companyDetails?.id
                    },
                    values.master_user.id
                )
                setOpenDeleteCompanyModal(false);
                setLoading(false);
            } catch (err: unknown) {
                console.error(err);
                setLoading(false);
            } finally {
                fetchCompanys()
                fetchCompany()
                onClose && onClose();
            }
        },
        [setLoading, companyDetails, values]);

    useEffect(() => {
        fetchCompanys()
        fetchProfiles()
    }, [])

    return (
        <div>
            <Modal
                onClose={() => {
                    onClose && onClose();
                    resetForm()
                }}
                open={open}
                height="590px"
                width="700px"
                overflow
                label={
                    <>
                        Editar Usuario Master
                    </>
                }
                background="#f4f4f4"
                buttons={[
                    {
                        label: "Não, manter como está",
                        kind: "ghost",
                        dimension: "74",
                        onClick: () => {
                            resetForm()
                            onClose && onClose();
                        },
                    },
                    {
                        label: "Sim , Editar Usuario Master",
                        icon: "",
                        dimension: "74",
                        kind: "primary",
                        onClick: () => { fetchUpdateCompany() },
                        disabled: values.master_user.full_name === "",
                    },
                ]}
            >
                <div className="w-[96%] mx-auto">
                    <div>
                        <Margin mb={10} />
                        <h4>Usuario</h4>
                        <Margin mb={10} />
                        <Label text="Selecione ou pesquise um usuário para ser o master." />
                        <Select
                            options={profilesList}
                            onChange={handleSelectProfiles}
                            placeholder="Pesquisar Usuario"
                            isMulti={false}
                        />
                    </div>
                </div>
            </Modal>
        </div >
    )
}
