import renewIcon from "~/assets/Renew.png";
import editIcon from "~/assets/editicon.png";
import addIcon from "~/assets/iconadd.png";
import DetailsIcon from "~/assets/View.png";
import DeleteIcon from "~/assets/Trash-can.png";
import Margin from "~/components/Margin";

import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getAreasAPI } from "~/services/api/areas";
import useArea from "~/hooks/useArea";
import NewAreaContentModal from "./NewAreaContentModal";
import DeleteAreaModal from "./DeleteAreaModal";
import { useNavigate } from "react-router-dom";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";

import { getAreaTypesAPI } from "~/services/api/areaTypes";
import useAreaType from "~/hooks/useAreaType";
import EditAreaModal from "./EditAreaModal";
import { Breadcrumb, Button, TableBasic, Tag, Tooltip } from "prosperita-dumbo-react";

const Areas = () => {
  const { setLoading } = useLoading();

  const {
    setAreas,
    areas,
    setOpenDeleteAreaModal,
    openDeleteAreaModal,
    setAreaDetails,
    setAttendAreaProfiles,
    setOpenEditAreaModal,
    openEditAreaModal,
  } = useArea();

  const { setAreaTypes } = useAreaType();

  const navigate = useNavigate();

  const [openNewAreaModal, setOpenNewAreaModal] = useState<boolean>(false);

  // const { values } = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values: { search: string }) => {
  //     console.log(values);
  //   },
  // });

  const dataAttendTypes = useMemo(() => {
    return areas?.map((area, index) => {
      return {
        "Nome": area.name,
        "Status": area.active ? (
          <div className="w-[80px] m-0 p-0">
            <Tag type="green" position="center">
              <p className="text-[14px] m-0 p-2">Ativa</p>
            </Tag>
          </div>
        ) : area?.active === false ? (
          <div className="w-[80px]  m-0 p-0">
            <Tag type="red" position="center">
              <p className="text-[14px] m-0 p-[10px]">Inativa</p>
            </Tag>
          </div>
        ) : (
          ""
        ),
        edit: (
          <div style={{ display: "flex", gap: "20px" }}>
            <Tooltip align="top" label="Visualizar detalhes" size="large">
              <div key={index} className="m-0 p-0">
                <Button
                  label=""
                  kind="ghost"
                  size="medium"
                  icon={DetailsIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/app/areas/${area.id}/`);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip align="top" label="Editar" size="large">
              <div key={index} className="m-0 p-0">
                <Button
                  label=""
                  kind="ghost"
                  size="medium"
                  icon={editIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenEditAreaModal(true);
                    setAreaDetails(area);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip align="top" label="Excluir" size="large">
              <div key={index} className="m-0 p-0">
                <Button
                  label=""
                  kind="ghost"
                  size="medium"
                  icon={DeleteIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeleteAreaModal(true);
                    setAreaDetails(area);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        ),
      };
    });
  }, [areas]);

  const tableHeadersProfile: string[] = ["Nome", "Status", ""];

  const fetchAreas = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [setAreas, setLoading]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  useEffect(() => {
    fetchAreas();
    fetchAttendAreaProfiles();
    fetchAreaTypes();
  }, [fetchAreas, fetchAreaTypes, fetchAttendAreaProfiles]);

  return (
    <div>
      <div className="w-full mx-auto">
        <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
          <div className="flex flex-col">
            <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
              <Breadcrumb
                crumbsOptions={[
                  { crumb: "Configurações", onClick: () => navigate("/app") },
                  { crumb: "Áreas" },
                ]}
                endHiddenIndex={5}
                startHiddenIndex={5}
              />
            </div>
            <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
              Áreas
            </h2>
          </div>
        </div>
      </div>
      <Margin mt={64} />
      <div className="w-[95%] mx-auto">
        <TableBasic
          search
          buttons={[
            {
              kind: "ghost",
              label: "",
              dimension: "37",
              width: "37px",
              onClick: () => fetchAreas(),
              position: "right",
              icon: renewIcon,
            },

            {
              kind: "primary",
              label: "Nova àrea",
              dimension: "",
              onClick: () => setOpenNewAreaModal(true),
              position: "right",
              icon: addIcon,
            },
          ]}
          pageSizes={[10, 5, 20, 30, 40, 50]}
          rowData={dataAttendTypes}
          columnTitles={tableHeadersProfile}
        />
      </div>
      <NewAreaContentModal
        onClose={() => setOpenNewAreaModal(false)}
        open={openNewAreaModal}
      />
      <EditAreaModal
        onClose={() => setOpenEditAreaModal(false)}
        open={openEditAreaModal}
      />
      <DeleteAreaModal
        onClose={() => setOpenDeleteAreaModal(false)}
        open={openDeleteAreaModal}
      />
    </div>
  );
};

export default Areas;
