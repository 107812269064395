import {
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Grid,
  Column,
  Button as ButtonCarbon,
  // Tile,
  Pagination,
} from "@carbon/react";

import { TrashCan, Add, Save, Download, Renew, Filter } from "@carbon/icons-react";
import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useLoading from "~/hooks/useLoading";
import { getAttendAPI, getFilteredAttend } from "~/services/api/attend";
import Padding from "~/components/Padding";
// import FiltersModal from "./FiltersModal";
import useAttend from "~/hooks/useAttend";
// import styled from "styled-components";
import Badge from "~/components/Badge";
import { permissions } from "~/services/permissions";
import { checkDueDate, formatCPFCNPJ } from "~/services/utils";
//calculateSla
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import useStatus from "~/hooks/useStatus";
import { getStatusAPI } from "~/services/api/status";
import useUser from "~/hooks/useUser";
import { Breadcrumb, Button, Dropdown, InputFluid, Tag } from "prosperita-dumbo-react";
import { useFormik } from "formik";
import { getFundsAPI } from "~/services/api/fund";
import { IFund } from "~/types";
import useFund from "~/hooks/useFund";
import { getProfilesAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import useAreaType from "~/hooks/useAreaType";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import styled from "styled-components";
import lockIcon from "~/assets/lock.png";
import { Loading } from "carbon-components-react";

// const TileContainer = styled.div`
//   display: flex;
//   gap: 15px;
// `;

const tableHeadersAttend: string[] = [
  "Nº",
  "Status",
  "Solicitante",
  "Fundo",
  "Tipo",
  "Atendente",
  "SLA",
  // "Criado em",
];

const Container = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  width: 100%;
`;
const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;
const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;
const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const Attends = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  //status: statusData
  const { setStatus } = useStatus();
  const { attends, setAttends } = useAttend();
  const { userProfile } = useUser();

  const { setFunds, funds } = useFund();
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilteredOn, setIsFilteredOn] = useState<boolean>(false);
  const { setAreaTypes, areaTypes } = useAreaType();
  const [clearInputs, setClearInputs] = useState<boolean>(false);

  const [itemsPerPage, setItemsPerPage] = useState(20);

  const canReadAttends = permissions.includes("can_read_attends");
  const canCreatAttends = permissions.includes("can_create_attends");

  const {
    setProfiles,
    profiles,
  } = useProfile();

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      id: "",
      client: "",
      owner: "",
      fund: "",
      sla: "",
      type_id: ""
    },
    onSubmit: () => { }
  })

  const paginatedAttends = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return attends?.slice(start, end);
  }, [currentPage, itemsPerPage, attends]);


  const paginatedAttendsByStatus = (statusName: string) => {
    const filteredAttends = attends?.filter((attend) => {
      if (statusName === "Alocado") {
        return (
          attend.status.name === statusName ||
          (attend.status.id === 3 && attend.client.id === userProfile?.id)
        );
      }

      return (
        attend.status.name === statusName &&
        !(attend.status.id === 3 && attend.client.id === userProfile?.id)
      );
    });

    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return filteredAttends?.slice(start, end);
  };

  // const paginatedAttendsByClient = (statusName: string) => {
  //   const filteredAttends = attends.filter(
  //     (attend) =>
  //       attend.status.name === statusName ||
  //       (attend.status.id === 3 && statusName === "Alocado")
  //   );

  //   const start = (currentPage - 1) * itemsPerPage;
  //   const end = start + itemsPerPage;

  //   return filteredAttends.slice(start, end);
  // };

  const fetchAttends = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getAttendAPI();
      setAttends(data.results.reverse().sort((a, b) => b.id - a.id));
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false)
    }
  }, []);


  const fetchAttendsFiltered = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getFilteredAttend(
        values.id,
        values.client,
        values.owner,
        values.fund,
        values.sla,
        values.type_id,
      )
      setAttends(data.results.reverse().sort((a, b) => b.id - a.id));
      setLoading(false);
    } catch (err: unknown) {

      setLoading(false);
    }
  }, [setAttends, setLoading, values]);

  const fetchFunds = useCallback(async () => {
    try {
      const { data } = await getFundsAPI();
      setFunds(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setFunds]);

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();
      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);


  const areaTypesOptions = useMemo(() => {
    const options = areaTypes
      .filter((item) => {
        return item.area.active && item.type.active;
      })
      .map((areaType) => ({
        value: String(areaType.type.id),
        label: `${areaType.type.name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    const uniqueOptions = Array.from(
      new Map(options.map((option) => [option.value, option])).values()
    );

    return uniqueOptions;
  }, [areaTypes]);


  const fetchProfiles = useCallback(async () => {

    setLoading(true);
    try {

      const { data } = await getProfilesAPI({ mode: "list" });

      setProfiles(data.results.reverse());

      setLoading(false);

    } catch (err: unknown) {

      console.error(err);

    } finally {

      setLoading(false);
    }
  }, []);


  const filteredFunds = funds.filter((fund) => fund.raw_data.active === true);

  const fundsOptions = useMemo(() => {
    return filteredFunds
      .map((fund: IFund) => ({
        value: String(fund.id),
        label: `${fund?.raw_data?.short_name?.trim()} - ${formatCPFCNPJ(fund?.raw_data?.document_number).trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [filteredFunds]);

  const profilesOptions = useMemo(() => {
    return profiles
      .map((profile) => ({
        value: String(profile.id),
        label: `${profile?.name?.trim()}`,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [profiles]);


  const fetchStatus = useCallback(async () => {
    try {
      const { data } = await getStatusAPI();
      setStatus(data.results.sort((a, b) => a.name.localeCompare(b.name)));

      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAttends();
    fetchStatus();
  }, [fetchAttends]);


  useEffect(() => {
    fetchFunds();
    fetchProfiles()
    fetchAreaTypes()
  }, []);


  useEffect(() => {
    if (clearInputs) {
      setClearInputs(false);
    }
  }, [values, clearInputs]);

  const uniqueStatuses = [...new Set(attends?.map((status) => status.status.name))];


  return (
    <div className="w-[100%] m-0 p-0">
      {!!attends ?
        <>
          {userProfile?.raw_data?.status === "inactive" ?
            <>
              <div>
                <div className="bg-[#f4f4f4] h-[160px] m-0 w-full">
                  <div className="flex flex-col">
                    <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                      <Breadcrumb
                        crumbsOptions={[
                          { crumb: "Painel", onClick: () => navigate("/") },
                          { crumb: "Atendimentos" },
                        ]}
                        endHiddenIndex={5}
                        startHiddenIndex={5}
                      />
                    </div>
                    <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                      Atendimentos
                    </h2>
                  </div>
                </div>
              </div>
              <div className="w-[100%] m-0 p-0">
                <Container>
                  <div
                    style={{
                      width: "320px",
                      marginTop: "40px",
                    }}
                  ></div>
                </Container>
                <div style={{ width: "96%", margin: "0 auto" }}>
                  <TagLup src={lockIcon} />
                  <Text>Acesso restrito</Text>
                  <Subtext>
                    Você não tem permissão para ver o painel de Atendimentos.
                  </Subtext>
                </div>
              </div>
            </>
            :
            <Grid>
              <Column span={16}>
                <Margin mt={64} /> <h1>Atendimentos</h1>
                <Margin mt={34} />{" "}
                <Margin mt={20} />
                <Tabs
                  defaultActiveKey="1"
                  tabBarStyle={{
                    margin: 0,
                    borderBottom: "none",
                  }}
                >
                  <TabPane tab="Todos" key="0">
                    <div className="m-0">
                      <Margin mt={20} />
                      {isFilteredOn && (
                        <div
                          style={{ borderRadius: "8px 8px 0px 0" }}
                          className="bg-[#f4f4f4] w-[100%] mx-auto mt-[20px] p-2"
                        >
                          <div className="d-flex justify-between py-3 w-[97%] mx-auto">
                            <h3 className="m-0 p-0">Filtros</h3>
                            <div className="flex pt-1 w-[300px] justify-around box-border ">
                              <div className="flex w-[100%]">
                              </div>
                              <div className="w-[10%] box-border align-end">
                                <Button
                                  kind="ghost"
                                  size="small"
                                  borderNone
                                  width="100%"
                                  onClick={() => setIsFilteredOn(false)}
                                />
                              </div>
                            </div>
                          </div>
                          <Margin mt={20} />
                          <div className="w-[98%] flex gap-[5px] mx-auto mb-[20px]"
                            style={{ borderBottom: "1px solid #8D8D8D" }}>
                            <Dropdown
                              kind="fluid"
                              size="large"
                              autoComplete="off"
                              options={fundsOptions}
                              label="Fundo"
                              message=""
                              type="text"
                              name="name"
                              placeholder="Selecione um fundo"
                              clearFiles={clearInputs}
                              onChange={(event) => setFieldValue("fund", event.target.value)}
                              noRadius
                              borderNone
                            />
                            <InputFluid
                              autoComplete="off"
                              label="ID"
                              message=""
                              type="text"
                              name="name"
                              maxLength={5}
                              placeholder="ID do atendimento"
                              value={values.id}
                              onChange={(event) => setFieldValue("id", event.target.value)}
                              noRadius
                              borderNone
                            />
                          </div>
                          <div className="w-[98%] flex mx-auto gap-[5px]"
                            style={{ borderBottom: "1px solid #8D8D8D" }}>
                            <section className="flex flex-col w-[100%]">
                              <div className="w-[100%] flex gap-[5px]">
                                <Dropdown
                                  kind="fluid"
                                  size="large"
                                  autoComplete="off"
                                  label="Solicitante"
                                  options={profilesOptions}
                                  message=""
                                  type="text"
                                  name="client"
                                  placeholder="Selecione "
                                  clearFiles={clearInputs}
                                  onChange={(event) => setFieldValue("client", event.target.value)}
                                  noRadius
                                  borderNone
                                />
                              </div>
                            </section>
                            <section className="flex flex-col w-[100%]">
                              <div className="w-[100%] flex gap-[5px]">
                                <Dropdown
                                  kind="fluid"
                                  size="large"
                                  autoComplete="off"
                                  options={profilesOptions}
                                  label="Responsável"
                                  message=""
                                  clearFiles={clearInputs}
                                  type="text"
                                  name="owner"
                                  placeholder="Selecione "
                                  onChange={(event) => setFieldValue("owner", event.target.value)}
                                  noRadius
                                  borderNone
                                />
                              </div>
                            </section>
                            <section className="flex flex-col w-[100%]">
                              <div className="w-[100%] flex gap-[5px]">
                                <Dropdown
                                  kind="fluid"
                                  size="large"
                                  autoComplete="off"
                                  options={areaTypesOptions}
                                  label="Tipo de atendimento"
                                  message=""
                                  type="text"
                                  clearFiles={clearInputs}
                                  name="type_id"
                                  placeholder="Selecione"
                                  onChange={(event) => setFieldValue("type_id", event.target.value)}
                                  noRadius
                                  borderNone
                                />
                              </div>
                            </section>
                          </div>
                          <Margin mt={30} />
                          <div className="flex justify-between w-[97%] mx-auto align-center items-center">
                            <div className="m-0 p-0">
                              <p className="m-0 p-0"><b className="m-0 p-0">SLA</b></p>
                              <Margin mt={20} />
                              <div className="flex gap-[5px] m-0 p-0" >
                                <Dropdown
                                  kind="fluid"
                                  size="large"
                                  autoComplete="off"
                                  options={[
                                    {
                                      label: "No Prazo",
                                      value: "no_prazo"
                                    },
                                    {
                                      label: "Atrasado",
                                      value: "atrasado"
                                    }
                                  ]}
                                  label="SLA"
                                  message=""
                                  type="text"
                                  name="name"
                                  clearFiles={clearInputs}
                                  placeholder="Selecione"
                                  onChange={(event) => setFieldValue("sla", event.target.value)}
                                  noRadius
                                  borderNone
                                />
                              </div>
                            </div>
                            <div className="flex gap-[20px] m-0 p-0">
                              <Button
                                label="Limpar campos"
                                kind="ghost"
                                size="large"
                                onClick={() => {
                                  resetForm()
                                  setClearInputs(true)
                                }}
                              />
                              <Button
                                label="Aplicar filtros"
                                kind="tertiary"
                                size="large"
                                onClick={() => fetchAttendsFiltered()}
                              />
                            </div>
                          </div>
                          <Padding pb={20} />
                        </div>
                      )}
                    </div>
                    <TableContainer>
                      <TableToolbar>
                        {canReadAttends && (
                          <ButtonCarbon
                            tabIndex={0}
                            onClick={() => {
                              fetchAttends();
                            }}
                            renderIcon={Renew}
                            kind="secondary"
                          >
                            Atualizar
                          </ButtonCarbon>
                        )}
                        {!canReadAttends && (
                          <div
                            style={{
                              height: "400px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <h4 style={{ position: "relative", left: "45%", margin: "0", padding: "0" }}>
                              Você não possui permissão para visualizar os atendimentos
                            </h4>
                          </div>
                        )}
                        <TableBatchActions onCancel={console.log} totalSelected={0}>
                          <TableBatchAction
                            tabIndex={0}
                            renderIcon={TrashCan}
                            onClick={console.log}
                          >
                            Delete
                          </TableBatchAction>
                          <TableBatchAction
                            hasIconOnly
                            iconDescription="Add"
                            tabIndex={0}
                            renderIcon={Add}
                            onClick={console.log}
                          >
                            Delete
                          </TableBatchAction>
                          <TableBatchAction
                            hasIconOnly
                            iconDescription="Save"
                            tabIndex={0}
                            renderIcon={Save}
                            onClick={console.log}
                          >
                            Save
                          </TableBatchAction>
                          <TableBatchAction
                            tabIndex={0}
                            renderIcon={Download}
                            onClick={console.log}
                          >
                            Download
                          </TableBatchAction>
                        </TableBatchActions>
                        <TableToolbarContent>
                          {canCreatAttends && (
                            <ButtonCarbon
                              tabIndex={0}
                              onClick={() => {
                                navigate("/app/attends/new");
                              }}
                              kind="primary"
                              disabled={userProfile?.raw_data?.status === "inactive"}
                            >
                              Novo atendimento
                            </ButtonCarbon>
                          )}
                          <ButtonCarbon
                            tabIndex={0}
                            onClick={() => setIsFilteredOn((prev) => !prev)}
                            renderIcon={Filter}
                            kind="secondary"
                            hasIconOnly
                            disabled={userProfile?.raw_data?.status === "inactive"}
                            iconDescription=""

                          >
                          </ButtonCarbon>
                        </TableToolbarContent>
                      </TableToolbar>
                      {canReadAttends && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              {tableHeadersAttend.map((columnTitle, index) => {
                                return (
                                  <TableHeader key={String(index)}>
                                    {columnTitle}
                                  </TableHeader>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedAttends?.length === 0 ? (
                              <TableCell colSpan={tableHeadersAttend.length + 1}>
                                <Padding pt={24} pb={24} pl={12}>
                                  <div
                                    style={{
                                      height: "300px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h4>Nenhum atendimento até o momento.</h4>
                                  </div>
                                </Padding>
                              </TableCell>
                            ) : paginatedAttends && paginatedAttends?.length > 0 ? (
                              paginatedAttends?.map((attend, index) => (
                                <TableRow
                                  key={String(index)}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate(`/app/attends/${attend.id}`)}
                                >
                                  {/* <TableSelectRow
                      name={""}
                      id={""}
                      onSelect={console.log}
                      checked={false}
                    /> */}
                                  <TableCell>#{attend.id}</TableCell>
                                  <TableCell>
                                    {attend?.status.name ? (
                                      <div>
                                        {userProfile?.type.includes("client") ||
                                          userProfile?.type.includes("manager") ? (
                                          <Badge
                                            label={
                                              attend.status.id === 3
                                                ? "Alocado"
                                                : attend.status.name
                                            }
                                            color={
                                              attend.status.id === 3
                                                ? "#e3e3e3"
                                                : attend.status?.color
                                            }
                                            textColor="#000000"
                                          />
                                        ) : attend.client.id === userProfile?.id &&
                                          attend.status.id === 3 ? (
                                          <Badge
                                            label={"Alocado"}
                                            color={"#e3e3e3"}
                                            textColor="#000000"
                                          />
                                        ) : (
                                          <Badge
                                            label={attend?.status.name}
                                            color={attend?.status.color}
                                            textColor="#000000"
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      "Não encontrado"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <p style={{ fontWeight: "600" }}>
                                      {" "}
                                      {attend?.client?.company?.name
                                        ? attend?.client?.company?.name
                                        : !attend?.client?.company?.name &&
                                        !attend?.client?.type.includes("manager") &&
                                        !attend?.client?.type.includes("client") &&
                                        "BANVOX"}
                                    </p>
                                    <Margin mt={-15} />
                                    <p>
                                      <b>{attend.client?.name}</b>
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    {attend?.fund?.raw_data?.short_name ?? "-"}
                                  </TableCell>
                                  <TableCell>{attend?.type?.name}</TableCell>
                                  <TableCell>{attend?.owner?.name ?? "-"}</TableCell>
                                  <TableCell>
                                    <div>
                                      {attend.sla_deadline && (
                                        <>
                                          <div className="w-[100px] my-[7px]">
                                            <Tag position="center"
                                              type={checkDueDate(new Date(String(attend.sla_deadline))).status
                                                === "No prazo" ? "green" : "red"}>
                                              <p className="p-[7px] m-0">
                                                {checkDueDate(new Date(String(attend.sla_deadline))).status}
                                              </p>
                                            </Tag>
                                          </div>
                                          <div className="my-[7px]">
                                            {checkDueDate(new Date(String(attend.sla_deadline))).status
                                              === "No prazo" ? checkDueDate(new Date(String(attend.sla_deadline))).hora :
                                              `- ${checkDueDate(new Date(String(attend.sla_deadline))).hora}`
                                            }
                                          </div>
                                        </>
                                      )}
                                      {
                                        attend?.sla_paused_on && !attend?.sla_deadline && (
                                          <div className="mx-auto text-center">
                                            <div className="w-[100px] my-[7px]">
                                              <Tag position="center"
                                                type={"blue"}>
                                                <p className="p-[7px] m-0">
                                                  Pausado
                                                </p>
                                              </Tag>
                                            </div>
                                            <p className="mx-auto text-center">
                                              {checkDueDate(new Date(String(attend?.sla_paused_on))).hora}
                                            </p>
                                          </div>
                                        )
                                      }
                                      {
                                        !attend?.sla_paused_on && !attend?.sla_deadline && (
                                          <div className="mx-auto text-center">
                                            {/* <div className="w-[100px] my-[7px]">
                                          <Tag position="center"
                                            type={"red"}>
                                            <p className="p-[7px] m-0">
                                              -
                                            </p>
                                          </Tag>
                                        </div> */}
                                            -----
                                          </div>
                                        )
                                      }
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              paginatedAttends?.map((attend, index) => (
                                <TableRow
                                  key={String(index)}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigate(`/app/attends/${attend.id}`)}
                                >
                                  <TableCell>#{attend.id}</TableCell>
                                  <TableCell>
                                    {attend?.status.name ? (
                                      <Badge
                                        label={attend.status.name}
                                        color={attend.status?.color}
                                        textColor="#000000"
                                      />
                                    ) : (
                                      "Não encontrado"
                                    )}
                                  </TableCell>
                                  {/* <TableCell>{attend.area}</TableCell>
                    <TableCell>{attend.area_manager}</TableCell> */}
                                  <TableCell>
                                    <p>
                                      {" "}
                                      <b>{attend.client.name}</b>
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    {attend?.fund?.raw_data?.short_name ?? "-"}
                                  </TableCell>
                                  <TableCell>{attend?.type?.name}</TableCell>

                                  <TableCell>{attend?.owner?.name ?? "-"}</TableCell>

                                  <TableCell>{attend?.type?.sla}h</TableCell>
                                  {/* <TableCell>
                        {format(attend.created_on, "dd-MM-yyyy")}
                      </TableCell> */}
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      )}
                      <Pagination
                        totalItems={attends?.length}
                        pageSize={itemsPerPage}
                        pageSizes={[5, 10, 20, 30, 40, 50]}
                        onChange={({ page, pageSize }) => {
                          setCurrentPage(page);
                          setItemsPerPage(pageSize);
                        }}
                        page={currentPage}
                        size="md"
                      />
                    </TableContainer>
                  </TabPane>
                  {(userProfile?.type.includes("tool_manager") ||
                    userProfile?.type.includes("area_manager") ||
                    userProfile?.type.includes("internal") ||
                    userProfile?.type.includes("client") ||
                    userProfile?.type.includes("manager")) &&
                    uniqueStatuses
                      .map((status) => {
                        const paginatedAttends = paginatedAttendsByStatus(status);
                        return (
                          <TabPane tab={status} key={status}>
                            <TableContainer>
                              <Margin mb={20} />
                              <TableToolbar>
                                {canReadAttends && (
                                  <ButtonCarbon
                                    tabIndex={0}
                                    onClick={() => fetchAttends()}
                                    renderIcon={Renew}
                                    kind="secondary"
                                  >
                                    Atualizar
                                  </ButtonCarbon>
                                )}
                                {!canReadAttends && (
                                  <div
                                    style={{
                                      height: "400px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <h4 style={{ position: "relative", left: "45%" }}>
                                      Você não possui permissão para visualizar os
                                      atendimentos
                                    </h4>
                                  </div>
                                )}
                                {/* ... Resto do código do TableToolbar ... */}
                              </TableToolbar>
                              {canReadAttends && (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {tableHeadersAttend.map((columnTitle, index) => (
                                        <TableHeader key={String(index)}>
                                          {columnTitle}
                                        </TableHeader>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {paginatedAttends?.length === 0 ? (
                                      <TableCell
                                        colSpan={tableHeadersAttend.length + 1}
                                      >
                                        <Padding pt={24} pb={24} pl={12}>
                                          <div
                                            style={{
                                              height: "300px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <h4>
                                              Nenhum atendimento com esse status até o
                                              momento.
                                            </h4>
                                          </div>
                                        </Padding>
                                      </TableCell>
                                    ) : (
                                      paginatedAttends?.map((attend, index) => (
                                        <TableRow
                                          key={String(index)}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(`/app/attends/${attend.id}`)
                                          }
                                        >
                                          <TableCell>#{attend.id}</TableCell>
                                          <TableCell>
                                            {attend.client.id === userProfile?.id &&
                                              attend.status.id === 3 ? (
                                              <Badge
                                                label={"Alocado"}
                                                color={"#e3e3e3"}
                                                textColor="#000000"
                                              />
                                            ) : (
                                              <Badge
                                                label={attend?.status.name}
                                                color={attend?.status.color}
                                                textColor="#000000"
                                              />
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            <p style={{ fontWeight: "600" }}>
                                              {" "}
                                              {attend?.client?.company?.name
                                                ? attend?.client?.company?.name
                                                : !attend?.client?.company?.name &&
                                                !attend?.client?.type.includes(
                                                  "manager"
                                                ) &&
                                                !attend?.client?.type.includes(
                                                  "client"
                                                ) &&
                                                "BANVOX"}
                                            </p>
                                            <Margin mt={-15} />
                                            <p>
                                              <b>{attend.client?.name}</b>
                                            </p>
                                          </TableCell>
                                          <TableCell>
                                            {attend?.fund?.raw_data?.short_name ?? "-"}
                                          </TableCell>
                                          <TableCell>{attend?.type?.name}</TableCell>

                                          <TableCell>
                                            {attend?.owner?.name ?? "-"}
                                          </TableCell>

                                          <TableCell>
                                            <div
                                              style={{
                                                width: "100%",
                                                minWidth: "90px",
                                              }}
                                            >
                                              {attend.sla_deadline && (
                                                <>
                                                  <div className="w-[100px] my-[7px]">
                                                    <Tag position="center"
                                                      type={checkDueDate(new Date(String(attend.sla_deadline))).status
                                                        === "No prazo" ? "green" : "red"}>
                                                      <p className="p-[7px] m-0">
                                                        {checkDueDate(new Date(String(attend.sla_deadline))).status}
                                                      </p>
                                                    </Tag>
                                                  </div>
                                                  <div className="my-[7px]">
                                                    {checkDueDate(new Date(String(attend.sla_deadline))).status
                                                      === "No prazo" ? checkDueDate(new Date(String(attend.sla_deadline))).hora :
                                                      `- ${checkDueDate(new Date(String(attend.sla_deadline))).hora}`
                                                    }
                                                  </div>
                                                </>
                                              )}
                                              {
                                                attend?.sla_paused_on && (
                                                  <div className="mx-auto text-center">
                                                    <div className="w-[100px] my-[7px]">
                                                      <Tag position="center"
                                                        type={"blue"}
                                                      >
                                                        <p className="p-[7px] m-0">
                                                          Pausado
                                                        </p>
                                                      </Tag>
                                                    </div>
                                                    <p className="mx-auto text-center">
                                                      {checkDueDate(new Date(String(attend?.sla_paused_on))).hora}
                                                    </p>
                                                  </div>
                                                )
                                              }
                                              {
                                                !attend?.sla_paused_on && !attend.sla_deadline && (
                                                  <div className="mx-auto text-center">
                                                    {/* <div className="w-[100px] my-[7px]">
                                                  <Tag position="center"
                                                    type={"blue"}
                                                  >
                                                    <p className="p-[7px] m-0">
                                                      Pausado
                                                    </p>
                                                  </Tag>
                                                </div>
                                                <p className="mx-auto text-center">
                                                </p> */}
                                                    -----
                                                  </div>
                                                )
                                              }
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    )}
                                  </TableBody>
                                </Table>
                              )}
                            </TableContainer>
                          </TabPane>
                        );
                      })}
                </Tabs>
              </Column>
            </Grid>
          }
        </>
        :
        <>
          <div>
            <Loading withOverlay={true} active={true} style={{ zIndex: 4 }} />
          </div>
        </>
      }

    </div>
  );
};

export default Attends;
