import { Section } from "@carbon/react";
import "@fontsource/ibm-plex-sans";
import { Logout } from "@carbon/icons-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import { Outlet, useLocation, useNavigate } from "react-router-dom"; //useLocation
import useAuth from "~/hooks/useAuth";
import useUser from "~/hooks/useUser";
import { useCallback, useEffect, useState } from "react";
import BanvoxLogo from "~/assets/banvox.png";
import { getProfilesAPI } from "~/services/api/profile";
import AppStorage from "~/services/storage";
import { getRefreshTokenAPI } from "~/services/api/auth";
import FirstLoginModal from "./FirstLoginModal";
import { UIHeaderNav, Tooltip } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import useForum from "~/hooks/useForum";
import AuthenticateFund from "./Funds/AuthenticateFund/AuthenticateFund";
import { SearchIconSVG } from "~/assets/IconsSVG"
import { getNotificationsInvite } from "~/services/api/notifications";
import useNotification from "~/hooks/useToast";

import { SideNotification } from "./SideNotification";
import { getUserAPI } from "~/services/api/user";
import useArea from "~/hooks/useArea";


const DashboardContainer = styled.div`
  min-height: 100vh;

  .cds--header--navigation {
    top: 48px;

    nav {
      padding-left: 0;
    }
  }
  .header {
    z-index: 1;
  }
`;
const IconWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.7;
  }
  
  &:active {
    transform: scale(0.95);
  }
`;

const DashboardStructure = () => {

  const navigate = useNavigate();

  const {
    setUserProfile,
    userProfile,
    setPass,
    setIsFirstLogin,
    isFirstLogin,
  } = useUser();

  const { setParticipantMandatory, setSaveFilesForum } = useForum()

  const { setAttendAreaProfiles } = useArea();

  const { logout } = useAuth();

  const { pathname } = useLocation();

  const {
    setInviteNotification,
    inviteNotification,
    setToast,
    toast
  } = useNotification()

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", { hour: "2-digit", minute: "2-digit" })
  );

  const fetchRefreshToken = async () => {
    const refresh = AppStorage.getRefresh();
    const payload = { refresh: refresh };

    try {
      const { data } = await getRefreshTokenAPI(payload);
      AppStorage.setToken(data.access);
    } catch (e) {
      console.log(e);
      handleLogout()
    }
  };

  const fetchUserProfile = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI();
      setUserProfile(data.results[0]);
      getUserAPI()
    } catch (e) {
      console.error(e);
      handleLogout();
    }
  }, []);

  const fetchNotificatonFiltered = useCallback(async () => {

    try {
      const { data } = await getNotificationsInvite(Number(userProfile?.user?.id));

      setInviteNotification(data.results)

    } catch (e) {
    }
  }, [userProfile, inviteNotification])

  useEffect(() => {
    fetchRefreshToken();
    fetchUserProfile();
    setInterval(() => {
      fetchRefreshToken();
    }, 120000);
  }, []);


  useEffect(() => {
    fetchNotificatonFiltered()
  }, [userProfile])

  const excludedPaths = [
    // "/app/forum/forum-type/",
    "/app/forum/summary-forum/",
    "/app/forum/new-type-forum/",
  ];

  useEffect(() => {
    if (!excludedPaths.includes(pathname)) {
      AppStorage.remove("@participant_mandatory");
      AppStorage.remove("@form_type_forum");
      setParticipantMandatory([]);
    }
    if (pathname !== "/app/forum/creat-forum/") {
      setSaveFilesForum([])
    }
    if (!/^\/app\/attends\/\d+$/.test(pathname)) {
      setAttendAreaProfiles([]);
    }
  }, [pathname]);


  useEffect(() => {
    if (userProfile?.raw_data?.first_login) {
      setIsFirstLogin(true);
    } else {
      setIsFirstLogin(false);
    }
  }, [userProfile, setIsFirstLogin, setPass]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000); // Atualiza a cada segundo
    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userProfile?.raw_data?.first_login === false) {
        AppStorage.remove("@app:pass");
      }
    }, 20000);
    return () => clearTimeout(timer);
  }, [userProfile]);

  return (
    <>

      {pathname.includes("app/autheticate-fund") ? (
        <AuthenticateFund />
      ) : (
        <div>
          {" "}
          {!isFirstLogin &&

            <UIHeaderNav
              navigateProfile={() => {
                navigate("my-data");
              }}
              SectionRightTime={
                <p
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {new Date().toLocaleDateString()}- {currentTime}
                </p>
              }
              options={[
                {
                  label: "Atendimentos",
                  permission: true,
                  onClick: () => navigate("attends/"),
                },
                {
                  label: "Fórum",
                  permission: true,
                  optionsMenu: [
                    {
                      label: "Tipo de Fóruns",
                      onClick: () => navigate("/app/forum/forum-type/"),
                      permission: true,
                    },
                    {
                      label: "Meus fóruns",
                      onClick: () => navigate("/app/forum/list-forum/"),
                      permission: true,
                    },
                  ],
                },
                {
                  label: "Pagamentos",
                  onClick: () => navigate("payments/"),
                  permission: true,
                },
                {
                  label: "Beneficiários",
                  permission: true,
                  onClick: () => navigate("/app/beneficiaries"),
                },

                {
                  label: "Empresa",
                  permission:
                    userProfile?.type.includes("manager") ||
                    userProfile?.type.includes("client"),
                  onClick: () => navigate("/app/company-data"),
                },

                {
                  label: "Configurações",
                  permission:
                    userProfile?.type.includes("tool_manager") ||
                    userProfile?.type.includes("area_manager"),
                  optionsMenu: [
                    {
                      label: "Usuários",
                      permission: true,
                      onClick: () => navigate("/app/profiles"),
                    },
                    {
                      label: "Empresas",
                      permission: true,
                      onClick: () => navigate("/app/companys"),
                    },
                    {
                      label: "Fundos",
                      permission: true,
                      onClick: () => navigate("/app/funds"),
                    },
                    {
                      label: "Áreas",
                      permission: true,
                      onClick: () => navigate("/app/areas"),
                    },
                    // {
                    //   label: "Status de Atendimento",
                    //   permission: true,
                    //   onClick: () => navigate("/app/status"),
                    // },
                    {
                      label: "Fluxo de Status",
                      permission: true,
                      onClick: () => navigate("/app/status-flow"),
                    },
                    {
                      label: "Tipos de Atendimento",
                      permission: true,
                      onClick: () => navigate("/app/attend-types"),
                    },
                    {
                      label: "Tipos de despesas",
                      permission: true,
                      onClick: () => navigate("/app/payments-types"),
                    },
                  ],
                },
              ]}
              icon={
                <>
                  <img
                    onClick={() => navigate("/")}
                    src={BanvoxLogo}
                    alt=""
                    style={{
                      height: "60px",
                      cursor: "pointer",
                      filter: "brightness(10%) invert(1) brightness(100%)",
                    }}
                  />
                </>
              }
              userName={
                <>
                  Olá,{" "}
                  {userProfile?.user?.first_name === ""
                    ? "Torrano"
                    : `${userProfile?.user?.first_name} ${userProfile?.user?.last_name}`}
                </>
              }
              emailuser={`${userProfile?.user.email}`}
              bgHeader="#0A4A87"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  justifyContent: "center",
                  width: inviteNotification && inviteNotification?.length > 0 ? "100px" : "50px",
                  padding: "0",
                  margin: "0 10px 0 0",
                  gap: "20px"
                }}
              >
                {inviteNotification && inviteNotification?.length > 0 &&
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <IconWrapper
                      className="p-[10px]"
                      onClick={(event) => {
                        event.preventDefault();
                        setToast((prev) => !prev);
                      }}
                    >
                      {SearchIconSVG}
                    </IconWrapper>
                    {inviteNotification && inviteNotification.filter((toast) => toast.seen_at === null).length > 0 &&
                      <span
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "0",
                          background: "red",
                          color: "white",
                          fontSize: "10px",
                          fontWeight: "bold",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "translate(50%, -50%)",
                          boxShadow: "0 0 4px rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        {inviteNotification && inviteNotification.filter((toast) => toast.seen_at === null).length}
                      </span>}
                  </div>
                }
                <Tooltip align="bottom" size="small" label="Sair" width="60px">
                  <div
                    style={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <Logout />
                  </div>
                </Tooltip>
              </div>
            </UIHeaderNav >
          }
          <Margin mt={100} />
          <DashboardContainer>
            {isFirstLogin ?
              <FirstLoginModal onClose={() => null} open={isFirstLogin} /> :
              <>
                <Section>
                  <Outlet />
                </Section>{" "}
                <Margin mt={90} />
                {toast &&
                  <SideNotification />
                }</>}

          </DashboardContainer>
        </div >
      )}
    </>
  );
};

export default DashboardStructure;
