import constate from "constate";
import { useState } from "react";
import { IAttend, IAttendLog, IFile, IHistory, IStatus } from "~/types";

const [AttendProvider, useAttend] = constate(() => {
  const [attends, setAttends] = useState<IAttend[] | null>(null);
  const [subAttends, setSubAttends] = useState<IAttend[]>([]);
  const [attendParentData, setAttendParentData] = useState<IAttend>();

  const [attendLog, setAttendLog] = useState<IAttendLog>();
  const [attendFiles, setAttendFiles] = useState<IFile[]>();
  const [attendFileDetails, setAttendFileDetails] = useState<IFile>();

  const [attendDetails, setAttendDetails] = useState<IAttend>();
  const [subAttendDetails, setSubAttendDetails] = useState<IAttend>();

  const [openUpdateAttendStatusModal, setOpenUpdatedAttendStatusModal] =
    useState<boolean>(false);
  const [openUpdateAttendAreaModal, setOpenUpdatedAttendAreaModal] =
    useState<boolean>(false);

  const [successDeleteFile, setSuccessDeleteFile] = useState<boolean>(false);

  const [openDeleteFileModal, setOpenDeleteFileModal] =
    useState<boolean>(false);

  const [newSubAttend, setNewSubAttend] = useState<boolean>(false);

  const [openUpdateAreaTypeModal, setOpenUpdatedAreaTypeModal] =
    useState<boolean>(false);
  const [openUpdateOwnerAttendModal, setOpenUpdatedOwnerAttendModal] =
    useState<boolean>(false);
  const [status, setStatus] = useState<IStatus[]>([]);

  const [history, setHistory] = useState<IHistory[]>([]);

  return {
    newSubAttend,
    setNewSubAttend,
    attendFiles,
    openDeleteFileModal,
    setOpenDeleteFileModal,
    setAttendFiles,
    attendLog,
    setAttendLog,
    attends,
    setAttends,
    attendDetails,
    setAttendDetails,
    openUpdateAttendStatusModal,
    setOpenUpdatedAttendStatusModal,
    status,
    setStatus,
    openUpdateAttendAreaModal,
    setOpenUpdatedAttendAreaModal,
    openUpdateAreaTypeModal,
    setOpenUpdatedAreaTypeModal,
    openUpdateOwnerAttendModal,
    setOpenUpdatedOwnerAttendModal,
    attendFileDetails,
    setAttendFileDetails,
    successDeleteFile,
    setSuccessDeleteFile,
    setSubAttends,
    subAttends,
    subAttendDetails,
    setSubAttendDetails,
    attendParentData,
    setAttendParentData,
    history,
    setHistory,
  };
});

export { AttendProvider };

export default useAttend;
