import { Breadcrumb, Button } from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
    Background,
    Controls,
    addEdge,
    useEdgesState,
    useNodesState,
    Connection,
    Node,
} from "reactflow";
import "reactflow/dist/style.css";
import useWorkflow from "~/hooks/useWorkflow";
import { getStatusFiltered, getWorkflowFiltered, roleStatusWorkflowAPI, StatusWorkflowTransitionAPI } from "~/services/api/workflow";
import { ModalAddStatus } from "./ModalAddStatus";
// import { addIconSVG } from "~/assets/IconsSVG";
import trashIcon from "~/assets/trashIcon.png";
import editIcon from "~/assets/editicon.png";
import { Loading } from "@carbon/react";
import { ModalEditStatus } from "./ModalEditStatus";
import { ModalRemoveStatus } from "./ModalRemoveStatus";

const DagreDragFlow: React.FC = () => {

    const {
        activeStatuses,
        setActiveStatuses,
        setSelectedFlow,
        selectedFlow,
        modaladdStatus,
        setmodaladdStatus,
        setEditStatus,
        editStatus,
        modaleditStatus,
        setModaleditStatus,
        modalremoveStatus,
        setModalremoveStatus
    } = useWorkflow()

    const { id } = useParams()

    const fetchStatus = useCallback(async () => {

        try {

            const { data } = await getStatusFiltered(Number(id))

            const { data: workflow } = await getWorkflowFiltered(Number(id))

            setSelectedFlow(workflow)

            setActiveStatuses(data.results)

        } catch {

        }
    }, [id, activeStatuses, selectedFlow])


    const initialEdges = useMemo(() => {

        return activeStatuses && activeStatuses
            .map((status, index) => {
                if (index === activeStatuses.length - 1) return null;
                return {
                    id: `e${status.id}-${activeStatuses[index + 1].id}`,
                    source: String(status.id),
                    target: String(activeStatuses[index + 1].id),
                };
            })
            .filter(Boolean) as any[];

    }, [activeStatuses]);


    const initialNodes = useMemo(() => {
        return activeStatuses
            ? activeStatuses
                .sort((a, b) => {
                    // Ordena primeiro pelo campo 'order'
                    if (a.order === b.order) {
                        // Se 'order' for o mesmo, ordena pelo 'created_on'
                        return new Date(a.created_on).getTime() - new Date(b.created_on).getTime();
                    }
                    return a.order - b.order; // Caso contrário, ordena pelo 'order'
                })
                .map((status, index) => ({
                    id: String(status.id),
                    position: { x: 100 + index * 200, y: 100 },
                    data: { label: status.name },
                    style: { background: status.color, borderRadius: "100px", padding: "10px" }
                }))
            : []
    }, [activeStatuses, id])


    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes || []);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges || []);

    const navigate = useNavigate();

    const onConnect = (connection: Connection) => {
        setEdges((eds) => addEdge(connection, eds));
    };

    // Área da lixeira (posicionada no canto inferior esquerdo)
    const trashZone = { x: 50, y: 400, width: 100, height: 100 };

    // Função para detectar se o nó foi solto dentro da área da lixeira
    const onNodeDragStop = (_event: any, node: Node) => {
        const { x, y } = node.position;

        // Verifica se o nó está dentro da área da lixeira
        const isInTrash =
            x >= trashZone.x &&
            x <= trashZone.x + trashZone.width &&
            y >= trashZone.y &&
            y <= trashZone.y + trashZone.height;

        if (isInTrash) {
            // Remove o nó da lista de nós e as arestas conectadas a ele
            setNodes((nds) => nds.filter((n) => n.id !== node.id));
            setEdges((eds) =>
                eds.filter((e) => e.source !== node.id && e.target !== node.id)
            );
        }
    };
    const onNodeClick = (event: React.MouseEvent, node: Node) => {
        console.warn(event)
        setEditStatus(node)
    };

    useEffect(() => {
        fetchStatus();
    }, [id]);

    useEffect(() => {
        setNodes(initialNodes || []);
        setEdges(initialEdges || []);
    }, [activeStatuses]);


    useEffect(() => {
        roleStatusWorkflowAPI()
        StatusWorkflowTransitionAPI()
    }, [])


    return (
        <>
            {activeStatuses !== null ?
                <div className="w-[100%] mx-auto relative">
                    <div className="w-full mx-auto">
                        <div className="bg-[#f4f4f4] m-0 w-full">
                            <div className="flex flex-col">
                                <div className="mt-[40px] ml-[36px] w-1/2 flex flex-col items-start text-left">
                                    <Breadcrumb
                                        crumbsOptions={[
                                            { crumb: "Configurações", onClick: () => navigate("/app") },
                                            { crumb: "Fluxos de status" },
                                        ]}
                                        endHiddenIndex={5}
                                        startHiddenIndex={5}
                                    />
                                </div>
                                <h2 className="mt-4 ml-[36px] text-black text-[40px] font-light leading-[40px]">
                                    {selectedFlow && selectedFlow?.name}
                                </h2>
                                <p className="mt-1 ml-[36px] text-black font-light leading-[40px] break-words whitespace-normal w-[95%]">
                                    {selectedFlow && selectedFlow?.description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-[95%] mx-auto relative">
                        <div>
                            <div className="flex gap-[10px] my-[30px] w-[100%]">
                                {/* <Button kind="tertiary" size="large" label="Status pendente" />
                        <Button kind="tertiary" size="large" label="Status em andamento" />*/}
                                {/* <Button
                                    kind="tertiary"
                                    size="large"
                                    label="Adicionar Status"
                                    onClick={() => { setmodaladdStatus((prev) => !prev) }}
                                    hasIconSvg
                                    icon={addIconSVG}
                                /> */}
                                {/* <Button
                                    kind="tertiary"
                                    size="large"
                                    label="Transição"
                                    icon={iconTrasition}
                                    hasIconSvg
                                />
                                <Button
                                    kind="tertiary"
                                    size="large"
                                    label="Regra"
                                    icon={iconRole}
                                    hasIconSvg
                                /> */}
                            </div>
                        </div>
                        <div style={
                            {
                                width: "100%",
                                height: "600px",
                                margin: "10px auto",
                                position: "relative",
                                border: "1px solid #c0c0c0",
                            }}>
                            <div
                                style={{ position: "absolute", right: "0", zIndex: "6" }}
                                className="flex justify-end gap-[10px] m-[10px]">
                                {editStatus && <>
                                    <Button
                                        kind="ghost"
                                        size="small"
                                        label=""
                                        noPadding="5px"
                                        icon={trashIcon}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            setModalremoveStatus((prev) => !prev)
                                        }}
                                    />
                                    <Button
                                        kind="ghost"
                                        size="small"
                                        label=""
                                        icon={editIcon}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            setModaleditStatus((prev) => !prev)
                                        }}
                                    />
                                </>
                                }
                            </div>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                onNodeDragStop={onNodeDragStop}
                                onNodeClick={onNodeClick}
                                fitView
                            >
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </div>
                    </div>
                    <ModalAddStatus
                        onClose={() =>
                            setmodaladdStatus((prev) => !prev)
                        }
                        workflow={Number(id)}
                        open={modaladdStatus}
                    />
                </div>
                :
                <Loading withOverlay active style={{ zIndex: 4 }} />

            }
            <ModalEditStatus
                onClose={() => setModaleditStatus(false)}
                open={modaleditStatus}
                workflow={Number(id)}
            />
            <ModalRemoveStatus
                onClose={() => setModalremoveStatus(false)}
                open={modalremoveStatus}
                workflow={Number(id)}
            />
        </>
    )
};

export default DagreDragFlow;
