import { useFormik } from "formik";
import { InputFluid, Margin, Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLoading from "~/hooks/useLoading";
import useNotification from "~/hooks/useToast";
import { postWorkflow } from "~/services/api/workflow";


type initialValues = {
    name: string,
    description: string,
    active: boolean
}

const initialValues = {
    name: "",
    description: "",
    active: false
}

export const ModalCreateWorkflow = ({
    onClose,
    open,
}: {
    open: boolean;
    onClose: () => void;

}) => {

    const { setLoading } = useLoading();

    const navigate = useNavigate()

    const {
        setNotification,
        closeNotification
    } = useNotification()

    const { handleChange, values, resetForm } = useFormik({
        onSubmit: () => { },
        initialValues
    })

    const payload: initialValues = {
        name: values.name,
        description: values.description,
        active: true
    }

    const sendWorkflow = useCallback(async () => {

        setLoading(true);

        try {

            const { data } = await postWorkflow(payload)

            setNotification({
                status: "success",
                message: "O workflow foi criado com sucesso.",
                title: "Criado com sucesso",
                actived: true,
                onClose: () => closeNotification()
            });

            navigate(`/app/status-flow-details/${data?.id}`);

            resetForm()

            onClose()

        } catch (error) {

            setNotification({
                status: "error",
                message: "Não foi possível editar o tipo de fórum no momento, tente novamente mais tarde ou contate o administrador do sistema.",
                title: "Falha ao editar",
                actived: true,
                onClose: () => closeNotification()
            });

        } finally {

            setLoading(false);
        }

    }, [payload])


    return (
        <Modal
            label="Adicionar Novo Workflow"
            textLabel="Adicione um novo fluxo"
            open={open}
            width="800px"
            height="550px"
            background="#f4f4f4"
            onClose={() => {
                onClose()
                resetForm()
            }}
            buttons={
                [
                    {
                        label: "Cancelar",
                        kind: "secondary",
                        size: "large",
                        dimension: "80",
                        onClick: () => {
                            onClose && onClose()
                            resetForm()
                        }
                    },
                    {
                        label: "Criar Um Workflow",
                        kind: "primary",
                        dimension: "80",
                        size: "large",
                        disabled: values.name.length < 5,
                        onClick: () => { sendWorkflow() }
                    },
                ]
            }
        >
            <Margin top={10} />
            <div className="w-[99%] mx-auto">
                <InputFluid
                    label="Nome (obrigatorio)"
                    noRadius
                    name="name"
                    value={values.name}
                    placeholder="Digite o nome do fluxo"
                    onChange={handleChange}
                />
                <Margin top={10} />
                <InputFluid
                    label="Descrição"
                    noRadius
                    value={values.description}
                    name="description"
                    placeholder="Digite uma Descrição"
                    type="textarea"
                    rows={10}
                    onChange={handleChange}
                />
            </div>
        </Modal>
    )
}
