import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  ComboBox,
  // FileUploader,
  Grid,
  SideNav,
  SideNavItems,
  SideNavLink,
  Stack,
  TextInput,
  Toggle,
  // TextArea,
  // TextInput,
} from "@carbon/react";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useAttend from "~/hooks/useAttend";
import useLoading from "~/hooks/useLoading";
import { getAttendAPI } from "~/services/api/attend";
import { ISendNewTaskBody, postTaskAPI } from "~/services/api/task";
import * as Yup from "yup";
import {
  ISendNewAttendTaskBody,
  postAttendTaskAPI,
} from "~/services/api/attendTask";
import { permissions } from "~/services/permissions";
import { getAttendAreaProfilesAPI } from "~/services/api/attendAreaProfile";
import useArea from "~/hooks/useArea";
import { IArea, IAttendAreaProfile } from "~/types";
import { getAreasAPI } from "~/services/api/areas";

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;
    background: #f4f4f4;
  }

  .form {
    background: #f4f4f4;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

type Values = {
  attend: string;
  name: string;
  owner: string;
  area: string;
};

const initialValues: Values = {
  attend: "",
  name: "",
  owner: "",
  area: "",
};

const validationSchema = Yup.object({
  attend: Yup.string(),
  owner: Yup.string(),
  name: Yup.string().required("Nome da tarefa é obrigatório"),
});

const typeDict: { [key: string]: string } = {
  area_manager: "Gestor de área",
  internal: "Atendente",
};

const NewTask = () => {
  const navigate = useNavigate();
  const { setAttends, attends } = useAttend();
  const { setLoading } = useLoading();
  const { setAttendAreaProfiles, setAreas, areas, attendAreaProfiles } =
    useArea();

  const [associateAttend, setAssociateAttend] = useState<boolean>(false);
  const [associateInternal, setAssociateInternal] = useState<boolean>(false);

  const canCreateTasks = permissions.includes("can_create_tasks");
  const {
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    values,
    handleBlur,
    handleChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const sendNewTaskPayload: ISendNewTaskBody = {
          name: values.name,
        };

        if (values.attend) {
          sendNewTaskPayload.attend = Number(values.attend);
        }

        if (values.owner) {
          sendNewTaskPayload.owner = Number(values.owner);
        }

        await fetchSendNewTask(sendNewTaskPayload);
      } catch (error) {
        console.error("Erro ao enviar a nova tarefa:", error);
      }
    },
  });

  const fetchSendNewAttendTask = async (body: ISendNewAttendTaskBody) => {
    try {
      await postAttendTaskAPI(body);
    } catch (error) {
      console.error("Erro ao enviar tarefa:", error);
      setLoading(false);
    }
  };

  const fetchSendNewTask = async (payload: ISendNewTaskBody) => {
    try {
      setLoading(true);
      const { data } = await postTaskAPI({
        name: payload.name,
        owner: payload.owner ?? null,
        status: payload.status,
      });
      console.log(data);

      if (payload.attend) {
        const payloadAttendTask = {
          attend: payload.attend,
          task: data.id,
        };

        await fetchSendNewAttendTask(payloadAttendTask);
      }

      setLoading(false);
      navigate("/app/tasks/");
      resetForm();
    } catch (error) {
      console.error("Erro ao enviar tarefa:", error);
      setLoading(false);
    }
  };

  const attendsOptions = useMemo(() => {
    return attends?.map((attend) => ({
      text: `#${attend.id} - ${attend?.type?.name}`,
      id: String(attend.id),
    }));
  }, [attends]);

  const handleSelectAttend = (data: {
    selectedItem?: { id: string; text: string } | null;
  }) => {
    if (data.selectedItem) {
      setFieldValue("attend", data.selectedItem.id);
    }
  };

  const handleSelectArea = (data: {
    selectedItem?: { id: string; text: string } | null;
  }) => {
    if (data.selectedItem) {
      setFieldValue("area", data.selectedItem.id);
    }
  };

  const handleSelectOwner = (data: {
    selectedItem?: { id: string; text: string } | null;
  }) => {
    if (data.selectedItem) {
      setFieldValue("owner", data.selectedItem.id);
    }
  };

  const fetchAttends = useCallback(async () => {
    try {
      const { data } = await getAttendAPI();
      setAttends(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, []);

  const handleToggle1 = () => {
    setAssociateAttend(!associateAttend);
  };

  const handleToggle2 = () => {
    setAssociateInternal(!associateInternal);
  };
  const fetchAreas = async () => {
    try {
      const { data } = await getAreasAPI();
      setAreas(data.results);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  const areaOptions = useMemo(() => {
    return areas?.map((area: IArea) => ({
      id: String(area.id),
      text: area.name,
    }));
  }, [areas]);

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI();
      setAttendAreaProfiles(data.results);
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles]);

  const filteredAreaProfiles = useMemo(
    () =>
      attendAreaProfiles.filter((attendAreaProfile: IAttendAreaProfile) => {
        return attendAreaProfile.area === Number(values.area);
      }),
    [attendAreaProfiles, values.area]
  );

  const ownerOptions = useMemo(() => {
    return filteredAreaProfiles?.map((areaProfile: IAttendAreaProfile) => ({
      id: String(areaProfile.profile.id),
      text: `${areaProfile.profile.name} - ${typeDict[areaProfile.profile.type]}`,
    }));
  }, [filteredAreaProfiles]);

  useEffect(() => {
    fetchAttendAreaProfiles();
  }, [fetchAttendAreaProfiles]);

  useEffect(() => {
    fetchAreas();
    fetchAttends();
  }, []);

  return (
    <TicketFormContainer>
      {canCreateTasks ? (
        <form onSubmit={handleSubmit}>
          {" "}
          {/* Formulário envolvendo o onSubmit do formik */}
          <Grid>
            <Column span={16}>
              <Padding y={24}>
                <Breadcrumb>
                  <BreadcrumbItem onClick={() => navigate("/app/tasks/")}>
                    Tarefas
                  </BreadcrumbItem>
                  <BreadcrumbItem onClick={() => navigate("/app/tasks/new")}>
                    Nova Tarefa
                  </BreadcrumbItem>
                </Breadcrumb>
              </Padding>
              <h1>Nova Tarefa</h1>
              <Margin mb={12} />
              <Grid className="form">
                <Column span={4}>
                  <SideNav
                    aria-label="Side navigation"
                    className="form-sidenav"
                  >
                    <SideNavItems>
                      <SideNavLink href="#" isActive>
                        Geral
                      </SideNavLink>
                    </SideNavItems>
                  </SideNav>
                </Column>

                <Column span={12}>
                  <Grid>
                    <Column span={12}>
                      <Padding y={32}>
                        <h2>Geral</h2>
                      </Padding>
                    </Column>
                    <Column span={6}>
                      <Stack gap={5}>
                        <TextInput
                          id="subject"
                          name="name"
                          labelText="Nome da tarefa"
                          placeholder="Digite o nome da tarefa"
                          required
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <Toggle
                          id="toggle-2"
                          labelText="Atribuir responsável"
                          onToggle={handleToggle2}
                          toggled={associateInternal}
                          labelA=""
                          labelB=""
                        />
                        {associateInternal && (
                          <>
                            {" "}
                            <ComboBox
                              id="area"
                              titleText="Selecione a àrea do responsável"
                              placeholder="Pesquisar ou selecionar a àrea do responsável..."
                              items={areaOptions}
                              itemToString={(item) => (item ? item.text : "")}
                              onChange={handleSelectArea}
                            />
                            <ComboBox
                              id="owner"
                              titleText="Selecione o responsável pela tarefa"
                              placeholder="Pesquisar ou selecionar um responsável..."
                              items={ownerOptions}
                              itemToString={(item) => (item ? item.text : "")}
                              onChange={handleSelectOwner}
                            />
                          </>
                        )}
                        <Margin mb={20} />

                        <Toggle
                          id="toggle-1"
                          labelText="Associar à um atendimento"
                          onToggle={handleToggle1}
                          toggled={associateAttend}
                          labelA=""
                          labelB=""
                        />
                        {associateAttend && attends && attends.length > 0 && (
                          <ComboBox
                            id="attend"
                            titleText="Selecione um atendimento"
                            placeholder="Pesquisar ou selecionar um atendimento..."
                            items={attendsOptions || []}
                            itemToString={(item) => (item ? item.text : "")}
                            onChange={handleSelectAttend}
                          />
                        )}
                        {associateAttend && (
                          <Button
                            onClick={() => navigate("/app/attends/new/")}
                            kind="ghost"
                          >
                            Criar um atendimento
                          </Button>
                        )}
                      </Stack>
                    </Column>

                    <Column span={6} />

                    <Column span={12}>
                      <Padding y={32}>{/* <h2>Anexos</h2> */}</Padding>
                    </Column>

                    <Column span={6}>
                      <Stack gap={5}>
                        {/* <FileUploader
                        labelTitle="Anexos"
                        labelDescription="Arraste e solte arquivos aqui ou clique para carregar."
                        buttonLabel="Adicionar anexos"
                        filenameStatus="edit"
                        accept={[".jpg", ".png", ".gif"]}
                      /> */}
                      </Stack>

                      <Margin mb={64} />
                    </Column>

                    <Column span={6} />

                    <Column span={6}>
                      <Grid>
                        <Column span={3}>
                          <Button
                            kind="tertiary"
                            className="action-button"
                            onClick={() => navigate("/app/tasks/")}
                          >
                            Cancelar
                          </Button>
                        </Column>
                        <Column span={3}>
                          <Button
                            type="submit"
                            kind="primary"
                            className="action-button"
                            disabled={!isValid}
                          >
                            Enviar
                          </Button>
                        </Column>
                      </Grid>
                    </Column>

                    <Column span={6} />
                  </Grid>

                  <Margin mb={64} />
                </Column>
              </Grid>

              <Margin mb={64} />
            </Column>
          </Grid>
        </form>
      ) : (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {" "}
          <h4> Você não possui permissão para criar tarefas</h4>
        </div>
      )}
    </TicketFormContainer>
  );
};

export default NewTask;
