import { Modal } from "@carbon/react";

import Margin from "~/components/Margin";
import { useCallback, useEffect } from "react";
import useLoading from "~/hooks/useLoading";
import useArea from "~/hooks/useArea";

import {
  deleteAttendAreaProfileAPI,
  getAttendAreaProfilesAPI,
} from "~/services/api/attendAreaProfile";
import { getProfilesAPI } from "~/services/api/profile";
import useProfile from "~/hooks/useProfile";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const DeleteAttendAreaProfileModal = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { setLoading } = useLoading();
  const {
    areaDetails,
    setOpenDeleteAttendAreaProfileModal,
    setAttendAreaProfiles,
    attendAreaProfileDetails,
  } = useArea();
  const { setProfiles } = useProfile();
  const { id } = useParams();
  const notifySuccess = () => toast.success("Usuário removido com sucesso!");
  const notifyError = () =>
    toast.error(
      "Não foi possível remover o usuário. Tente novamente mais tarde."
    );

  const fetchAttendAreaProfiles = useCallback(async () => {
    try {
      const { data } = await getAttendAreaProfilesAPI(Number(id));
      setAttendAreaProfiles(data.results.reverse());
    } catch (e) {
      console.log(e);
    }
  }, [setAttendAreaProfiles, id]);

  const fetchProfiles = useCallback(async () => {
    try {
      const { data } = await getProfilesAPI();
      setProfiles(data.results.reverse());
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setProfiles]);

  const fetchDeleteAttendAreaProfile = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await deleteAttendAreaProfileAPI(id);
        await fetchAttendAreaProfiles();
        setOpenDeleteAttendAreaProfileModal(false);
        setLoading(false);
        notifySuccess();
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
        notifyError();
      }
    },
    [setLoading, fetchAttendAreaProfiles, setOpenDeleteAttendAreaProfileModal]
  );

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  return (
    <Modal
      style={{ zIndex: 3 }}
      modalHeading="Remover usuário"
      size="sm"
      open={open}
      onRequestClose={onClose}
      primaryButtonText="Sim, remover"
      secondaryButtonText="Cancelar"
      onRequestSubmit={async () => {
        fetchDeleteAttendAreaProfile(String(attendAreaProfileDetails?.id));
      }}
    >
      {/* {true && (
        <InlineNotification
          title="Erro!"
          // subtitle={error}
          subtitle="Não foi possivel atribuir o tipo de atendimento"
          hideCloseButton
          kind="error"
          lowContrast
        />
      )} */}
      <Margin mt={20} />
      <h3 style={{ textAlign: "center" }}>
        Deseja realmente remover o{" "}
        {attendAreaProfileDetails?.profile.type === "area_manager"
          ? "Gestor de área"
          : "Atendente"}{" "}
        {attendAreaProfileDetails?.profile.name} da área {areaDetails?.name}
      </h3>
      <Margin mb={100} />
    </Modal>
  );
};

export default DeleteAttendAreaProfileModal;
