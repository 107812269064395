import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Dropdown,
  Grid,
  OnChangeData,
  Stack,
  TextArea,
} from "@carbon/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Margin from "~/components/Margin";
import Padding from "~/components/Padding";
import useLoading from "~/hooks/useLoading";
import {
  getAttendDetailsAPI,
  patchAttendDetailsAPI,
} from "~/services/api/attend";
import { Tabs } from "antd";
import useAttend from "~/hooks/useAttend";
import { getFirstLetters, timeAgo } from "~/services/utils";

import useAreaType from "~/hooks/useAreaType";
import { getAreaTypesAPI } from "~/services/api/areaTypes";
import { IAttendLog, IAttendTaskMessage } from "~/types";

import {
  getAttendTaskMessageAPI,
  postAttendTaskMessageAPI,
} from "~/services/api/AttendTaskMessage";

import useUser from "~/hooks/useUser";
// import AttendDetailsTasks from "./AttendDetailsTasks";
import { getAttendLogsAPI } from "~/services/api/attendLog";
import LateralDetailsAttends from "../LateralDetailsAttends";
import Label from "~/components/Label";
import AttendDetailsFiles from "../AttendDetailsFiles";
import UpdateStatusAttendModal from "../UpdateStatusAttendModal";
import UpdateOwnerAttendModal from "../UpdateOwnerAttendModal";

const TicketFormContainer = styled.div`
  position: relative;

  .form-sidenav {
    position: relative;
    background: #ffffff;
  }

  .form {
    background: #ffffff;
    min-height: 50vh;

    .action-button {
      width: 100%;
    }
  }
`;

type Values = {
  message: string;
  selectedOption: string;
};

const initialValues: Values = {
  message: "",
  selectedOption: "",
};

const validationSchema = Yup.object({
  message: Yup.string().required("Descrição é obrigatória"),
  selectedOption: Yup.string().required("Seleção é obrigatória"),
});

const SubAttendDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAreaTypes } = useAreaType();
  const {
    setAttendDetails,
    setSubAttendDetails,
    subAttendDetails,
    attendDetails,
    setAttendLog,
    setNewSubAttend,
    setAttendParentData,
    setOpenUpdatedAttendStatusModal,
    setOpenUpdatedOwnerAttendModal,
    openUpdateAttendStatusModal,
    openUpdateOwnerAttendModal
  } = useAttend();
  const { userProfile } = useUser();
  const { TabPane } = Tabs;

  const { setLoading } = useLoading();
  const [messages, setMessages] = useState<IAttendTaskMessage[]>([]);

  const { values, handleSubmit, setFieldValue, handleBlur, handleChange } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        fetchSendAttendsTaskMessages();
      },
    });

  const senderDict: { [key: string]: string } = useMemo(() => {
    return {
      client: "client",
      internal: "owner",
      area_manager: "owner",
      tool_manager: "owner",
    };
  }, []);

  interface DropdownItem {
    id: string;
    label: string;
  }

  const items: DropdownItem[] = [
    { id: "1", label: "Responder" },
    { id: "2", label: "Nota Privada" },
  ];

  const handleDropdownChange = (eventData: OnChangeData<DropdownItem>) => {
    const selectedItem = eventData.selectedItem;

    if (selectedItem) {
      setFieldValue("selectedOption", selectedItem.id);
    }
  };

  const isSolicitant = useMemo(() => {
    return (
      userProfile?.type === "client" ||
      subAttendDetails?.client.id === userProfile?.id
    );
  }, [subAttendDetails, userProfile]);

  //depois preciso colocar o isWatcher

  const fetchAttends = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const { data } = await getAttendDetailsAPI(Number(id));

        setSubAttendDetails(data);
        setAttendDetails(data);

        if (data) {
          const attendParent = await getAttendDetailsAPI(Number(data.parent));

          const attendParentDataFiltered = attendParent.data;

          setAttendParentData(attendParentDataFiltered);
        }

        setLoading(false);
      } catch (err: unknown) {
        console.error(err);
        setLoading(false);
      }
    }
  }, [id]);

  const fetchAttendLog = async () => {
    try {
      const { data } = await getAttendLogsAPI();

      const filteredData = data.results.filter(
        (log: IAttendLog) => log.attend === Number(id)
      );

      setAttendLog(filteredData[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAreaTypes = useCallback(async () => {
    try {
      const { data } = await getAreaTypesAPI();

      setAreaTypes(data.results);
    } catch (err: unknown) {
      console.error(err);
    }
  }, [setAreaTypes]);

  const fetchAttendsTaskMessages = useCallback(async () => {
    try {
      const { data } = await getAttendTaskMessageAPI();

      const messagesFiltered = data.results.filter(
        (message: IAttendTaskMessage) => message.attend === Number(id)
      );

      setMessages(messagesFiltered.reverse());
      setLoading(false);
    } catch (err: unknown) {
      console.error(err);
      setLoading(false);
    }
  }, [id, setLoading]);

  useEffect(() => {
    if (id) {
      fetchAttendsTaskMessages();
    }
  }, [id, fetchAttendsTaskMessages]);

  const paylodMessage = useMemo(() => {
    return {
      sender: userProfile?.id === attendDetails?.client.id ? "client" : "owner",
      message: values.message,
      subject: userProfile?.name,
      attend: subAttendDetails?.id,
    };
  }, [
    values.message,
    values.selectedOption,
    subAttendDetails?.id,
    senderDict,
    userProfile?.name,
    userProfile?.type,
  ]);
  userProfile?.type
  // userProfile?.id === attendDetails?.client.id ? "client" : "owner",
  const fetchSendAttendsTaskMessages = useCallback(async () => {
    try {
      await postAttendTaskMessageAPI(paylodMessage);
      setFieldValue("message", "");
      await fetchAttendsTaskMessages();
    } catch (err: unknown) {
      console.error(err);
    }
  }, [paylodMessage, setFieldValue, fetchAttendsTaskMessages]);


  const _fetchAttends = useCallback(async () => {
    if (subAttendDetails) {
      try {
        const { data } = await getAttendDetailsAPI(
          Number(subAttendDetails?.id)
        );
        setSubAttendDetails(data);
        setAttendDetails(data);
      } catch (err: unknown) {
        console.error(err);
      }
    }
  }, [subAttendDetails, setSubAttendDetails, setAttendDetails]);


  const fetchSendNewStatus = useCallback(async () => {
    try {
      await patchAttendDetailsAPI(Number(subAttendDetails?.id), {
        status: 3,
      });
      _fetchAttends();
    } catch (err: unknown) {
      console.error(err);
    }
  }, [subAttendDetails, _fetchAttends]);

  const allCalleds = async () => {
    await fetchAttends();
    await fetchAttendsTaskMessages();
    await fetchAreaTypes();
    await fetchAttendLog();
  };

  useEffect(() => {
    allCalleds();
    setNewSubAttend(false);
    return () => {
      setSubAttendDetails(undefined);
      setAttendDetails(undefined);
      setAttendLog(undefined);
    }
  }, []);

  return (
    <div>
      {subAttendDetails !== undefined && (
        <TicketFormContainer>
          <form onSubmit={handleSubmit} className="form">
            <Grid>
              <Column span={16}>
                <Padding y={24}>
                  <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate("/app/attends/")}>
                      Atendimentos
                    </BreadcrumbItem>
                    <BreadcrumbItem
                      onClick={() => navigate(`/app/attends/${id}`)}
                    >
                      Detalhes
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Padding>
                <Margin mt={-10} />
                <h1>
                  #{subAttendDetails?.id} - {attendDetails?.type?.name}
                </h1>
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Button
                    size="md"
                    kind="tertiary"
                    onClick={() =>
                      navigate(`/app/attends/${subAttendDetails?.parent}`)
                    }
                  >
                    Retornar ao atendimento principal
                  </Button>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  tabBarStyle={{
                    margin: 0,
                    borderBottom: "none",
                    color: "red",
                  }}
                >
                  <TabPane tab="Chat" key="1">
                    <Grid
                      id="container-center"
                      className="form"
                      style={{ background: "white" }}
                    >
                      <Column span={12}>
                        <div
                          style={{
                            padding: "40px 20px",
                            height: "500px",
                            overflow: "auto",

                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {messages.length > 0 &&
                            messages
                              .filter(
                                (message) =>
                                  !(
                                    isSolicitant &&
                                    message.sender === "internal"
                                  )
                              )
                              .map((message, index) => {
                                return (
                                  <div
                                    key={String(index)}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                      paddingTop: "40px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          background:
                                            message.sender === "internal"
                                              ? "#85b0ff"
                                              : "#b8b8b8",
                                          borderRadius: "50%",
                                          width: "50px",
                                          height: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <h3
                                          style={{
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {getFirstLetters(message.subject)}
                                        </h3>
                                      </div>
                                      <Margin ml={20} />
                                      <div>
                                        <h4
                                          style={{
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {message.sender === "internal"
                                            ? `${message.subject} - Nota Privada`
                                            : message.subject}
                                        </h4>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            color:
                                              message.sender === "internal"
                                                ? "#0f62fe"
                                                : "",
                                          }}
                                        >
                                          {timeAgo(message.created_on)}
                                        </p>
                                        <Margin mb={30} />
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        marginLeft: "10px",
                                        marginBottom: "60px",
                                        color:
                                          message.sender === "internal"
                                            ? "#0f62fe"
                                            : "",
                                      }}
                                    >
                                      {message.message}
                                    </p>
                                  </div>
                                );
                              })}

                          <Margin mt={60} />
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                background: "#b8b8b8",
                                borderRadius: "50%",
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <h3>
                                {getFirstLetters(subAttendDetails?.client.name)}
                              </h3>
                            </div>
                            <Margin ml={20} />
                            <div>
                              <h4>{subAttendDetails.client.name}</h4>
                              <p style={{ fontSize: "12px" }}>
                                {timeAgo(subAttendDetails?.created_on)}
                              </p>
                              <Margin mb={30} />
                            </div>
                          </div>
                          <p style={{ marginLeft: "10px" }}>
                            {subAttendDetails.message}
                          </p>
                        </div>
                        <Stack gap={5}>
                          <Margin mb={-20} />
                          <div>
                            <TextArea
                              id="message"
                              name="message"
                              labelText="Mensagem"
                              required
                              rows={6}
                              placeholder="Digite a mensagem"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{ resize: "none" }}
                            />
                          </div>
                        </Stack>
                        <Margin mb={30} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          {!isSolicitant && (
                            <div>
                              <Margin mt={-20} />
                              <div style={{ width: "300px" }}>
                                <Label text="Enviar mensagem como" />
                                <Dropdown
                                  titleText=""
                                  id="example-dropdown"
                                  label="Selecione o modo de envio"
                                  items={items}
                                  itemToString={(item) =>
                                    item ? item.label : ""
                                  }
                                  onChange={(selectedItem) =>
                                    handleDropdownChange(selectedItem)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <Margin ml={20} />
                          <div>
                            <Button
                              kind="primary"
                              disabled={
                                userProfile?.type !== "client" && !isSolicitant
                                  ? values.selectedOption === "" ||
                                  values.message === ""
                                  : values.message === ""
                              }
                              className="action-button"
                              onClick={() => {
                                fetchSendAttendsTaskMessages();
                                if (
                                  subAttendDetails?.status.slug ===
                                  "return_to_client" &&
                                  isSolicitant
                                ) {
                                  fetchSendNewStatus();
                                }
                              }}
                            >
                              Enviar
                            </Button>
                          </div>
                        </div>
                        <Margin mb={100} />
                        <Grid></Grid>
                      </Column>
                      <LateralDetailsAttends />
                    </Grid>
                  </TabPane>
                  <TabPane tab="Documentos" key="2">
                    <Grid
                      id="container-center"
                      className="form"
                      style={{ background: "white" }}
                    >
                      <Column span={12}>
                        <AttendDetailsFiles />
                      </Column>
                      <LateralDetailsAttends />
                    </Grid>
                  </TabPane>
                </Tabs>
              </Column>
            </Grid>
          </form>
        </TicketFormContainer>
      )}
      <UpdateStatusAttendModal
        open={openUpdateAttendStatusModal}
        onClose={() => setOpenUpdatedAttendStatusModal(false)}
      />
      <UpdateOwnerAttendModal
        open={openUpdateOwnerAttendModal}
        onClose={() => setOpenUpdatedOwnerAttendModal(false)}
      />
    </div>
  );
};

export default SubAttendDetails;
